<template>
    <div id="datos-empleado">
        <h1>INFORMACION PERSONAL</h1>
        <div id="empleado-informacion-div">
            <div id="empleado-info">
                <select id="empleado-informacion" class="select-style" @change="handleChange" v-model="empleadoInformacionSelect">
                    <option value="" disabled hidden>Emplead@</option>
                    <option v-for="empleado in empleados" :key="empleado.id" data-info="empleado-informacion" 
                        :data-posicion="empleado.posicion" :data-id="empleado.id">
                            {{ empleado.nombre }} {{ empleado.apellido }}
                    </option>
                </select> 
            </div>
            <span v-if="errorEmpleadoInformacion"><p class="error-select" style="color: red; height: 0;">{{ errorEmpleadoInformacion }}</p></span>
        </div>
       
        <div id="fecha-informacion-div">
            <div id="fecha-info">
                <Datepicker
                    dark
                    range 
                    auto-apply
                    hide-input-icon
                    placeholder="Rango fechas"
                    v-model="fechaInformacion"
                    :enable-time-picker="false"
                    format="dd/MM/yyyy"
                    locale="es" 
                    cancelText="Cancelar" 
                    selectText="Seleccionar" 
                    id="fecha-informacion"
                    position="center"
                    :year-range="[new Date().getFullYear()]"
                />
            </div>
            <span v-if="errorFechaInformacion"><p class="error-select" style="color: red; height: 0;">{{ errorFechaInformacion }}</p></span>
        </div>

        <div id="retiro-informacion-div" v-if="empleadoInformacionSelect != ''">
            <div class="field field_v2">
                <label for="retiro-informacion" class="ha-screen-reader">Retiro</label>
                <input v-model="retiroInformacion" id="retiro-informacion" class="field__input" placeholder="ej. 1000" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                <span class="field__label-wrap" aria-hidden="true">
                    <span class="field__label">Retiro</span>
                </span>
            </div>    
            <span v-if="errorRetiroInformacion"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorRetiroInformacion }}</p></span>
        </div>

        <div id="boton-retiro-informacion-div" v-if="empleadoInformacionSelect != ''">
            <div id="boton-retiro-informacion">
                <button id="button" @click="retiroEmpleado">Confirmar</button>
            </div>
        </div>

        <div id="boton-informacion-div">
            <div id="boton-informacion">
                <button id="button" @click="buscarInfoPersonal">BUSCAR</button>
            </div>
        </div>
    </div>
    
    <transition name="fade">
        <div v-if="loaded" id="show-div">
            <h1>{{ nombreEmpleado }}</h1>  

            <div id="boton-cerrar-div">
                <button @click="cerrar" id="boton-cerrar-graficos">CERRAR</button>
                <button @click="flip" id="boton-flip-retiros">Retiros</button>
            </div>

            <div>
                <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                    <div class="lds-dual-ring"></div>
                </div>
                <div id="chart" v-if="loaded">
                    <div id="grafico-total-retiro-div">
                        <div class="front">
                        <Bar
                            id="grafico-total-retiro"
                            :options="graficoOpcionesTotalRetiro"
                            :data="graficoTotalRetiro"
                        />
                        </div>
                        <div class="back">

                            <div id="tabla-retiros-div">
                                <div id="table-wrapper-retiros">
                                    <div id="tabla-retiros">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>FECHA</th>
                                                    <th>TOTAL</th>
                                                </tr> 
                                            </thead>

                                            <tbody>
                                                <tr
                                                    v-for="retiro in retiros"
                                                    :key="retiro.id">
                                        
                                                    <td id="settings-delete-icon-empleado">
                                                        <img id="trash-retiro" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarRetiro" title="Eliminar" data-info="retiros-tabla" :data-id="retiro.id">
                                                    </td>
                                                    <td data-label="FECHA">{{ retiro.fecha_str }}</td>
                                                    <td data-label="TOTAL">{{ retiro.total }}</td>                          
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div id="grafico-servicios-div">
                        <Bar
                            id="grafico-servicios"
                            :data="graficoServicios"
                            :options="graficoOpcionesServicios"
                        />
                    </div>
                
                    <div id="grafico-efectivo-tarjeta-div">
                        <Bar
                            id="grafico-efectivo-tarjeta"
                            :options="graficoOpcionesEfectivoTarjeta"
                            :data="graficoEfectivoTarjeta"
                        />
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { Bar } from 'vue-chartjs'

export default {
    name: 'InformacionPersonalEmpleado',
    props: {
        empleados:Array
    },
    data() {
        return {
            empleadoInformacionSelect:'',
            nombreEmpleado:'',
            errorEmpleadoInformacion:'',
            errorFechaInformacion:'',
            errorRetiroInformacion:'',
            fechaInformacion:null,
            empleadoInformacion: {
                id:'',
                posicionNombre:''
            },
            retiroInformacion:'',
            loaded:false,
            show:false,
            retiros:[],
            graficoServicios: {
                labels:[],
                datasets: [
                    {
                        label: 'Cantidad',
                        backgroundColor: '#9757c8',
                        data: []
                    }
                ]
            },
            graficoOpcionesServicios: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size:20
                        }
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false
                        },
                    },
                    y: {
                        ticks: {
                            // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                            stepSize: 1,
                            autoSkip: true,
                    }
                    }
                }
            },
            graficoEfectivoTarjeta: {
                labels: [],
                datasets: [
                    {
                        label: 'Total $',
                        backgroundColor: '#0777d8',
                        data: [],
                    },
                   
                ]
            },
            graficoOpcionesEfectivoTarjeta: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size:20
                        }
                    },
                    datalabels: {
                    formatter: function(value, context) {
                        return '$' + value;
                    }
                    }
                },
                scales: {
                    y: {
                    ticks: {
                        callback: function(value, index, ticks) {
                            return '$' + value;
                        }
                    }
                    }
                }
            },
            graficoTotalRetiro: {
                labels: [],
                datasets: [
                    {
                        label: 'Total $',
                        backgroundColor: '#0777d8',
                        data: [],
                    },
                   
                ]
            },
            graficoOpcionesTotalRetiro: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size:20
                        }
                    },
                    datalabels: {
                    formatter: function(value, context) {
                        return '$' + value;
                    }
                    }
                },
                scales: {
                    y: {
                    ticks: {
                        callback: function(value, index, ticks) {
                            return '$' + value;
                        }
                    }
                    }
                }
            },
        }
    },
    components: { 
        Datepicker,
        Bar
    },
    mounted() {
        this.checkMobile()
    },
    watch: { 
    },
    methods: {  
        flip() {
            $('#grafico-total-retiro-div').toggleClass('flipped');
        },
        async buscarInfoPersonal(e) {
            if (!this.empleadoInformacion.id) {
                this.errorEmpleadoInformacion= 'Requerid@'
                setTimeout(() => this.errorEmpleadoInformacion = '', 2000)
            }
            if (this.fechaInformacion == null) {
                this.errorFechaInformacion = 'Requerida'
                setTimeout(() => this.errorFechaInformacion = '', 2000)
            }
               
            e.preventDefault();

            if (!this.errorEmpleadoInformacion && !this.errorFechaInformacion) {
                this.$store.commit('setIsLoading', true)
                this.nombreEmpleado = this.empleadoInformacionSelect
                this.show = true
                this.loaded = false
                const data = {
                    empleado:this.empleadoInformacion,
                    fechas:this.fechaInformacion
                }

                axios
                    .post("/api/v1/empleados/informacion-personal/", data)
                    .then(response => {
                        this.retiros = response.data[0]['retiros']
                        this.graficoOpcionesServicios.plugins.title.text = response.data[0]['titulo']
                        this.graficoServicios.labels = response.data[0]['items']
                        this.graficoServicios.datasets[0].data = response.data[0]['items_cantidad']
                        this.graficoOpcionesEfectivoTarjeta.plugins.title.text = 'Efectivo / tarjeta'
                        this.graficoEfectivoTarjeta.datasets[0].data = [response.data[0]['total_efectivo'], response.data[0]['total_tarjeta']]
                        this.graficoEfectivoTarjeta.labels = ['Efectivo','Tarjeta']
                        this.graficoOpcionesTotalRetiro.plugins.title.text = 'Efectivo + tarjeta / retiro'
                        this.graficoTotalRetiro.datasets[0].data = [response.data[0]['total_efectivo'] + response.data[0]['total_tarjeta'], response.data[0]['total_retiros']]
                        this.graficoTotalRetiro.labels = ['Efec/Tarj','Retiro']
                        this.loaded = true
                        setTimeout(() => {
                            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
                        }, 1000);        
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL BUSCAR EMPLEAD@',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                this.$store.commit('setIsLoading', false)
            }
        },
        cerrar () {
            this.loaded = false
            this.show = false
        },
        checkMobile() {
            let hasTouchScreen = false;
            if ("maxTouchPoints" in navigator) {
            hasTouchScreen = navigator.maxTouchPoints > 0;
            } else if ("msMaxTouchPoints" in navigator) {
            hasTouchScreen = navigator.msMaxTouchPoints > 0;
            } else {
            const mQ = matchMedia?.("(pointer:coarse)");
            if (mQ?.media === "(pointer:coarse)") {
                hasTouchScreen = !!mQ.matches;
            } else if ("orientation" in window) {
                hasTouchScreen = true; // deprecated, but good fallback
            } else {
                // Only as a last resort, fall back to user agent sniffing
                const UA = navigator.userAgent;
                hasTouchScreen =
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
            }
            }

            if (hasTouchScreen) {
                this.mobile = true
                const scalesProSer = { 
                    y: {
                    ticks: {
                        display:false,
                    },
                    grid: {
                        display: false
                    },
                    },
                    x: {
                    ticks: {
                        stepSize: 1,
                        autoSkip: true,
                    },
                    }
                }
                const p = {
                    title: {
                    display: true,
                    text: '',
                    font: {
                        size:20
                    }
                    },
                    datalabels: {
                    formatter: function(value, context) {
                        let w = ''
                        let words =  context.chart.data.labels[context.dataIndex].trim().split(' ');
                        let lenWord = words.length

                        if (words.length > 1) {
                        for (let x = 0; x < words.length; x++) {
                            if(x > 0) {
                            w += words[x].substring(0,3) +'. '
                            }else {
                            w += words[x] + ' '
                            }
                        }
                        }else {
                        w = words[0]
                        }
                    
                    return w;
                    }
                    }
                }
                const scales = {
                    y: {
                        ticks: {
                            maxRotation: 90,
                            minRotation: 90,
                            labelOffset:-30,
                            color:'#ffffff',
                            font: {
                                weight: 'bold'
                            }
                        }
                    },
                    x: {
                        ticks: {
                           display:false
                        }
                    }
                }

                this.graficoOpcionesServicios.indexAxis = 'y'
                this.graficoOpcionesServicios.scales = scalesProSer
                this.graficoOpcionesServicios.plugins = p
                this.graficoOpcionesEfectivoTarjeta.indexAxis = 'y'
                this.graficoOpcionesEfectivoTarjeta.scales = scales
                this.graficoOpcionesTotalRetiro.indexAxis = 'y'
                this.graficoOpcionesTotalRetiro.scales = scales 
            }

        },
        async retiroEmpleado(e) {
            if (!this.empleadoInformacion.id) {
                this.errorEmpleadoInformacion= 'Requerid@'
                setTimeout(() => this.errorEmpleadoInformacion = '', 2000)
            }   
            e.preventDefault();

            if (!this.errorEmpleadoInformacion) {
                const data = {
                    id: this.empleadoInformacion.id,
                    retiro: this.retiroInformacion
                }
                axios
                    .post("/api/v1/empleados/retiro/", data)
                    .then(response => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'RETIRO GUARDADO',
                            showConfirmButton: false,
                            timer: 2000
                        }) 
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL GUARDAR RETIRO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
            }
            
        },
        async eliminarRetiro(e) {
            Swal.fire({
                title: 'ELIMINAR RETIRO?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#279f04',
                cancelButtonColor: '#d33',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                cancelButtonText: 'CANCELAR',
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = {
                        id:e.target.dataset.id
                    }
                    axios
                        .post('/api/v1/empleados/eliminar-retiro/', data)
                        .then(response => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'RETIRO ELIMINADO',
                                showConfirmButton: false,
                                timer: 2000
                            })
                            this.buscarInfoPersonal(e)
                        })
                        .catch(error => {
                            console.log(error)
                            Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL ELIMINAR RETIRO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                        })
                }
            })
        },
        handleChange(e) {
            if(e.target.options.selectedIndex > -1) {
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "empleado-informacion") {
                    this.empleadoInformacion.id = e.target.options[e.target.options.selectedIndex].dataset.id
                    this.empleadoInformacion.posicionNombre = e.target.options[e.target.options.selectedIndex].dataset.posicion
                }
                
            }
        },
    }
}
</script>

<style>
#datos-empleado h1 {
    text-shadow: var(--text-shadow);
}

#empleado-info, #fecha-info {
    border-bottom: 2px solid var(--fieldBorderColor); 
}

#fecha-info input {
    height: 45px;
    text-align: left;
    font-family: 'Khand';
    color: white;
    font-size: 18px;
    background-color: transparent;
    border: none;
}

#empleado-informacion-div, #fecha-informacion-div {
    display: inline-table;
    margin-left: 5px;
    margin-right: 5px;
    margin: 20px 5px 10px 5px;
}

#empleado-informacion  {
    width: 170px;
}

#fecha-informacion {
    height: 39px;
    width: 250px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
}

.dp__input::placeholder{
    color: #ffffff;
    font-weight: 500;
    opacity: 1;
}

#retiro-informacion {
    margin-bottom: 13px;
    width: 150px;
}

#retiro-informacion-div, #boton-retiro-informacion-div {
    display: inline-table;
    margin: 0 5px 0 5px;
}

#boton-informacion-div {
    margin-top: 10px;
}

#boton-informacion button {
    width: 90%;
}

#show-div h1 {
    background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-10&color=#000000);
    width: fit-content;
    margin: 50px auto 0 auto;
    padding: 0 20px 0 20px;
    text-shadow: var(--text-shadow);
}

#boton-cerrar-div {
    margin-top: 20px;
}

#boton-cerrar-graficos {
    width: 20%;
    background-color: var(--button-close);
    border: none;
    color: rgb(255, 255, 255);
    margin-right: 10px;
    padding: 0 8px;
}

#boton-flip-retiros {
    width: 20%;
    background-color: rgb(75, 47, 139);
    border: none;
    color: rgb(255, 255, 255);
    margin-left: 10px;
    padding: 0 8px;
}

#show-div {
    min-height: 720px;
}

#show-div #chart {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#grafico-servicios-div, 
#grafico-efectivo-tarjeta-div,
#grafico-total-retiro-div {
    margin: 50px 10px 0 10px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
    height: 500px;
    width: 500px;
}

#grafico-total-retiro-div {
    -webkit-transition: -webkit-transform 1s;
    -moz-transition: -moz-transform 1s;
    -o-transition: -o-transform 1s;
    transition: transform 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;

}
#grafico-total-retiro-div div {
    width: 500px;
    height: 500px;
    position: absolute;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
}

#grafico-total-retiro-div .back {
    background:transparent;
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY( 180deg );
    -o-transform: rotateY( 180deg );
    transform: rotateY( 180deg );
}
#grafico-total-retiro-div.flipped {
    -webkit-transform: rotateY( 180deg );
    -moz-transform: rotateY( 180deg );
    -o-transform: rotateY( 180deg );
    transform: rotateY( 180deg );
}

#table-wrapper-retiros {
    position: absolute;
    width: 100%;
}

#tabla-retiros table {
    width: 100%;
}

#tabla-retiros th, #tabla-retiros td {
    text-align: center;
}

#trash-retiro {
    width: 20px;
    filter: invert(100%);
}

@media screen and (min-width: 700px) and (max-width: 1365px) {}

@media screen and (max-width: 900px) {

    #grafico-servicios-div, #grafico-efectivo-tarjeta-div,
    #grafico-total-retiro-div , #grafico-total-retiro-div div {
        width: 90%;
    }

    .back #tabla-retiros-div, .back #table-wrapper-retiros, .back #tabla-retiros {
        width: 100%;
    }

    #tabla-retiros th, #tabla-retiros td {
    text-align: center;
}
}
</style>