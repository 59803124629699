import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import '@/assets/styles/normalize.css'
import '@/assets/styles/skeleton.css'
import '@/assets/styles/global.css'

const app = createApp(App);

axios.defaults.baseURL = 'https://api.tijerasgestion.com.ar'

app.use(store).use(router).mount('#app')
