<template>
  <div class="inicio-cliente-view">
    <swiper 
    :loop="true"
    :autoplay="{
      delay: 10000,
      disableOnInteraction: false,
    }"
    :pagination="{
      clickable: true,
    }"
    :modules="modules"
    class="mySwiper">
      <swiper-slide>
        <img id="img" src="@/assets/images/pelu.jpeg" alt="">
        <div class="title" >
          <h1>TU TIENDA NOMBRE</h1>
        </div>
        <div class="text" >
          <p>
            TRANSFORMAMOS TU CABELLO EN UNA OBRA DE ARTE
          </p>
        </div> 
      </swiper-slide>
      <swiper-slide>
        <img id="img" src="@/assets/images/pelu2.jpg" alt="">
        <div class="title" >
          <h1>ELEGI TU LOOK</h1>
        </div>
        <div class="boton" >
          <a href="/SERVICIOS">MIRA NUESTROS SERVICIOS</a>
        </div>
      </swiper-slide>
      <swiper-slide>
        <img id="img" src="@/assets/images/pelu3.jpg" alt="">
        <div class="title" >
          <h1>¡RENOVA TU ESTILO!</h1>
        </div>
        <div class="boton" >
          <a href="/turnos">AGENDA TU TURNO</a>
        </div>
      </swiper-slide>
    </swiper>
    
    <div id="servicios-inicio-div">
      <div id="corte-peinado-div">
        <div id="img-corte-peinado-div">
          <img id="img-servicios-inicio" src="@/assets/images/tijeras.png" alt="">
        </div>
        <h1 id="titulo-servicios-inicio">CORTE Y PEINADO</h1>
        <p id="texto-servicios-inicio">
          Te brindaremos el corte ideal acompañado con el estilo que te diferencia del resto.
        </p>
      </div>

      <div id="cuidado-cabello-div">
        <div id="img-cuidado-cabello-div">
          <img id="img-servicios-inicio" src="@/assets/images/cabello.png" alt="">
        </div>
        <h1 id="titulo-servicios-inicio">CUIDADO DEL CABELLO</h1>
        <p id="texto-servicios-inicio">
          Te ofrecemos varias opciones para que mantegas la calidad y apariencia en todo momento.
        </p>
      </div>

      <div id="tintura-div">
        <div id="img-tintura-div">
          <img id="img-servicios-inicio" src="@/assets/images/tintura.png" alt="">
        </div>
        <h1 id="titulo-servicios-inicio">TINTURA</h1>
        <p id="texto-servicios-inicio">
          Disponemos de una gran variedad de colores para llevar tu look al siguiente nivel.
        </p>
      </div>
    </div>
    <div id="parallax-1-div">
        <div class="parallax-1"></div>
        <div class="texto-interior-1">
          <p>
            Un lugar donde la creatividad se encuentra con la habilidad <br> ¡tu cabello nunca se verá mejor!
          </p>	
        </div>
      </div>
    <!--
    <div >
      <div id="parallax-1-div">
        <div class="parallax-1"></div>
        <div class="texto-interior-1">
          <p>
            Nuestro equipo de expertos está aquí para ayudarte. 
            Ofrecemos una amplia variedad de servicios, desde cortes modernos 
            hasta peinados clásicos, nuestro objetivo es hacerte lucir increíble.
          </p>	
        </div>
      </div>

      <div id="parallax-2-div">
        <div class="parallax-2"></div>
        <div class="texto-interior-2">
          <p>
            Nuestro equipo de expertos está aquí para ayudarte. 
            Ofrecemos una amplia variedad de servicios, desde cortes modernos 
            hasta peinados clásicos, nuestro objetivo es hacerte lucir increíble.
          </p>	
        </div>
      </div>
    </div>
  

    <div id="sections-inicio-cliente">
      <section class="section-1">
        <div class="flex-group">
          <div class="section-img-div">
            <img class="section-img" src="@/assets/images/section1.jpg">
          </div>
          <div class="content-container">
            <p>
              Nuestro equipo de expertos está aquí para ayudarte. 
              Ofrecemos una amplia variedad de servicios, desde cortes modernos 
              hasta peinados clásicos, nuestro objetivo es hacerte lucir increíble.
            </p>
          </div>
        </div>
      </section>
    
      <section class="section-2">
        <div class="flex-group section-2-flex-group">
          <div class="section-img-div">
            <img class="section-img" src="@/assets/images/section2.jpg">
          </div>
          <div class="content-container">
            <p>
              Utilizamos productos de alta calidad y herramientas 
              de última generación para garantizar los mejores resultados en cada 
              servicio. Nos comprometemos a proporcionar un ambiente calido 
              y relajado durante tu visita.
            </p>      
          </div>
        </div>
      </section>
    </div>

    <div id="parallax-div">
      <div class="parallax"></div>
      <div class="texto-interior">
        <p>
          LA SATISFACCION DEL CLIENTE ES NUESTRA MAXIMA PRIORIDAD, <br>
          VENI Y COMPROBA NUESTRO SERVICIO PREMIUM
        </p>	
      </div>
    </div>
    -->
  </div>
</template>

<script>
// @ is an alias to /src
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper";


export default {
  name: 'InicioClienteView',
  data() {
    return {
      modules: [Autoplay, Navigation],
    }
  },
  mounted() {
    document.title = 'Inicio | Tu tienda'
    if (this.$store.state.isAuthenticated ==false) {
      this.setBgBody()
    }
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    setBgBody() {
      var store = document.querySelector(':root');
      store.style.setProperty('--bg-body', "var(--bg-body-cliente)")
    },
  }
}
</script>

<style lang="scss">
.swiper {
  height: 590px;
}

#img {
  width: 100%;
  height: 100%;
  object-fit:cover;
  object-position:top;
  filter:brightness(70%);
}

.inicio-cliente-view {
  padding-top: 127px;
}

.swiper-slide .title h1 {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size:100px;
  width: 100%;
}

.swiper-slide .text p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(255, 255, 255);
  font-weight: bold;
  font-size:30px;
  width: 100%;
}

.swiper-slide .boton {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.boton a {
  background-color: var(--button-color-cliente);
  border: none;
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  padding: 10px;
  border-radius: 4px;
}

.boton a:hover {
  background-color: var(--button-color-cliente-hover);
}

/* Sections */

#sections-inicio-cliente {
  width: 100%;
  margin: 0 auto 0 auto;
}

.flex-group {
  display: flex;
  align-items: center;
}

.section-1 {
  background-image: linear-gradient(to bottom, black, rgb(211, 211, 211));
}

.section-1 p {
  padding: 50px;
  font-size: 20px;
}

.section-1-btn {
  margin-bottom: rem;
}

.section-2 {
  background-image: linear-gradient(to top, black, rgb(211, 211, 211));
}

.section-2-flex-group {
  flex-direction: row-reverse;
}

.section-2 p {
  padding: 50px;
  font-size: 20px;
}

.section-img-div {
  width: 900px;
  padding: 50px;
}

.section-img {
  width: 100%;
  filter: grayscale(100%);

}
.parallax-1{
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size:  cover;
	height:20rem;
	max-width: 100%;
	position: relative;
  background-image:url("@/assets/images/parallax1.jpg");
  filter:brightness(60%);
}

.texto-interior-1 p{
  width: 70%;
  color:#fff;
	font-size: 40px;
	font-weight: bold;
  position: absolute;
  margin-top: -10rem;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-transform: uppercase;
}

.parallax-2{
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size:  cover;
	height:20rem;
	max-width: 100%;
	position: relative;
  background-image:url("@/assets/images/section2.jpg");
  filter:brightness(60%);
}

.texto-interior-2 p{
  width: 100%;
  color:#fff;
	font-size: 35px;
	font-weight: bold;
  position: absolute;
  margin-top: -10rem;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

#servicios-inicio-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#titulo-servicios-inicio {
  font-size: 40px;
  font-weight: bold;
}

#texto-servicios-inicio {
  font-size: 20px;
}

#corte-peinado-div,
#cuidado-cabello-div,
#tintura-div {
  width: 350px;
  margin: 30px 50px 30px 50px;
  text-align: center;
}

#img-servicios-inicio-div {
  height: 100px;
  width: 100%;
  overflow: hidden;
}

#img-servicios-inicio {
  width: 100px;
  transition: 0.4s;
  padding: 20px;
}

#corte-peinado-div:hover #img-servicios-inicio,
#cuidado-cabello-div:hover #img-servicios-inicio,
#tintura-div:hover #img-servicios-inicio {
    transform: scale(1.2,1.2);
}


@media screen and (min-width: 900px) and (max-width: 1350px) {
  #corte-peinado-div,
  #cuidado-cabello-div,
  #tintura-div {
    width: 300px;
    margin: 20px;
  }

  #img-servicios-inicio {
    width: 90px;
    transition: 0.4s;
    padding: 20px;
  }

  #titulo-servicios-inicio {
    font-size: 35px;
    font-weight: bold;
  }

  #texto-servicios-inicio {
    font-size: 20px;
  }
  

}

@media only screen and (max-width: 900px) {
  .swiper {
    margin-top: -10px;
    height: 290px;
  }

  #img {
    width: 100%;

    object-fit: cover;
  }

  .home {
    padding-top: 120px;
  }

  .swiper-slide .title h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size:60px;
    width: 100%;
  }

  .swiper-slide .text p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size:20px;
    width: 100%;
  }

  .swiper-slide .boton {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  #corte-peinado-div,
  #cuidado-cabello-div,
  #tintura-div {
    width: 300px;
    margin: 0;
  }

  #img-servicios-inicio {
    width: 80px;
    transition: 0.4s;
    padding: 20px;
  }

  #titulo-servicios-inicio {
    font-size: 30px;
    font-weight: bold;
  }

  #texto-servicios-inicio {
    font-size: 18px;
  }

  .texto-interior-1 p{
    font-size: 30px;
  }
  

  /* Flex Group Mobile */

  .flex-group {
    flex-direction: column;
  }
}

@media only screen and (max-width: 450px) {
  .swiper-slide .title h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size:45px;
    width: 100%;
  }

  .swiper-slide .text p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size:15px;
    width: 100%;
  }

  .swiper-slide .boton {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  .boton a {
    background-color: rgb(209, 44, 94);
    border: none;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    padding: 10px;
    border-radius: 4px;
  }

  .texto-interior-1 p{
    font-size: 20px;
  }
}
</style>
