<template>
      <div id="grafico-anual">
        <div id="grafico-caja-anual">
          <h1 id="title-anual">ANUAL</h1>
          <div id="year-div">
              <select id="year" class="select-style" @change="handleChange" v-model="yearSelect">
                  <option value="" disabled hidden>Año</option>
                  <option v-for="year in years" :key="year" data-info="year">{{ year }}</option>
              </select> 
          </div>
        </div>
        
        <transition name="fade">
          <div id="chart-graficos-anual" v-if="loaded">
            <button @click="min" id="boton-cerrar-anual">CERRAR</button>
            <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
              <div class="lds-dual-ring"></div>
            </div>
              <div v-if="show">
                <div id="grafico-anual-totales">
                  <Bar
                      id="grafico-totales-anual"
                      :options="graficoOpcionesAnual"
                      :data="graficoTotalesAnual"
                  />
                </div>

                <div id="grafico-anual-efectivo-tarjeta">
                  <Bar
                      id="grafico-total-efectivo-tarjeta-anual"
                      :options="graficoOpcionesEfectivoTarjetaYear"
                      :data="graficoTotalEfectivoTarjetaYear"
                  />
                </div>

                  <div id="grafico-anual-total">
                    <Bar
                        id="grafico-total-anual"
                        :options="graficoOpcionesTotalYear"
                        :data="graficoTotalYear"
                    />
                  </div>

                  <div id="grafico-anual-medios-pago">
                    <Pie
                        id="grafico-medios-pago-anual"
                        :options="graficoOpcionesMediosPagoAnual"
                        :data="graficoTotalMediosPagoAnual"
                    />
                  </div>

                  <div id="grafico-servicios-year">
                    <Bar
                        id="grafico-year-servicios"
                        :options="graficoOpcionesServicios"
                        :data="graficoServicios"
                    />
                  </div>

                  <div id="grafico-productos-year">
                    <Bar
                        id="grafico-year-productos"
                        :options="graficoOpcionesProductos"
                        :data="graficoProductos"
                    />
                  </div>
              </div>
          </div>
        </transition>
      </div>    
    
  </template>
  
<script>
  import axios from 'axios'
  import { Bar, Pie } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale } from 'chart.js'
  import ChartDataLabels from 'chartjs-plugin-datalabels';

  ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale, ChartDataLabels)
  ChartJS.defaults.font.size = 15;
  ChartJS.defaults.borderColor = '#575656';

  export default {
    name: 'CajaGraficos',
    data() {
      return {
        mobile:false,
        loaded:false,
        show: false,
        buttonText:'Minimizar',
        yearSelect:'',
        years:[],
        graficoTotalesAnual: {
          labels: '',
          datasets: [
            {
              label: 'Servicios Efectivo $',
              backgroundColor: '#58508d',
              data: [],
            },
            {
              label: 'Servicios Tarjeta $',
              backgroundColor: '#bc5090',
              data: []
            },
            {
              label: 'Productos Efectivo $',
              backgroundColor: '#ff6361',
              data: []
            },
            {
              label: 'Productos Tarjeta $',
              backgroundColor: '#ffa600',
              data: []
            }
          ]
        },
        graficoOpcionesAnual: {
          indexAxis: 'x',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
                display: true,
                text: '',
                font: {
                  size:20
                }
            },
            datalabels: {
              formatter: function(value, context) {
                return '$' + value;
              }
            }
          },
          scales: {
            y: {
              ticks: {
                  callback: function(value, index, ticks) {
                      return '$' + value;
                  }
              }
            }
          }
        },
        graficoTotalEfectivoTarjetaYear: {
          labels: '',
          datasets: [
            {
              label: 'Total efectivo $',
              backgroundColor: "#96c0ff",
              data: [],
            },
            {
              label: 'Total tarjeta $',
              backgroundColor: "#00b3ff",
              data: [],
            }
          ]
        },
        graficoOpcionesEfectivoTarjetaYear: {
          indexAxis: 'x',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
                display: true,
                text: '',
                font: {
                  size:20
                }
            },
            datalabels: {
              formatter: function(value, context) {
                return '$' + value;
              }
            }
          },
          scales: {
            y: {
              ticks: {
                  callback: function(value, index, ticks) {
                      return '$' + value;
                  }
              }
            }
          }
        },
        graficoTotalYear: {
          labels: '',
          datasets: [
            {
              label: 'Total $',
              backgroundColor: "#2f5b6b",
              data: [],
            },
            {
              label: 'Retiros $',
              backgroundColor: "#ff3838",
              data: [],
            }
          ]
        },
        graficoOpcionesTotalYear: {
          indexAxis: 'x',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
                display: true,
                text: '',
                font: {
                  size:20
                }
            },
            datalabels: {
              formatter: function(value, context) {
                return '$' + value;
              }
            }
          },
          scales: {
            y: {
              ticks: {
                  callback: function(value, index, ticks) {
                      return '$' + value;
                  }
              }
            }
          }
        },
        graficoTotalMediosPagoAnual: {
            labels:['Efectivo', 'Tarjeta'],
            datasets: [
                {
                  label: 'Cant',
                  backgroundColor: ['#0b5394', '#6fa8dc'],
                  data: []
                },
            ]
        },
        graficoOpcionesMediosPagoAnual: {
            responsive: true,
            plugins: {
                title: {
                  display: true,
                  text: '',
                  font: {
                    size:20
                  }
                }
            }
        },
        graficoServicios: {
            labels:[],
            datasets: [
                {
                    label: 'Cantidad',
                    backgroundColor: "#ff7abb",
                    data: []
                }
            ]
        },
        graficoOpcionesServicios: {
          indexAxis: 'x',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
              title: {
                  display: true,
                  text: ''
              }
          },
          scales: {
            x: {
              grid: {
                display: false
              },
            },
            y: {
              ticks: {
              // For a category axis, the val is the index so the lookup via getLabelForValue is needed
              stepSize: 1,
              autoSkip: true,
              }
            }
          }
        },
        graficoProductos: {
            labels:[],
            datasets: [
                {
                  label: 'Cantidad',
                  backgroundColor: '#d793f3',
                  data: [],
                }
            ]
        },
        graficoOpcionesProductos: {
          indexAxis: 'x',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: '',
              font: {
                size:20
              }
            },
          },
          scales: {
            x: {
              grid: {
                display: false
              },
            },
            y: {
              ticks: {
                stepSize: 1,
                autoSkip: true,
              }
            },
          }
        },
      }
    },
    components: { Bar, Pie },
    mounted() {
        this.getYears();
        this.checkMobile()
    },
    methods: {
      min() {
        this.loaded = false
        this.yearSelect = ''
      },
      checkMobile() {
        let hasTouchScreen = false;
        if ("maxTouchPoints" in navigator) {
          hasTouchScreen = navigator.maxTouchPoints > 0;
        } else if ("msMaxTouchPoints" in navigator) {
          hasTouchScreen = navigator.msMaxTouchPoints > 0;
        } else {
          const mQ = matchMedia?.("(pointer:coarse)");
          if (mQ?.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
          } else if ("orientation" in window) {
            hasTouchScreen = true; // deprecated, but good fallback
          } else {
            // Only as a last resort, fall back to user agent sniffing
            const UA = navigator.userAgent;
            hasTouchScreen =
              /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
              /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
          }
        }

        if (hasTouchScreen) {
          this.mobile = true
          const scales = {
            y: {
              ticks: {
                maxRotation: 90,
                minRotation: 90,
                color:'#FFC512',
                font: {
                  weight: 'bold'
                }
              }
            },
            x: {
              ticks: {
                  callback: function(value, index, ticks) {
                      return '$' + value;
                  }
              }
            }
          }
          const scalesProSer = { 
            y: {
              ticks: {
                display:false,
              },
              grid: {
                display: false
              },
            },
            x: {
              ticks: {
                stepSize: 1,
                autoSkip: true,
              },
            }
          }
          const p = {
            title: {
              display: true,
              text: '',
              font: {
                size:20
              }
            },
            datalabels: {
              formatter: function(value, context) {
                let w = ''
                let words =  context.chart.data.labels[context.dataIndex].trim().split(' ');
                let lenWord = words.length

                if (words.length > 1) {
                  for (let x = 0; x < words.length; x++) {
                    if(x > 0) {
                      w += words[x].substring(0,3) +'. '
                    }else {
                      w += words[x] + ' '
                    }
                  }
                }else {
                  w = words[0]
                }
              
              return w;
              }
            }
          }
          this.graficoOpcionesAnual.indexAxis = 'y'
          this.graficoOpcionesAnual.scales = scales
          this.graficoOpcionesEfectivoTarjetaYear.indexAxis = 'y'
          this.graficoOpcionesEfectivoTarjetaYear.scales = scales
          this.graficoOpcionesTotalYear.indexAxis = 'y'
          this.graficoOpcionesTotalYear.scales = scales
          this.graficoOpcionesServicios.indexAxis = 'y'
          this.graficoOpcionesServicios.scales = scalesProSer
          this.graficoOpcionesServicios.plugins = p
          this.graficoOpcionesProductos.indexAxis = 'y'
          this.graficoOpcionesProductos.scales = scalesProSer
          this.graficoOpcionesProductos.plugins = p
        }

      },
      handleChange(e) {
        if(e.target.options.selectedIndex > -1) {
          if(e.target.options[e.target.options.selectedIndex].dataset.info === "year"){
              this.getYearGraficos()
          }
        }
      },
      async getYears() {
        await axios
            .get("/api/v1/years/")
            .then(response => {
                this.years = response.data['years']
            })
            .catch(error => {
              console.log(JSON.stringify(error))
            })
      },
      async getYearGraficos() {
        this.$store.commit('setIsLoading', true)
        this.loaded = false
        const data = {
            year:this.yearSelect
        }
        
        await axios
          .post("/api/v1/grafico-caja-anual/", data)
          .then(response => {
            this.graficoTotalesAnual.labels = this.years
            this.graficoOpcionesAnual.plugins.title.text = 'Totales'
            this.graficoTotalesAnual.datasets[0].data = response.data[0]['totales_efectivo_trabajo']
            this.graficoTotalesAnual.datasets[1].data = response.data[0]['totales_tarjeta_trabajo']
            this.graficoTotalesAnual.datasets[2].data = response.data[0]['totales_efectivo_tienda']
            this.graficoTotalesAnual.datasets[3].data = response.data[0]['totales_tarjeta_tienda']

            this.graficoOpcionesEfectivoTarjetaYear.plugins.title.text = 'Efectivo / Tarjeta'
            this.graficoTotalEfectivoTarjetaYear.datasets[0].data = [response.data[0]['total_efectivo_year']]
            this.graficoTotalEfectivoTarjetaYear.datasets[1].data = [response.data[0]['total_tarjeta_year']]
            this.graficoTotalEfectivoTarjetaYear.labels = [this.yearSelect]
  
            this.graficoOpcionesTotalYear.plugins.title.text = 'Efectivo + Tarjeta / Retiros'
            this.graficoTotalYear.datasets[0].data = [response.data[0]['total_efectivo_year'] + response.data[0]['total_tarjeta_year']]
            this.graficoTotalYear.datasets[1].data = [response.data[0]['retiros']]
            this.graficoTotalYear.labels = [this.yearSelect]
            this.graficoOpcionesMediosPagoAnual.plugins.title.text = 'Medios de pago ' + this.yearSelect
            this.graficoTotalMediosPagoAnual.datasets[0].data = [ response.data[0]['cant_efectivo'],  response.data[0]['cant_tarjeta']]
            this.graficoServicios.labels = response.data[0]['servicios']
            this.graficoProductos.labels = response.data[0]['productos']
            this.graficoServicios.datasets[0].data = response.data[0]['servicios_cantidad']
            this.graficoOpcionesServicios.plugins.title.text = 'Servicios año ' + this.yearSelect
            this.graficoProductos.datasets[0].data = response.data[0]['productos_cantidad']
            this.graficoOpcionesProductos.plugins.title.text = 'Productos año ' + this.yearSelect

            this.loaded = true
            this.show = true
          })
          .catch(error => {
            console.log(JSON.stringify(error))
          })  
          this.$store.commit('setIsLoading', false)
      },
    }
  }
</script>

<style>
#grafico-anual {
  margin-top: 40px;
  margin-bottom: 50px;
}

#year-div {
  border-bottom: 2px solid var(--fieldBorderColor);
  width: 100px;
  margin: 10px auto 10px auto;
}

#chart-graficos-anual {
  margin-top: 50px;
}

#boton-cerrar-anual {
  width: 120px;
  background-color: var(--button-close);
  border: none;
  color: rgb(255, 255, 255);
  padding: 0 8px;
}

#grafico-caja-anual {
  max-width: 160px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 33px 22px 0px #000000;
  color: white;
  background: var(--bg-box-black);
  background-position: center center;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
}

#grafico-caja-anual h1 {
  text-shadow: var(--text-shadow);
}

#grafico-anual-totales, #grafico-anual-efectivo-tarjeta, 
#grafico-anual-total, #grafico-anual-medios-pago, 
#grafico-servicios-year, #grafico-productos-year {
  margin: 50px 10px 0 10px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 33px 22px 0px #000000;
  color: white;
  background: var(--bg-box-black-big);
  background-position: center center;
  background-size: cover;
}

#grafico-totales-anual, 
#grafico-total-efectivo-tarjeta-anual, 
#grafico-total-anual,
#grafico-year-servicios,
#grafico-year-productos {
  height: 500px;
}

#grafico-anual-total, #grafico-anual-medios-pago, #grafico-anual-efectivo-tarjeta {
  display: inline-block;
  max-width: 500px;
  margin-left: 15px;
  margin-right: 15px;
}

@media screen and (max-width: 700px) {

    #grafico-totales-anual {
      max-height: 700px;
    }
    #grafico-anual-total, #grafico-anual-efectivo-tarjeta {
      height: 400px;
    }

    #grafico-anual-total, #grafico-anual-medios-pago, #grafico-anual-efectivo-tarjeta {
      display: inherit;
      margin-left: inherit;
      margin-right: inherit;
    }

}
</style>