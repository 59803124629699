<template>
    <div class="gestion-eliminado-view">
        <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
            <div class="lds-dual-ring"></div>
        </div>
        <div id="trabajos-eliminados-div">
            <div id="buscar-trabajo-eliminado">
                <h1>TRABAJOS ELIMINADOS</h1>
                <div id="fecha-turno-div">
                    <div id="fecha-turno">
                        <Datepicker 
                            dark
                            auto-apply
                            hide-input-icon
                            hide-offset-dates
                            placeholder="Fecha"
                            v-model="trabajoFecha"
                            :enable-time-picker="false"
                            :format="format" 
                            locale="es" 
                            id="fecha"
                            position="center"
                            @cleared="limpiar('trabajo')"
                        />      
                    </div>
                    <span v-if="errorTrabajo"><p class="error-select" style="color: red; height: 0;">{{ errorTrabajo }}</p></span>
                </div>

                <div id="buscar-boton-div">
                    <button @click="buscarTrabajo" id="boton-buscar-eliminado" data-info="buscar-trabajo">BUSCAR</button>
                </div>
            </div>

            <transition name="fade">
                <div id="table-wrapper-servicios-eliminados" v-if="trabajos.length > 0">
                    <div id="tabla-servicios-eliminados">
                        <table>
                            <thead>
                                <tr>
                                    <th>FECHA</th>
                                    <th>CAJER@</th>
                                    <th>FECHA ORIG.</th>
                                    <th>CAJER@ ORIG.</th>
                                    <th>CLIENTE</th>
                                    <th>TELEFONO</th>
                                    <th>SERVICIO</th>
                                    <th>EMPLEAD@</th>
                                    <th>DESCUENTO</th>
                                    <th>TARJETA</th>
                                    <th>PRECIO</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="trabajo in trabajos" :key="trabajo.id">
                                    <td data-label="FECHA">{{ trabajo.get_fecha }}</td>
                                    <td data-label="CAJERO">{{ trabajo.cajero_actual }}</td>
                                    <td data-label="FECHA O.">{{ trabajo.get_fecha_original }}</td>
                                    <td data-label="CAJERO O.">{{ trabajo.cajero_original }}</td>
                                    <td data-label="CLIENTE">{{ trabajo.apellido }} {{ trabajo.nombre }}</td>
                                    <td data-label="TELEFONO">{{ trabajo.telefono }}</td>
                                    <td data-label="SERVICIO">{{ trabajo.trabajo }}</td>
                                    <td data-label="EMPLEADO">{{ trabajo.empleado }}</td>        
                                    <td data-label="DESCUENTO">{{ trabajo.descuento }}%</td>
                                    <td data-label="TARJETA">{{ trabajo.tarjeta }}</td>
                                    <td data-label="PRECIO" id="total-eliminados">${{ trabajo.precio }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </transition>
        </div>

        <div id="ventas-eliminadas-div">
            <div id="buscar-tienda-eliminada">
                <h1>VENTAS ELIMINADAS</h1>
                <div id="fecha-turno-div">
                    <div id="fecha-turno">
                        <Datepicker 
                            dark
                            auto-apply
                            hide-input-icon
                            hide-offset-dates
                            placeholder="Fecha"
                            v-model="ventaFecha"
                            :enable-time-picker="false"
                            :format="format" 
                            locale="es" 
                            id="fecha"
                            position="center"
                            @cleared="limpiar('tienda')"
                        />      
                    </div>
                    <span v-if="errorVenta"><p class="error-select" style="color: red; height: 0;">{{ errorVenta }}</p></span>
                </div>
                <div id="buscar-boton-div">
                    <button @click="buscarVenta" id="boton-buscar-eliminado" data-info="buscar-tienda">BUSCAR</button>
                </div>
            </div>

            <transition name="fade">
                <div id="table-wrapper-ventas-eliminadas" v-if="tienda.length > 0">
                    <div id="tabla-ventas-eliminadas">
                        <table>
                            <thead>
                                <tr>
                                    <th>FECHA</th>
                                    <th>CAJER@</th>
                                    <th>FECHA ORIG.</th>
                                    <th>CAJER@ ORIG.</th>
                                    <th>DESCUENTO</th>
                                    <th>TARJETA</th>
                                    <th>PRECIO</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="venta in tienda" :key="venta.id">
                                    <td data-label="FECHA">{{ venta.get_fecha }}</td>
                                    <td data-label="CAJERO">{{ venta.cajero_actual }}</td>
                                    <td data-label="FECHA ORIG.">{{ venta.get_fecha_original }}</td>
                                    <td data-label="CAJERO ORIG.">{{ venta.cajero_original }}</td>
                                    <td data-label="DESCUENTO">{{ venta.descuento }}%</td>
                                    <td data-label="TARJETA">{{ venta.tarjeta }}</td>
                                    <td data-label="PRECIO">${{ venta.precio }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </transition>
        </div>

        <div id="reservas-eliminadas-div">
            <div id="buscar-reserva-eliminada">
                <h1>RESERVAS ELIMINADAS</h1>
                <div id="fecha-turno-div">
                    <div id="fecha-turno">
                        <Datepicker 
                            dark
                            auto-apply
                            hide-input-icon
                            hide-offset-dates
                            placeholder="Fecha"
                            v-model="reservaFecha"
                            :enable-time-picker="false"
                            :format="format" 
                            locale="es" 
                            id="fecha"
                            position="center"
                            @cleared="limpiar('reservas')"
                        />      
                    </div>
                    <span v-if="errorReserva"><p class="error-select" style="color: red; height: 0;">{{ errorReserva }}</p></span>
                </div>
                <div id="buscar-boton-div">
                    <button @click="buscarReserva" id="boton-buscar-eliminado" data-info="buscar-reserva">BUSCAR</button>
                </div>
            </div>

            <transition name="fade">
                <div id="table-wrapper-reservas-eliminadas" v-if="reservas.length > 0">
                    <div id="tabla-reservas-eliminadas">
                        <table>
                            <thead>
                                <tr>
                                    <th>FECHA</th>
                                    <th>CAJER@</th>
                                    <th>FECHA ORIG.</th>
                                    <th>HORA ORIG.</th>
                                    <th>SERVICIO</th>
                                    <th>NOMBRE</th>
                                    <th>APELLIDO</th>
                                    <th>TELEFONO</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="reserva in reservas" :key="reserva.id">
                                    <td data-label="FECHA">{{ reserva.get_fecha }}</td>
                                    <td data-label="CAJERO">{{ reserva.cajero_actual }}</td>
                                    <td data-label="FECHA ORIG.">{{ reserva.get_fecha_original }}</td>
                                    <td data-label="HORA ORIG.">{{ reserva.hora_original }} HS</td>
                                    <td data-label="SERVICIO">{{ reserva.servicio }}</td>
                                    <td data-label="NOMBRE">{{ reserva.nombre }}</td>
                                    <td data-label="APELLIDO">{{ reserva.apellido }}</td>
                                    <td data-label="TELEFONO">{{ reserva.telefono }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </transition>
        </div>

        <div id="empleados-eliminados-div">
            <div id="buscar-empleado-eliminado">
                <h1>EMPLEAD@S ELIMINADOS</h1>
                <div id="fecha-turno-div">
                    <div id="fecha-turno">
                        <Datepicker 
                            dark
                            auto-apply
                            hide-input-icon
                            hide-offset-dates
                            placeholder="Fecha"
                            v-model="empleadoFecha"
                            :enable-time-picker="false"
                            :format="format" 
                            locale="es" 
                            id="fecha"
                            position="center"
                            @cleared="limpiar('empleado')"
                        />      
                    </div>
                    <span v-if="errorEmpleado"><p class="error-select" style="color: red; height: 0;">{{ errorEmpleado }}</p></span>
                </div>
                <div id="buscar-boton-div">
                    <button @click="buscarEmpleado" id="boton-buscar-eliminado" data-info="buscar-empleado">BUSCAR</button>
                </div>
            </div>

            <transition name="fade">
                <div id="table-wrapper-empleados-eliminados" v-if="empleados.length > 0">
                    <div id="tabla-empleados-eliminados">
                        <table>
                            <thead>
                                <tr>
                                    <th>FECHA</th>
                                    <th>CAJER@</th>
                                    <th>FECHA ORIG.</th>
                                    <th>POSICION</th>
                                    <th>NOMBRE</th>
                                    <th>APELLIDO</th>
                                    <th>TELEFONO</th>
                                    <th>DIRECCION</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="empleado in empleados" :key="empleado.id">
                                    <td data-label="FECHA">{{ empleado.get_fecha }}</td>
                                    <td data-label="CAJERO">{{ empleado.cajero_actual }}</td>
                                    <td data-label="FECHA ORIG.">{{ empleado.get_fecha_original }}</td>
                                    <td data-label="POSICION">{{ empleado.posicion }}</td>
                                    <td data-label="NOMBRE">{{ empleado.nombre }}</td>
                                    <td data-label="APELLIDO">{{ empleado.apellido }}</td>
                                    <td data-label="TELEFONO">{{ empleado.telefono }}</td>
                                    <td data-label="DIRECCION">{{ empleado.direccion }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>  
            </transition> 
        </div>
       
        <div id="retiros-empleados-eliminados-div">
            <div id="buscar-retiros-empleados-eliminados">
                <h1>RETIRO EMPLEAD@S ELIMINAD@S</h1>
                <div id="fecha-turno-div">
                    <div id="fecha-turno">
                        <Datepicker 
                            dark
                            auto-apply
                            hide-input-icon
                            hide-offset-dates
                            placeholder="Fecha"
                            v-model="retiroEmpleadoFecha"
                            :enable-time-picker="false"
                            :format="format" 
                            locale="es" 
                            id="fecha"
                            position="center"
                            @cleared="limpiar('empleado-retiro')"
                        />      
                    </div>
                    <span v-if="errorRetiroEmpleado"><p class="error-select" style="color: red; height: 0;">{{ errorRetiroEmpleado }}</p></span>
                </div>
                <div id="buscar-boton-div">
                    <button @click="buscarRetirosEmpleados" id="boton-buscar-eliminado" data-info="buscar-retiro-empleado">BUSCAR</button>
                </div>
            </div>

            <transition name="fade">
                <div id="table-wrapper-retiros-empleados-eliminados" v-if="empleadosRetiros.length > 0">
                    <div id="tabla-retiros-empleados-eliminados">
                        <table>
                            <thead>
                                <tr>
                                    <th>FECHA</th>
                                    <th>CAJER@</th>
                                    <th>FECHA ORIG.</th>
                                    <th>CAJER@ ORIG.</th>
                                    <th>EMPLEAD@</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="retiro in empleadosRetiros" :key="retiro.id">
                                    <td data-label="FECHA">{{ retiro.get_fecha }}</td>
                                    <td data-label="CAJERO">{{ retiro.cajero_actual }}</td>
                                    <td data-label="FECHA ORIG.">{{ retiro.get_fecha_original }}</td>
                                    <td data-label="CAJERO ORIG.">{{ retiro.cajero_original }}</td>
                                    <td data-label="EMPLEADO">{{ retiro.empleado }}</td>
                                    <td data-label="TOTAL">{{ retiro.total }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </transition>
        </div>

        <div id="cajas-inicio-eliminadas-div">
            <div id="buscar-cajas-inicio-eliminadas">
                <h1>CAJA INICIO ELIMINADAS</h1>
                <div id="fecha-turno-div">
                    <div id="fecha-turno">
                        <Datepicker 
                            dark
                            auto-apply
                            hide-input-icon
                            hide-offset-dates
                            placeholder="Fecha"
                            v-model="cajaInicioFecha"
                            :enable-time-picker="false"
                            :format="format" 
                            locale="es" 
                            id="fecha"
                            position="center"
                            @cleared="limpiar('caja-inicio')"
                        />      
                    </div>
                    <span v-if="errorCajaInicio"><p class="error-select" style="color: red; height: 0;">{{ errorCajaInicio }}</p></span>
                </div>
                <div id="buscar-boton-div">
                    <button @click="buscarIniciosCaja" id="boton-buscar-eliminado" data-info="buscar-retiro-inicio">BUSCAR</button>
                </div>
            </div>

            <transition name="fade">
                <div id="table-wrapper-cajas-inicio-eliminadas" v-if="cajaInicio.length > 0">
                    <div id="tabla-cajas-inicio-eliminadas">
                        <table>
                            <thead>
                                <tr>
                                    <th>FECHA</th>
                                    <th>CAJER@</th>
                                    <th>FECHA ORIG.</th>
                                    <th>CAJER@ ORIG.</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="retiro in cajaInicio" :key="retiro.id">
                                    <td data-label="FECHA">{{ retiro.get_fecha }}</td>
                                    <td data-label="CAJERO">{{ retiro.cajero_actual }}</td>
                                    <td data-label="FECHA ORIG.">{{ retiro.get_fecha_original }}</td>
                                    <td data-label="CAJERO ORIG.">{{ retiro.cajero_original }}</td>
                                    <td data-label="TOTAL">{{ retiro.total }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </transition>
        </div>

        <div id="caja-retiros-eliminadas-div">
            <div id="buscar-cajas-retiro-eliminadas">
                <h1>CAJA RETIROS ELIMINADOS</h1>
                <div id="fecha-turno-div">
                    <div id="fecha-turno">
                        <Datepicker 
                            dark
                            auto-apply
                            hide-input-icon
                            hide-offset-dates
                            placeholder="Fecha"
                            v-model="retiroCajaFecha"
                            :enable-time-picker="false"
                            :format="format" 
                            locale="es" 
                            id="fecha"
                            position="center"
                            @cleared="limpiar('caja-retiro')"
                        />      
                    </div>
                    <span v-if="errorCajaRetiro"><p class="error-select" style="color: red; height: 0;">{{ errorCajaRetiro }}</p></span>
                </div>
                <div id="buscar-boton-div">
                    <button @click="buscarRetirosCaja" id="boton-buscar-eliminado" data-info="buscar-retiro-caja">BUSCAR</button>
                </div>
            </div>

            <transition name="fade">
                <div id="table-wrapper-cajas-retiro-eliminadas" v-if="cajaRetiros.length > 0">
                    <div id="tabla-cajas-retiro-eliminadas">
                        <table>
                            <thead>
                                <tr>
                                    <th>FECHA</th>
                                    <th>CAJER@</th>
                                    <th>FECHA ORIG.</th>
                                    <th>CAJER@ ORIG.</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="retiro in cajaRetiros" :key="retiro.id">
                                    <td data-label="FECHA">{{ retiro.get_fecha }}</td>
                                    <td data-label="CAJERO">{{ retiro.cajero_actual }}</td>
                                    <td data-label="FECHA ORIG.">{{ retiro.get_fecha_original }}</td>
                                    <td data-label="CAJERO ORIG.">{{ retiro.cajero_original }}</td>
                                    <td data-label="TOTAL">{{ retiro.total }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </transition>
        </div>

    </div>
</template>

<script>
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';

const date = ref(new Date());
// In case of a range picker, you'll receive [Date, Date]
const format = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}


export default {
    name: 'GestionEliminadoView',
    data() {
        return {
            format,
            trabajos:[],
            tienda:[],
            reservas:[],
            empleados:[],
            empleadosRetiros:[],
            cajaInicio:[],
            cajaRetiros:[],
            trabajoFecha:'',
            ventaFecha:'',
            reservaFecha:'',
            empleadoFecha:'',
            retiroEmpleadoFecha:'',
            cajaInicioFecha:'',
            retiroCajaFecha:'',
            errorTrabajo:'',
            errorVenta:'',
            errorReserva:'',
            errorEmpleado:'',
            errorRetiroEmpleado:'',
            errorCajaInicio:'',
            errorCajaRetiro:'',
        }
    },
    components: { 
        Datepicker
    },
    mounted() {
        document.title = 'Eliminado | Tijeras Gestion'
        this.setBgBody();
    },
    methods: {
        limpiar(d) {
            if (d == 'trabajo'){
                this.trabajos = []
            }
            if (d == 'tienda'){
                this.tienda = []
            }
            if (d == 'reservas') {
                this.reservas = []
            }
            if (d == 'empleado') {
                this.empleados = []
            }
            if (d == 'empleado-retiro') {
                this.empleadosRetiros = []
            }
            if (d == 'caja-inicio') {
                this.cajaInicio = []
            }
            if (d == 'caja-retiro') {
                this.cajaRetiros = []
            }
        },
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-gestion)");
            document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
        },
        async buscarTrabajo(e) {
            if (!this.trabajoFecha) {
                this.errorTrabajo= 'Requerida'
                setTimeout(() => this.errorTrabajo = '', 2000)
            }

            e.preventDefault();

            if (!this.errorTrabajo) {
                this.$store.commit('setIsLoading', true)
                let data = {
                    fecha:this.trabajoFecha
                }

                await axios
                    .post("/api/v1/trabajos/borrados/", data)
                    .then(response => {                 
                        this.trabajos = response.data                  
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL BUSCAR TRABAJO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                this.$store.commit('setIsLoading', false)
            }
        },
        async buscarVenta(e) {
            if (!this.ventaFecha) {
                this.errorVenta= 'Requerida'
                setTimeout(() => this.errorVenta = '', 2000)
            }

            e.preventDefault();

            if (!this.errorVenta) {
                this.$store.commit('setIsLoading', true)
                let data = {
                    fecha:this.ventaFecha
                }

                await axios
                    .post("/api/v1/tienda/borradas/", data)
                    .then(response => {           
                        this.tienda = response.data
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL BUSCAR VENTA',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                this.$store.commit('setIsLoading', false)
            }
        },
        async buscarReserva(e) {
            if (!this.reservaFecha) {
                this.errorReserva= 'Requerida'
                setTimeout(() => this.errorReserva = '', 2000)
            }

            e.preventDefault();

            if (!this.errorReserva) {
                this.$store.commit('setIsLoading', true)
                let data = {
                    fecha:this.reservaFecha
                }

                await axios
                    .post("/api/v1/reservas/borradas/", data)
                    .then(response => {
                        this.reservas = response.data
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL BUSCAR RESERVA',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                this.$store.commit('setIsLoading', false)
            }
        },
        async buscarEmpleado(e) {
            if (!this.empleadoFecha) {
                this.errorEmpleado= 'Requerida'
                setTimeout(() => this.errorEmpleado = '', 2000)
            }

            e.preventDefault();

            if (!this.errorEmpleado) {
                this.$store.commit('setIsLoading', true)
                let data = {
                    fecha:this.empleadoFecha
                }

                await axios
                    .post("/api/v1/empleados/borrados/", data)
                    .then(response => {
                        this.empleados = response.data
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL BUSCAR EMPLEAD@',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                this.$store.commit('setIsLoading', false)
            }
        },
        async buscarRetirosEmpleados(e) {
            if (!this.retiroEmpleadoFecha) {
                this.errorRetiroEmpleado= 'Requerida'
                setTimeout(() => this.errorRetiroEmpleado = '', 2000)
            }

            e.preventDefault();

            if (!this.errorRetiroEmpleado) {
                this.$store.commit('setIsLoading', true)
                let data = {
                    fecha:this.retiroEmpleadoFecha
                }

                await axios
                    .post("/api/v1/empleados/retiros-borrados/", data)
                    .then(response => {
                        this.empleadosRetiros = response.data
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL BUSCAR RETIRO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                this.$store.commit('setIsLoading', false)
            }
        },
        async buscarIniciosCaja(e) {
            if (!this.cajaInicioFecha) {
                this.errorCajaInicio = 'Requerida'
                setTimeout(() => this.errorCajaInicio = '', 2000)
            }

            e.preventDefault();

            if (!this.errorCajaInicio) {
                this.$store.commit('setIsLoading', true)
                let data = {
                    fecha:this.cajaInicioFecha
                }

                await axios
                    .post("/api/v1/caja/inicios/borrados/", data)
                    .then(response => {
                        this.cajaInicio = response.data
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL BUSCAR RETIRO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                this.$store.commit('setIsLoading', false)
            }
        },
        async buscarRetirosCaja(e) {
            if (!this.retiroCajaFecha) {
                this.errorCajaRetiro = 'Requerida'
                setTimeout(() => this.errorCajaRetiro = '', 2000)
            }

            e.preventDefault();

            if (!this.errorCajaRetiro) {
                this.$store.commit('setIsLoading', true)
                let data = {
                    fecha:this.retiroCajaFecha
                }

                await axios
                    .post("/api/v1/caja/retiros/borrados/", data)
                    .then(response => {
                        this.cajaRetiros = response.data
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL BUSCAR RETIRO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                this.$store.commit('setIsLoading', false)
            }
        },
        async eliminarTrabajo(e) {      
            Swal.fire({
                title: 'ELIMINAR TRABAJO?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#279f04',
                cancelButtonColor: '#d33',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                cancelButtonText: 'CANCELAR'
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = {
                        id: e.target.dataset.id
                    }
                    axios
                        .post("/api/v1/borrar-servicio/", data)
                        .then(response => {
                            this.getTrabajos()
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'SERVICIO ELIMINIDO',
                                showConfirmButton: false,
                                timer: 3000
                            })
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'EL SERVICIO NO PUDO SER ELIMINADO',
                                showConfirmButton: false,
                                timer: 3000
                            })
                        })
                }
            })
        }
    }
}
</script>

<style>
.gestion-eliminado-view {
    padding-top: 180px;
    text-align: center;
    color: white;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

#buscar-trabajo-eliminado,
#buscar-tienda-eliminada,
#buscar-reserva-eliminada,
#buscar-empleado-eliminado,
#buscar-retiros-empleados-eliminados,
#buscar-cajas-inicio-eliminadas,
#buscar-cajas-retiro-eliminadas {
    max-width: 400px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
    margin: 0 auto 50px auto;
}

#buscar-trabajo-eliminado h1,
#buscar-tienda-eliminada h1,
#buscar-reserva-eliminada h1,
#buscar-empleado-eliminado h1,
#buscar-retiros-empleados-eliminados h1,
#buscar-cajas-inicio-eliminadas h1,
#buscar-cajas-retiro-eliminadas h1 {
    text-shadow: var(--text-shadow)
}

#buscar-trabajo button,
#buscar-tienda button {
    margin-left: 20px;
    background-color: var(--button-color);
    border: none;
    color: #ffffff;
}

#buscar-trabajo button:hover,
#buscar-venta button:hover {
    background-color: var(--button-color-hover);
}

#buscar-input-div,
#buscar-boton-div {
    display: inline-table;
}

#table-wrapper-servicios-eliminados {
    position: relative;
    margin: 0 auto 50px auto;
}

#table-wrapper-ventas-eliminadas {
    position: relative;
    width: 60%;
    margin: 0 auto 50px auto;
}

#table-wrapper-reservas-eliminadas {
    position: relative;
    width: 70%;
    margin: 0 auto 50px auto;
}

#table-wrapper-empleados-eliminados {
    position: relative;
    width: 70%;
    margin: 0 auto 50px auto;
}

#table-wrapper-retiros-empleados-eliminados,
#table-wrapper-cajas-inicio-eliminadas,
#table-wrapper-cajas-retiro-eliminadas {
    position: relative;
    width: 50%;
    margin: 0 auto 50px auto;
}

#tabla-servicios-eliminados,
#tabla-ventas-eliminadas,
#tabla-reservas-eliminadas,
#tabla-empleados-eliminados,
#tabla-retiros-empleados-eliminados,
#tabla-cajas-inicio-eliminadas,
#tabla-cajas-retiro-eliminadas {
    max-height: 400px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    overflow: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    background:var(--bg-box-black-big);
    background-position: center center;
    background-size: cover;

}

#tabla-servicios-eliminados table,
#tabla-ventas-eliminadas table,
#tabla-reservas-eliminadas table,
#tabla-empleados-eliminados table,
#tabla-retiros-empleados-eliminados table,
#tabla-cajas-inicio-eliminadas table,
#tabla-cajas-retiro-eliminadas table {
    width: 100%;
}

#tabla-servicios-eliminados thead tr th,
#tabla-ventas-eliminadas thead tr th,
#tabla-reservas-eliminadas thead tr th,
#tabla-empleados-eliminados thead tr th,
#tabla-retiros-empleados-eliminados thead tr th,
#tabla-cajas-inicio-eliminadas thead tr th,
#tabla-cajas-retiro-eliminadas tr th {
    position: sticky;
    top: 0;
    background-color: #000000;
    border: none;
    text-align: center;
    font-size: 20px;
    text-align: center;
}

#tabla-servicios-eliminados table tr td,
#tabla-ventas-eliminadas table tr td,
#tabla-reservas-eliminadas table tr td,
#tabla-empleados-eliminados table tr td,
#tabla-retiros-empleados-eliminados table tr td,
#tabla-cajas-inicio-eliminadas table tr td,
#tabla-cajas-retiro-eliminadas table tr td {
    font-size: 18px;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;
}

#tabla-servicios-eliminados::-webkit-scrollbar-thumb,
#tabla-ventas-eliminadas::-webkit-scrollbar-thumb,
#tabla-reservas-eliminadas::-webkit-scrollbar-thumb,
#tabla-empleados-eliminados::-webkit-scrollbar-thumb,
#tabla-retiros-empleados-eliminados::-webkit-scrollbar-thumb,
#tabla-cajas-inicio-eliminadas::-webkit-scrollbar-thumb,
#tabla-cajas-retiro-eliminadas::-webkit-scrollbar-thumb {
    background-color: #555;
}

#tabla-servicios-eliminados::-webkit-scrollbar,
#tabla-ventas-eliminadas::-webkit-scrollbar,
#tabla-reservas-eliminadas::-webkit-scrollbar,
#tabla-empleados-eliminados::-webkit-scrollbar,
#tabla-retiros-empleados-eliminados::-webkit-scrollbar,
#tabla-cajas-inicio-eliminadas::-webkit-scrollbar,
#tabla-cajas-retiro-eliminadas::-webkit-scrollbar {
    width: 5px;
    background-color: #000000;
}

#boton-buscar-eliminado {
    background-color: var(--button-color);
    border: none;
    color: white;
}

#boton-buscar-eliminado:hover {
    background-color: var(--button-color-hover);
}

#trash-eliminados-mobile-icon {
    display: none;
}

#trash-eliminados {
    width: 20px;
    cursor: pointer;
    filter: invert(100%);
}

#total-eliminados {
    color: #ff3bff;
}


@media screen and (min-width: 1190px) and (max-width: 1350px) {

    #tabla-servicios-eliminados thead tr th,
    #tabla-ventas-eliminadas thead tr th,
    #tabla-reservas-eliminadas thead tr th,
    #tabla-empleados-eliminados thead tr th,
    #tabla-retiros-empleados-eliminados thead tr th,
    #tabla-cajas-inicio-eliminadas thead tr th,
    #tabla-cajas-retiro-eliminadas tr th {
        font-size: 18px;
    }

    #tabla-servicios-eliminados table tr td,
    #tabla-ventas-eliminadas table tr td,
    #tabla-reservas-eliminadas table tr td,
    #tabla-empleados-eliminados table tr td,
    #tabla-retiros-empleados-eliminados table tr td,
    #tabla-cajas-inicio-eliminadas table tr td,
    #tabla-cajas-retiro-eliminadas table tr td {
        font-size: 17px;
    }

}

@media screen and (min-width: 900px) and (max-width: 1190px) {
    #table-wrapper-servicios-eliminados,
    #table-wrapper-ventas-eliminadas,
    #table-wrapper-reservas-eliminadas,
    #table-wrapper-empleados-eliminados,
    #table-wrapper-retiros-empleados-eliminados,
    #table-wrapper-cajas-inicio-eliminadas,
    #table-wrapper-cajas-retiro-eliminadas {
        width: 100%;
    }

    #tabla-servicios-eliminados,
    #tabla-ventas-eliminadas,
    #tabla-reservas-eliminadas,
    #tabla-empleados-eliminados,
    #tabla-retiros-empleados-eliminados,
    #tabla-cajas-inicio-eliminadas,
    #tabla-cajas-retiro-eliminadas {
        padding: 10px;
    }

    #tabla-servicios-eliminados table tr td::before,
    #tabla-ventas-eliminadas table tr td::before,
    #tabla-reservas-eliminadas table tr td::before,
    #tabla-empleados-eliminados table tr td::before,
    #tabla-retiros-empleados-eliminados table tr td::before,
    #tabla-cajas-inicio-eliminadas table tr td::before,
    #tabla-cajas-retiro-eliminadas table tr td::before {
        font-size: 23px;
        text-align: right;
        text-shadow: var(--text-shadow);
    }

    #tabla-servicios-eliminados table tr td,
    #tabla-ventas-eliminadas table tr td,
    #tabla-reservas-eliminadas table tr td,
    #tabla-empleados-eliminados table tr td,
    #tabla-retiros-empleados-eliminados table tr td,
    #tabla-cajas-inicio-eliminadas table tr td,
    #tabla-cajas-retiro-eliminadas table tr td {
        font-size: 23px;
        text-align: right;
    }
    

    #tabla-servicios-buscar table tr td p:first-child,
    #tabla-ventas-buscar table tr td p:first-child {
        margin-top: 40px;
    }

    #tabla-servicios-eliminados thead,
    #tabla-ventas-eliminadas thead,
    #tabla-reservas-eliminadas thead,
    #tabla-empleados-eliminados thead,
    #tabla-retiros-empleados-eliminados thead,
    #tabla-cajas-inicio-eliminadas thead,
    #tabla-cajas-retiro-eliminadas thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    #tabla-servicios-eliminados tr,
    #tabla-ventas-eliminadas tr,
    #tabla-reservas-eliminadas tr,
    #tabla-empleados-eliminados tr,
    #tabla-retiros-empleados-eliminados tr,
    #tabla-cajas-inicio-eliminadas tr,
    #tabla-cajas-retiro-eliminadas tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    #tabla-servicios-eliminados table td,
    #tabla-ventas-eliminadas table td,
    #tabla-reservas-eliminadas table td,
    #tabla-empleados-eliminados table td,
    #tabla-retiros-empleados-eliminados table td,
    #tabla-cajas-inicio-eliminadas table td,
    #tabla-cajas-retiro-eliminadas table td {
        border-bottom: none;
        display: block;
        font-size: 25px;
        text-align: right;
    }

    #tabla-servicios-eliminados td::before,
    #tabla-ventas-eliminadas td::before,
    #tabla-reservas-eliminadas td::before,
    #tabla-empleados-eliminados td::before,
    #tabla-retiros-empleados-eliminados td::before,
    #tabla-cajas-inicio-eliminadas td::before,
    #tabla-cajas-retiro-eliminadas td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    #tabla-servicios-eliminados td:last-child
    #tabla-ventas-eliminadas td:last-child,
    #tabla-reservas-eliminadas td:last-child,
    #tabla-empleados-eliminados td:last-child,
    #tabla-retiros-empleados-eliminados td:last-child,
    #tabla-cajas-inicio-eliminadas td:last-child,
    #tabla-cajas-retiro-eliminadas td:last-child {
        border-bottom: 0;
        padding-right: 15px;
    }

    #tabla-servicios-eliminados td:first-child,
    #tabla-ventas-eliminadas td:first-child,
    #tabla-reservas-eliminadas td:first-child,
    #tabla-empleados-eliminados td:first-child,
    #tabla-retiros-empleados-eliminados td:first-child,
    #tabla-cajas-inicio-eliminadas td:first-child,
    #tabla-cajas-retiro-eliminadas td:first-child {
        display: none;
    }

    #tabla-servicios-eliminados,
    #tabla-ventas-eliminadas,
    #tabla-reservas-eliminadas,
    #tabla-empleados-eliminados,
    #tabla-retiros-empleados-eliminados,
    #tabla-cajas-inicio-eliminadas,
    #tabla-cajas-retiro-eliminadas {
        display: block;
        overflow-y: scroll;
        max-height: 300px;
        width: 600px;
        margin-left: auto;
        margin-right: auto;
    }

    #trash-eliminados-icon,
    #trash-eliminados {
        display: none;
        height: 0;
    }

    #trash-eliminados-mobile-icon {
        display: initial;
    }

    #trash-eliminados-mobile {
        width: 28px;
        cursor: pointer;
        filter: invert(100%);
    }

    #tabla-servicios-eliminados table tbody tr:hover,
    #tabla-ventas-eliminadas table tbody tr:hover,
    #tabla-reservas-eliminadas table tbody tr:hover,
    #tabla-empleados-eliminados table tbody tr:hover,
    #tabla-retiros-empleados-eliminados table tbody tr:hover,
    #tabla-cajas-inicio-eliminadas table tbody tr:hover,
    #tabla-cajas-retiro-eliminadas table tbody tr:hover {
        background-color: #55555500;
    }

    #total-eliminados {
        color: #ffffff;
    }
}

@media screen and (max-width: 900px) {
    #table-wrapper-servicios-eliminados,
    #table-wrapper-ventas-eliminadas,
    #table-wrapper-reservas-eliminadas,
    #table-wrapper-empleados-eliminados,
    #table-wrapper-retiros-empleados-eliminados,
    #table-wrapper-cajas-inicio-eliminadas,
    #table-wrapper-cajas-retiro-eliminadas {
        width: 100%;
    }

    #tabla-servicios-eliminados,
    #tabla-ventas-eliminadas,
    #tabla-reservas-eliminadas,
    #tabla-empleados-eliminados,
    #tabla-retiros-empleados-eliminados,
    #tabla-cajas-inicio-eliminadas,
    #tabla-cajas-retiro-eliminadas {
        padding: 10px;
    }

    #tabla-servicios-eliminados table tr td::before,
    #tabla-ventas-eliminadas table tr td::before,
    #tabla-reservas-eliminadas table tr td::before,
    #tabla-empleados-eliminados table tr td::before,
    #tabla-retiros-empleados-eliminados table tr td::before,
    #tabla-cajas-inicio-eliminadas table tr td::before,
    #tabla-cajas-retiro-eliminadas table tr td::before {
        font-size: 20px;
        text-align: right;
        text-shadow: var(--text-shadow);
    }

    #tabla-servicios-eliminados table tr td,
    #tabla-ventas-eliminadas table tr td,
    #tabla-reservas-eliminadas table tr td,
    #tabla-empleados-eliminados table tr td,
    #tabla-retiros-empleados-eliminados table tr td,
    #tabla-cajas-inicio-eliminadas table tr td,
    #tabla-cajas-retiro-eliminadas table tr td {
        font-size: 23px;
        text-align: right;
    }
    

    #tabla-servicios-buscar table tr td p:first-child,
    #tabla-ventas-buscar table tr td p:first-child {
        margin-top: 40px;
    }

    #tabla-servicios-eliminados thead,
    #tabla-ventas-eliminadas thead,
    #tabla-reservas-eliminadas thead,
    #tabla-empleados-eliminados thead,
    #tabla-retiros-empleados-eliminados thead,
    #tabla-cajas-inicio-eliminadas thead,
    #tabla-cajas-retiro-eliminadas thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    #tabla-servicios-eliminados tr,
    #tabla-ventas-eliminadas tr,
    #tabla-reservas-eliminadas tr,
    #tabla-empleados-eliminados tr,
    #tabla-retiros-empleados-eliminados tr,
    #tabla-cajas-inicio-eliminadas tr,
    #tabla-cajas-retiro-eliminadas tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    #tabla-servicios-eliminados table td,
    #tabla-ventas-eliminadas table td,
    #tabla-reservas-eliminadas table td,
    #tabla-empleados-eliminados table td,
    #tabla-retiros-empleados-eliminados table td,
    #tabla-cajas-inicio-eliminadas table td,
    #tabla-cajas-retiro-eliminadas table td {
        border-bottom: none;
        display: block;
        font-size: 25px;
        text-align: right;
    }

    #tabla-servicios-eliminados td::before,
    #tabla-ventas-eliminadas td::before,
    #tabla-reservas-eliminadas td::before,
    #tabla-empleados-eliminados td::before,
    #tabla-retiros-empleados-eliminados td::before,
    #tabla-cajas-inicio-eliminadas td::before,
    #tabla-cajas-retiro-eliminadas td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    #tabla-servicios-eliminados td:last-child
    #tabla-ventas-eliminadas td:last-child,
    #tabla-reservas-eliminadas td:last-child,
    #tabla-empleados-eliminados td:last-child,
    #tabla-retiros-empleados-eliminados td:last-child,
    #tabla-cajas-inicio-eliminadas td:last-child,
    #tabla-cajas-retiro-eliminadas td:last-child {
        border-bottom: 0;
        padding-right: 15px;
    }

    #tabla-servicios-eliminados td:first-child,
    #tabla-ventas-eliminadas td:first-child,
    #tabla-reservas-eliminadas td:first-child,
    #tabla-empleados-eliminados td:first-child,
    #tabla-retiros-empleados-eliminados td:first-child,
    #tabla-cajas-inicio-eliminadas td:first-child,
    #tabla-cajas-retiro-eliminadas td:first-child {
        display: none;
    }

    #tabla-servicios-eliminados,
    #tabla-ventas-eliminadas,
    #tabla-reservas-eliminadas,
    #tabla-empleados-eliminados,
    #tabla-retiros-empleados-eliminados,
    #tabla-cajas-inicio-eliminadas,
    #tabla-cajas-retiro-eliminadas {
        display: block;
        overflow-y: scroll;
        max-height: 300px;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    #trash-eliminados-icon,
    #trash-eliminados {
        display: none;
        height: 0;
    }

    #trash-eliminados-mobile-icon {
        display: initial;
    }

    #trash-eliminados-mobile {
        width: 28px;
        cursor: pointer;
        filter: invert(100%);
    }

    #total-eliminados {
        color: #ffffff;
    }

}</style>