import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: false,
    isAuthenticated: false,
    token: '',
    admin: false,
    cajero: false,
    horaLogin:''
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
          state.admin = localStorage.getItem('admin')
          state.horaLogin = localStorage.getItem('horaLogin')
      } else {
          state.token = ''
          state.isAuthenticated = false
          state.admin = false
          state.horaLogin = ''
      } 
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },  
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
      state.admin = false
    },
    setRol(state, rol) {
      state.admin = rol
    },
    setHoraLogin(state, horaLogin) {
      state.horaLogin = horaLogin
    }
  },
  actions: {
  },
  modules: {
  }
})
