<template>
    <div class="servicios-ventas-view">
        <div class="row" id="servicios-row">
            <div id="buscar-servicio">
                <h1>SERVICIO(S) CLIENTE</h1>
                <p id="buscar-por">POR APELLIDO, NOMBRE O TELEFONO</p>

                <div id="buscar-input-div">
                    <div class="field field_v2">
                        <label for="buscar-input" class="ha-screen-reader">Cliente</label>
                        <input v-model="buscarServicio" id="buscar-input" class="field__input" placeholder="ej. Gonzales"
                            autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Cliente</span>
                        </span>
                    </div>
                </div>

                <div id="buscar-boton-div">
                    <button @click="buscar" id="buscar-boton" data-info="buscar-servicio">BUSCAR</button>
                </div>
            </div>

            <div id="table-wrapper">
                <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                    <div class="lds-dual-ring"></div>
                </div>
                <div id="tabla-servicios-buscar">
                    <table>
                        <thead>
                            <tr>
                                <th id="th-eliminar-servicios"></th>
                                <th>FECHA</th>
                                <th>HORA</th>
                                <th>CLIENTE</th>
                                <th>TELEFONO</th>
                                <th>SERVICIO</th>
                                <th>PRODUCTO</th>
                                <th id="th-cantidad-servicios">CANTIDAD</th>
                                <th>EMPLEADO</th>
                                <th>CAJERO</th>
                                <th id="th-descuento-servicios">DESCUENTO</th>
                                <th>TARJETA</th>
                                <th>PRECIO</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="trabajo in trabajos" :key="trabajo.id">
                                <td id="delete-icon"><img id="trash-buscar" src="@/assets/images/trash.png" alt="trash-icon"
                                        title="Eliminar" @click="removeServicioTienda" data-info="servicio-tabla"
                                        :data-id="trabajo.id"></td>
                                <td data-label="FECHA">{{ trabajo.get_fecha }}</td>
                                <td data-label="HORA">{{ trabajo.get_hora }} HS</td>
                                <td data-label="CLIENTE">{{ trabajo.apellido }} {{ trabajo.nombre }}</td>
                                <td data-label="TELEFONO">{{ trabajo.telefono }}</td>
                                <td data-label="SERVICIO">{{ trabajo.trabajo }}</td>
                                <td data-label="PRODUCTO" id="producto-servicios-ventas" v-if="trabajo.productos.length">
                                    <p v-for="producto in trabajo.productos" :key="producto.id">
                                        {{ producto.producto }}
                                    </p>
                                </td>
                                <td v-else id="empty"></td>
                                <td data-label="CANTIDAD" id="cantidad-servicios-ventas" v-if="trabajo.productos.length">
                                    <p v-for="producto in trabajo.productos" :key="producto.id">
                                        {{ producto.cantidad }} {{ producto.unidad }}
                                    </p>
                                </td>
                                <td v-else id="empty"></td>
                                <td data-label="EMPLEADO">{{ trabajo.empleado }}</td>
                                <td data-label="CAJERO">{{ trabajo.cajero }}</td>
                                <td data-label="DESCUENTO">{{ trabajo.descuento }}%</td>
                                <td data-label="TARJETA">{{ trabajo.tarjeta }}</td>
                                <td data-label="PRECIO">${{ trabajo.precio }}</td>
                                <div id="trash-mobile-icon">
                                    <img id="trash-buscar" src="@/assets/images/trash.png" alt="trash-icon"
                                        @click="removeServicioTienda" data-info="servicio-tabla" :data-id="trabajo.id">
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row">
                <div id="anterior-siguiente">
                    <p id="anterior" v-if="serviciosCounter > 1" @click="decrease" data-info="servicio">
                        <img src="@/assets/images/siguiente.png" alt="">
                    </p>
                    <p id="siguiente" @click="increase" data-info="servicio" v-if="trabajos.length > 20">
                        <img src="@/assets/images/siguiente.png" alt="">
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div id="ventas-row">
                <div id="buscar-tienda">
                    <h1>VENTA(S) TIENDA</h1>
                    <p id="buscar-por-tienda">POR NOMBRE O APELLIDO</p>
                    <div id="buscar-input-div">
                        <div class="field field_v2">
                            <label for="buscar-input" class="ha-screen-reader">Cajero</label>
                            <input v-model="buscarTienda" id="buscar-input" class="field__input" placeholder="ej. Gonzales"
                                autocomplete="off">
                            <span class="field__label-wrap" aria-hidden="true">
                                <span class="field__label">Cajero</span>
                            </span>
                        </div>
                    </div>
                    <div id="buscar-boton-div">
                        <button @click="buscar" id="buscar-boton" data-info="buscar-tienda">BUSCAR</button>
                    </div>
                </div>

                <div id="table-wrapper">
                    <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                        <div class="lds-dual-ring"></div>
                    </div>
                    <div id="tabla-ventas-buscar">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>FECHA</th>
                                    <th>HORA</th>
                                    <th id="th-producto-venta">PRODUCTO</th>
                                    <th id="th-cantidad-venta">CANTIDAD</th>
                                    <th>CAJERO</th>
                                    <th id="th-descuento-venta">DESCUENTO</th>
                                    <th id="th-tarjeta-venta">TARJETA</th>
                                    <th>PRECIO</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="venta in tienda" :key="venta.id">
                                    <td id="delete-icon"><img id="trash-buscar" src="@/assets/images/trash.png"
                                            alt="trash-icon" title="ELIMINAR" @click="removeServicioTienda"
                                            data-info="tienda-tabla" :data-id="venta.id"></td>
                                    <td data-label="FECHA">{{ venta.get_fecha }}</td>
                                    <td data-label="HORA">{{ venta.get_hora }} HS</td>
                                    <td data-label="PRODUCTO" id="producto-servicios-ventas">
                                        <p v-for="producto in venta.productos" :key="producto.id">
                                            {{ producto.producto }}
                                        </p>
                                    </td>

                                    <td data-label="CANTIDAD" id="cantidad-servicios-ventas">
                                        <p v-for="producto in venta.productos" :key="venta.id">
                                            {{ producto.cantidad }}
                                        </p>
                                    </td>
                                    <td data-label="CAJERO">{{ venta.cajero }}</td>
                                    <td data-label="DESCUENTO">{{ venta.descuento }}%</td>
                                    <td data-label="TARJETA">{{ venta.tarjeta }}</td>
                                    <td data-label="PRECIO">${{ venta.precio }}</td>
                                    <div id="trash-mobile-icon">
                                        <img id="trash-buscar" src="@/assets/images/trash.png" alt="trash-icon"
                                            @click="removeServicioTienda" data-info="tienda-tabla" :data-id="venta.id">
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div class="row">
                    <div id="anterior-siguiente">
                        <p id="anterior" v-if="tiendaCounter > 1" @click="decrease" data-info="tienda">
                            <img src="@/assets/images/siguiente.png" alt="">
                        </p>
                        <p id="siguiente" @click="increase" data-info="tienda" v-if="tienda.length > 20">
                            <img src="@/assets/images/siguiente.png" alt="">
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'BuscarView',
    data() {
        return {
            trabajos: [],
            tienda: [],
            buscarServicio: '',
            buscarTienda: '',
            serviciosCounter: 1,
            tiendaCounter: 1,
            search: false
        }
    },
    mounted() {
        document.title = 'Buscar | Tijeras Gestion'
        this.getTrabajos();
        this.getTiendaVentas();
        this.setBgBody();
    },
    methods: {
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-gestion)");
            document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
        },
        increase(e) {
            if (this.search == false) {
                if (e.target.dataset.info == 'servicio') {
                    this.serviciosCounter++
                    this.getTrabajos()
                } else {
                    this.tiendaCounter++
                    this.getTiendaVentas()
                }
            } else {
                if (e.target.dataset.info == 'servicio') {
                    this.serviciosCounter++
                    this.buscar('buscar-servicio')
                } else {
                    this.tiendaCounter++
                    this.buscar('buscar-tienda')
                }

            }
        },
        decrease(e) {
            if (this.search == false) {
                if (e.target.dataset.info == 'servicio') {
                    this.serviciosCounter--
                    if (this.serviciosCounter == 0) {
                        this.serviciosCounter = 1
                    }
                    this.getTrabajos()
                } else {
                    this.tiendaCounter--
                    if (this.tiendaCounter == 0) {
                        this.tiendaCounter = 1
                    }
                    this.getTiendaVentas()
                }
            } else {
                if (e.target.dataset.info == 'servicio') {
                    this.serviciosCounter--
                    if (this.serviciosCounter == 0) {
                        this.serviciosCounter = 1
                    }
                    this.buscar('buscar-servicio')
                } else {
                    this.tiendaCounter--
                    if (this.tiendaCounter == 0) {
                        this.tiendaCounter = 1
                    }
                    this.buscar('buscar-tienda')
                }
            }
        },
        async buscar(e) {
            if (this.buscarTienda == '') {
                this.tiendaCounter = 1
                this.getTiendaVentas()
            }
            if (this.buscarServicio == '') {
                this.serviciosCounter = 1
                this.getTrabajos()
            }

            let data = {
                info: '',
                query: '',
                contador: ''
            }

            this.search = true

            if (e.target == undefined) {
                if (e == 'buscar-servicio') {
                    data.query = this.buscarServicio
                    data.info = 'servicio'
                    data.contador = this.serviciosCounter
                } else {
                    data.query = this.buscarTienda
                    data.info = 'tienda'
                    data.contador = this.tiendaCounter
                }
            } else {
                if (e.target.dataset.info == 'buscar-servicio') {
                    data.query = this.buscarServicio
                    data.info = 'servicio'
                    data.contador = this.serviciosCounter
                } else {
                    data.query = this.buscarTienda
                    data.info = 'tienda'
                    data.contador = this.tiendaCounter
                }
            }

            await axios
                .post("/api/v1/buscar/", data)
                .then(response => {
                    if (data.info == 'servicio') {
                        if (response.data.length > 0) {
                            this.trabajos = response.data
                        }
                    } else {
                        if (response.data.length > 0) {
                            this.tienda = response.data
                        }
                    }

                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })

        },
        async removeServicioTienda(e) {
            if (e.target.dataset.info == 'servicio-tabla') {
                Swal.fire({
                    title: 'ELIMINAR SERVICIO?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#279f04',
                    cancelButtonColor: '#d33',
                    confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                    cancelButtonText: 'CANCELAR'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const data = {
                            id: e.target.dataset.id
                        }
                        axios
                            .post("/api/v1/trabajos/borrar/", data)
                            .then(response => {
                                this.getTrabajos()
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'SERVICIO ELIMINIDO',
                                    showConfirmButton: false,
                                    timer: 3000
                                })
                            })
                            .catch(error => {
                                console.log(JSON.stringify(error))
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'error',
                                    title: 'EL SERVICIO NO PUDO SER ELIMINADO',
                                    showConfirmButton: false,
                                    timer: 3000
                                })
                            })
                    }
                })

            }
            if (e.target.dataset.info == 'tienda-tabla') {
                Swal.fire({
                    title: 'ELIMINAR VENTA?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#279f04',
                    cancelButtonColor: '#d33',
                    confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                    cancelButtonText: 'CANCELAR'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const data = {
                            id: e.target.dataset.id
                        }
                        axios
                            .post("/api/v1/tienda/borrar/", data)
                            .then(response => {
                                this.getTiendaVentas()
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'VENTA ELIMINADA',
                                    showConfirmButton: false,
                                    timer: 3000
                                })
                            })
                            .catch(error => {
                                console.log(JSON.stringify(error))
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'error',
                                    title: 'LA VENTA NO PUDO SER ELIMINADA',
                                    showConfirmButton: false,
                                    timer: 3000
                                })
                            })
                    }
                })
            }
        },
        async getTrabajos() {
            this.$store.commit('setIsLoading', true)
            let data = {
                contador: this.serviciosCounter
            }
            await axios
                .post("/api/v1/trabajos/", data)
                .then(response => {
                    if (response.data.length > 0) {
                        this.trabajos = response.data
                    } else {
                        this.serviciosCounter--
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("token")
                        localStorage.removeItem("username")
                        localStorage.removeItem("userid")
                        this.$store.commit('removeToken')
                        this.$router.push('/gestion')
                    }
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getTiendaVentas() {
            this.$store.commit('setIsLoading', true)
            let data = {
                contador: this.tiendaCounter
            }

            await axios
                .post("/api/v1/tienda/", data)
                .then(response => {
                    if (response.data.length > 0) {
                        this.tienda = response.data
                    } else {
                        this.tiendaCounter--
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>

<style>
.servicios-ventas-view {
    padding-top: 180px;
    text-align: center;
    color: white;
}

#servicios-row {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}

#buscar-servicio,
#buscar-tienda {
    max-width: 400px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
    margin: 0 auto 50px auto;
}

#th-eliminar-servicios {
    width: 50px;
}

#th-producto-venta {
    width: 200px;
}

#th-descuento-servicios {
    width: 90px;
}

#th-cantidad-servicios {
    width: 70px;
}

#th-cantidad-venta,
#th-descuento-venta,
#th-tarjeta-venta {
    width: 100px;
}

#servicios-row h1,
#ventas-row h1 {
    text-shadow: var(--text-shadow)
}

#buscar-por,
#buscar-por-tienda {
    font-size: 15px;
    text-shadow: var(--text-shadow)
}

#ventas-row {
    margin-top: 40px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
}


#buscar-servicio button,
#buscar-tienda button {
    margin-left: 20px;
    background-color: var(--button-color);
    border: none;
    color: #ffffff;
}

#buscar-servicio button:hover,
#buscar-tienda button:hover {
    background-color: var(--button-color-hover);
}

#buscar-input-div,
#buscar-boton-div {
    display: inline-table;
}

#buscar-input {
    width: 130px;
    margin-bottom: 13px;
}

#anterior-siguiente {
    text-align: center;
}

#anterior,
#siguiente {
    display: inline-table;
    margin: 20px 10px 10px 10px;
    cursor: pointer;
}

#anterior img {
    width: 50px;
    transform: rotate(180deg);
    background-color: black;
    border-radius: 50%;
    box-shadow: 0px 18px 22px 0px #000000;
}

#siguiente img {
    width: 30px;
    background-color: black;
    border-radius: 50%;
    box-shadow: 0px 18px 22px 0px #000000;
}

#table-wrapper {
    position: relative;
    width: 100%;
}

#tabla-servicios-buscar,
#tabla-ventas-buscar {
    max-height: 500px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    overflow: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    background:
        var(--image);
    background-position: center center;
    background-size: cover;
    --image: url("@/assets/images/pizarron-grande.png");
}


#tabla-servicios-buscar thead tr th,
#tabla-ventas-buscar thead tr th {
    position: sticky;
    top: 0;
    background-color: #000000;
    border: none;
    text-align: center;
    font-size: 20px;
}

#tabla-servicios-buscar table tr td,
#tabla-ventas-buscar table tr td {
    font-size: 20px;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;
}

#producto-servicios-ventas p,
#cantidad-servicios-ventas p {
    margin-bottom: inherit;
}

#tabla-servicios-buscar::-webkit-scrollbar-thumb,
#tabla-ventas-buscar::-webkit-scrollbar-thumb {
    background-color: #555;
}

#tabla-servicios-buscar::-webkit-scrollbar,
#tabla-ventas-buscar::-webkit-scrollbar {
    width: 5px;
    background-color: #000000;
}

#trash-mobile-icon {
    display: none;
}

#trash-buscar {
    width: 20px;
    cursor: pointer;
    filter: invert(100%);
}



@media screen and (min-width: 1200px) and (max-width: 1500px) {

    #tabla-servicios-buscar thead tr th,
    #tabla-ventas-buscar thead tr th {
        font-size: 17px;
    }

    #tabla-servicios-buscar table tr td,
    #tabla-ventas-buscar table tr td,
    #producto-servicios-ventas p,
    #cantidad-servicios-ventas p {
        font-size: 16px;
    }

}

@media screen and (min-width: 900px) and (max-width: 1200px) {

    #tabla-servicios-buscar table tr td,
    #tabla-ventas-buscar table tr td {
        font-size: 23px;
    }

    #delete-icon {
        display: none;
    }

    #producto-servicios-ventas,
    #cantidad-servicios-ventas {
        display: inline-block;
    }

    #tabla-servicios-buscar table tr td,
    #tabla-ventas-buscar table tr td {
        font-size: 18px;
        text-align: right;
        padding: 10px;
    }

    #tabla-servicios-buscar table tr td p:first-child,
    #tabla-ventas-buscar table tr td p:first-child {
        margin-top: 40px;
    }

    #producto-servicios-ventas p {
        text-align: left;
    }

    .servicios-ventas-view thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .servicios-ventas-view tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    .servicios-ventas-view table td {
        border-bottom: none;
        display: block;
        font-size: 25px;
        text-align: right;
    }

    .servicios-ventas-view td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .servicios-ventas-view td:last-child {
        border-bottom: 0;
    }

    .servicios-ventas-view td:last-child {
        padding-right: 15px;
    }

    .servicios-ventas-view td:first-child {
        padding-left: 15px;
    }

    #td-servicios-ventas {
        margin-top: 25px;
        text-align: center;
    }

    #empty {
        display: none;
    }

    #tabla-servicios-buscar tbody,
    #tabla-ventas-buscar tbody {
        display: block;
        overflow-y: scroll;
        max-height: 300px;
    }

    #tabla-servicios-buscar tbody::-webkit-scrollbar-thumb,
    #tabla-ventas-buscar tbody::-webkit-scrollbar-thumb {
        background-color: #555;
    }

    #tabla-servicios-buscar tbody::-webkit-scrollbar,
    #tabla-ventas-buscar tbody::-webkit-scrollbar {
        width: 5px;
        background-color: #000000;
    }

    #tabla-servicios-buscar::-webkit-scrollbar-thumb,
    #tabla-ventas-buscar::-webkit-scrollbar-thumb,
    #tabla-servicios-buscar::-webkit-scrollbar,
    #tabla-ventas-buscar::-webkit-scrollbar  {
        display: none;
    }

    #boton-borrar {
        display: initial;
    }

    #trash-mobile-icon {
        display: initial;
    }

    #trash {
        width: 32px;
        cursor: pointer;
    }
}

@media screen and (max-width: 900px) {

    .servicios-ventas-view {
        padding-top: 130px;
    }

    #tabla-servicios-buscar table tr td,
    #tabla-ventas-buscar table tr td {
        font-size: 18px;
        text-align: right;
        padding: 10px;
    }

    #tabla-servicios-buscar table tr td p:first-child,
    #tabla-ventas-buscar table tr td p:first-child {
        margin-top: 40px;
    }

    #delete-icon {
        display: none;
    }

    #producto-servicios-ventas,
    #cantidad-servicios-ventas {
        display: inline-block;
    }

    #producto-servicios-ventas p {
        text-align: left;
    }

    .servicios-ventas-view thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .servicios-ventas-view tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    .servicios-ventas-view table td {
        border-bottom: none;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    .servicios-ventas-view td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    .servicios-ventas-view td:last-child {
        border-bottom: 0;
    }

    .servicios-ventas-view td:last-child {
        padding-right: 15px;
    }

    .servicios-ventas-view td:first-child {
        padding-left: 15px;
    }

    #td-servicios-ventas {
        margin-top: 15px;
        margin-bottom: -10px;
        height: 40px;
    }

    #empty {
        display: none;
    }

    #tabla-servicios-buscar tbody,
    #tabla-ventas-buscar tbody {
        display: block;
        overflow-y: scroll;
        max-height: 300px;
    }

    #tabla-servicios-buscar::-webkit-scrollbar,
    #tabla-ventas-buscar::-webkit-scrollbar {
        display: none;
    }

    #tabla-servicios-buscar::-webkit-scrollbar-thumb,
    #tabla-ventas-buscar::-webkit-scrollbar-thumb {
        display: none;
    }

    #tabla-servicios-buscar::-webkit-scrollbar,
    #tabla-ventas-buscar::-webkit-scrollbar {
        display: none;
    }

    #tabla-servicios-buscar tbody::-webkit-scrollbar-thumb,
    #tabla-ventas-buscar tbody::-webkit-scrollbar-thumb {
        background-color: #555;
    }

    #tabla-servicios-buscar tbody::-webkit-scrollbar,
    #tabla-ventas-buscar tbody::-webkit-scrollbar {
        display: none;
    }

    #boton-borrar {
        display: initial;
    }

    #trash-mobile-icon {
        display: initial;
    }


}</style>