<template>
    <div id="gestion-stock-view">
        
            <div id="crear-categoria-tabla-stock-div">
                <h1>CREAR CATEGORIAS</h1>
                <div id="crear-categoria-stock-div">
                    <div id="crear-categoria-stock-inner">
                        <div class="field field_v2">
                            <label for="categoria-stock-nombre" class="ha-screen-reader">Nombre</label>
                            <input v-model="nombreCategoria" id="categoria-stock-nombre" class="field__input" placeholder="ej. Tintura 3" autocomplete="off">
                            <span class="field__label-wrap" aria-hidden="true">
                                <span class="field__label">Nombre</span>
                            </span>
                        </div>    
                        <span v-if="errorNombreCategoria"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorNombreCategoria }}</p></span>
                    </div>

                    <div>
                        <button id="boton-crear-categoria-stock" @click="ingresarCategoria" data-info="guardar">GUARDAR</button>
                    </div>
                </div>
                
                <div id="crear-categoria-stock-tabla">
                    <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                        <div class="lds-dual-ring"></div>
                    </div>

                    <div id="table-wrapper-crear-categoria-stock">
                        <div id="tabla-crear-categoria-stock">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>NOMBRE</th>
                                    </tr> 
                                </thead>

                                <tbody>
                                    <tr
                                        v-for="categoria in categorias"
                                        :key="categoria.id">
                                        <td><img id="trash-categoria-stock" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarCategoria" title="Eliminar" data-info="stock-categoria-tabla" :data-id="categoria.id"></td>
                                        <td data-label="NOMBRE">{{ categoria.nombre }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="ingresar-stock">
                <h1>INGRESAR STOCK</h1>
                <div id="producto-stock-div">
                    <div class="field field_v2">
                        <label for="producto-ingreso" class="ha-screen-reader">Producto</label>
                        <input v-model="nombre" id="producto-ingreso" class="field__input" placeholder="ej. Tintura 3" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Producto</span>
                        </span>
                    </div>    
                    <span v-if="errorNombre"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorNombre }}</p></span>
                </div>

                <div id="cantidad-stock-div">
                    <div class="field field_v2">
                        <label for="cantidad-ingreso" class="ha-screen-reader">Cantidad</label>
                        <input v-model="cantidad" id="cantidad-ingreso" class="field__input" placeholder="ej. 100" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Cantidad</span>
                        </span>
                    </div>    
                    <span v-if="errorCantidad"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorCantidad }}</p></span>    
                </div>

                <div id="unidad-stock-div">
                    <div id="unidad-stock">
                        <select id="unidad-ingreso" class="select-style" @change="handleChange" v-model="unidad">
                            <option value="" disabled hidden>Unidad</option>
                            <option v-for="item in unidades" :key="item.unidad" data-info="unidad" :data-short="item.short">
                                    {{ item.unidad }} 
                            </option>
                        </select>
                    </div>
                    <span v-if="errorUnidad"><p class="error-select" style="color: red; height: 0;">{{ errorUnidad }}</p></span>
                </div>

                <div id="categoria-stock-div">
                    <div id="categoria-stock">
                        <select id="categoria-ingreso" class="select-style" @change="handleChange" v-model="categoria">
                            <option value="" disabled hidden>Categoria</option>
                            <option v-for="categoria in categorias" :key="categoria.id" data-info="categoria">
                                    {{ categoria.nombre }} 
                            </option>
                        </select>
                    </div>
                    <span v-if="errorCategoria"><p class="error-select" style="color: red; height: 0;">{{ errorCategoria }}</p></span>
                </div>

                <div id="precio-stock-div" v-if="categoriaTienda">
                    <div class="field field_v2">
                        <label for="precio-ingreso" class="ha-screen-reader">Precio</label>
                        <input v-model="precio" id="precio-ingreso" class="field__input" placeholder="ej. 1000" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Precio</span>
                        </span>
                    </div>    
                    <span v-if="errorPrecio"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorPrecio }}</p></span>
                </div>

                <div id="boton-guardar-stock">
                    <button id="boton-agregar-stock" @click="ingresarProducto" data-info="guardar">GUARDAR</button>
                </div>
            </div>  
       

      
            <div id="cambiar-precio-stock">
                <h1>ACTUALIZAR PRECIOS</h1>
                <div id="porcentaje-stock-div">
                    <div class="field field_v2">
                        <label for="porcentaje-stock" class="ha-screen-reader">Porcentaje</label>
                        <input v-model="porcentaje" id="porcentaje-stock" class="field__input" placeholder="ej. 15" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Porcentaje</span>
                        </span>
                    </div>    
                </div>

                <div id="pesos-stock-div">
                    <div class="field field_v2">
                        <label for="pesos-stock" class="ha-screen-reader">Pesos</label>
                        <input v-model="pesos" id="pesos-stock" class="field__input" placeholder="ej. 200" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Pesos</span>
                        </span>
                    </div>    
                </div>

                <span v-if="errorActualizarPrecio"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorActualizarPrecio }}</p></span>

                <button id="boton-precio-aumentar" @click="actualizarPrecio" data-info="aumentar">AUMENTAR</button>
                <button id="boton-precio-reducir" @click="actualizarPrecio" data-info="reducir">REDUCIR</button>
            </div>

            <div id="buscar-stock">
                <h1>BUSCAR</h1>
                <div id="producto-buscar-stock-div">
                    <div class="field field_v2">
                        <label for="producto-buscar" class="ha-screen-reader">Producto</label>
                        <input v-model="productoBuscar" id="producto-buscar" class="field__input" placeholder="ej. Tintura 3" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Producto</span>
                        </span>
                    </div>    
                    <span v-if="errorProducto"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorProducto }}</p></span>
                </div>
                <button id="boton-buscar-stock" @click="buscarStock" data-info="buscar-boton">BUSCAR</button>
                <button v-if="verTodo" id="boton-ver-todo-stock" @click="todoStock" data-info="ver-todo-boton">VER TODO</button>
            </div>

            <div id="stock-tabla">
                <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                    <div class="lds-dual-ring"></div>
                </div>

                <div id="table-wrapper-stock">
                    <div id="tabla-stock">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>CATEGORIA</th>
                                    <th>PRODUCTO</th>
                                    <th>CANTIDAD</th>
                                    <th>PRECIO</th>
                                </tr> 
                            </thead>

                            <tbody>
                                <tr
                                    v-for="item in stock" :key="item.id">
                                    <td id="trash-settings-stock-div">
                                        <img id="settings-stock" src="@/assets/images/settings.png" alt="settings-icon" @click="editarProducto" title="Editar" data-info="stock-tabla" :data-id="item.id"> 
                                        <img id="trash-stock" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarStock" title="Eliminar" data-info="stock-tabla" :data-id="item.id">
                                    </td>
                                    <td data-label="CATEGORIA">{{ item.categoria }}</td>
                                    <td data-label="PRODUCTO">{{ item.producto }}</td>
                                    <td data-label="CANTIDAD" v-if="item.cantidad < 0" id="cantidad-negativa">{{ item.cantidad }} {{ item.unidad }}(s)</td>
                                    <td data-label="CANTIDAD" v-else-if="item.cantidad < 20" id="cantidad-menor">{{ item.cantidad }} {{ item.unidad }}(s)</td>
                                    <td data-label="CANTIDAD" v-else id="cantidad-normal">{{ item.cantidad }} {{ item.unidad }}(s)</td>
                                    <td data-label="PRECIO" v-if="item.precio == 0">-</td>
                                    <td data-label="PRECIO" v-else>${{ item.precio }}</td>
                                    <div id="trash-settings-stock-mobile-div">
                                        <img id="settings-stock-mobile" src="@/assets/images/settings.png" alt="settings-icon" @click="editarProducto" title="Editar" data-info="stock-tabla" :data-id="item.id">
                                        <img id="trash-stock-mobile" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarStock" data-info="stock-tabla" :data-id="item.id">
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
      
        <transition name="bounce">
            <SettingsProductoModal 
                v-show="showModalStock" 
                @stock="getStock"
                @close-modal="showModalStock = false" 
                :trigger="id"
                :unidades="unidades"
                :categorias="categorias"
            />
        </transition>
      
    </div>

     

</template>

<script>
import axios from 'axios'
import SettingsProductoModal from '@/components/SettingsProductoComp.vue'

export default {
    name: 'gestionStockView',
    data() {
        return {
            user: localStorage.getItem("username"),
            showModalStock:false,
            id:'',
            nombre:'',
            cantidad:'',
            unidad:'',
            unidadShort:'',
            precio:'',
            inicial:'',
            retiro:'',
            porcentaje:'',
            pesos:'',
            errorNombre: '',
            errorCantidad: '',
            errorUnidad:'',
            errorCategoria:'',
            errorPrecio:'',
            unidades:[
                {
                    'unidad':'Gramo(s)',
                    'short': 'GR'
                },
                {
                    'unidad':'Mililitro(s)',
                    'short': 'ML'
                },
                {
                    'unidad':'Unidad(es)',
                    'short': 'UN'
                }
            ],
            categoria:'',
            categorias:[],
            nombreCategoria:'',
            errorNombreCategoria:'',
            categoriaTienda: false,
            stock:[],
            errorProducto:'',
            productoBuscar:'',
            verTodo:false,
            cantidadMenor:false,
            errorActualizarPrecio:''
        }
    },
    components: {
        SettingsProductoModal
    },
    mounted() {
        document.title = 'Stock | Tijeras Gestion';
        this.getStockCategorias();
        this.getStock();
        this.setBgBody();
    },
    methods: {
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-gestion)");
            document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
        },
        editarProducto(e) {
            this.id = e.target.dataset.id
            this.showModalStock = true
        },
        async actualizarPrecio(e) {
            if (!this.porcentaje && !this.pesos) {
                this.errorActualizarPrecio = 'Seleccione uno'
                setTimeout(() => this.errorActualizarPrecio = '', 2000)
            }
            if (this.porcentaje && this.pesos) {
                this.errorActualizarPrecio = 'Uno solamente'
                setTimeout(() => this.errorActualizarPrecio = '', 2000)
            }
            if (this.porcentaje == '0' || this.pesos == '0') {
                this.errorActualizarPrecio= 'Minimo 1'
                setTimeout(() => this.errorActualizarPrecio = '', 2000)
            }

            e.preventDefault();

            if (!this.errorActualizarPrecio) {
                const data = {
                    operacion:e.target.dataset.info,
                    porcentaje: this.porcentaje,
                    pesos:this.pesos
                }

                await axios
                    .post('/api/v1/stock/actualizar-precio/', data)
                    .then(response => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'PRECIOS ACTUALIZADOS',
                            showConfirmButton: false,
                            timer: 2000
                        })
                        this.porcentaje = ''
                        this.pesos = ''
                        this.getStock()
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'PRECIOS NO ACTUALIZADOS',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
            }
        },
        async getStockCategorias() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/stock/categorias/')
                .then(response => {
                    this.categorias = response.data
                })
                .catch(error => {
                    if (error.response.status === 401){
                        localStorage.removeItem("token")
                        localStorage.removeItem("username")
                        localStorage.removeItem("userid")
                        this.$store.commit('removeToken')
                        this.$router.push('/gestion')
                    }
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getStock() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/stock/')
                .then(response => {
                    this.stock = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async ingresarProducto(e) {
            if (!this.nombre) {
                this.errorNombre = 'Requerido'
                setTimeout(() => this.errorNombre = '', 2000)
            }
            if (!this.cantidad) {
                this.errorCantidad= 'Requerida'
                setTimeout(() => this.errorCantidad = '', 2000)
            }
            if (!this.unidad) {
                this.errorUnidad = 'Requerida'
                setTimeout(() => this.errorUnidad = '', 2000)
            }
            if (!this.categoria) {
                this.errorCategoria= 'Requerida'
                setTimeout(() => this.errorCategoria = '', 2000)
            }
            if (this.categoria == 'TIENDA') {
                if (!this.precio) {
                    this.errorPrecio= 'Requerido'
                    setTimeout(() => this.errorPrecio = '', 2000)
                } 
            }
            
            e.preventDefault();

            if (!this.errorNombre && !this.errorCantidad && 
                !this.errorUnidad && !this.errorCategoria && 
                !this.errorPrecio) {

                const data = {
                    nombre:this.nombre,
                    cantidad:this.cantidad,
                    unidad: this.unidadShort,
                    categoria:this.categoria,
                    precio:this.precio
                }

                await axios
                    .post('/api/v1/stock/ingresar/', data)
                    .then(response => {
                        if (response.data == 'creado') {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'PRODUCTO AÑADIDO',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }else if (response.data == 'actualizado') {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'PRODUCTO ACTUALIZADO',
                                showConfirmButton: false,
                                timer: 2000
                            }) 
                        }
                        this.getStock()
                        this.nombre = '';
                        this.cantidad = '';
                        this.unidad = '';
                        this.categoria = '';
                        this.precio = '';
                        this.categoriaTienda = false
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'EL PRODUCTO NO SE PUDO AÑADIR',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
            }
        },
        async eliminarStock(e) {
            if (e.target.dataset.id == 'none') {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'STOCK EGRESOS NO PUEDEN SER ELIMINADOS',
                    showConfirmButton: false,
                    timer: 2000
                }) 
            }else{
                Swal.fire({
                    title: 'ELIMINAR STOCK?',
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#279f04',
                    cancelButtonColor: '#d33',
                    confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                    cancelButtonText: 'CANCELAR'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const data = {
                            id:e.target.dataset.id
                        }
                        axios
                            .post("/api/v1/stock/eliminar/", data)
                            .then(response => {
                                this.getStock()
                                this.verTodo = false
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'STOCK ELIMINADO',
                                    showConfirmButton: false,
                                    timer: 2000
                                })
                            })
                            .catch(error => {
                                console.log(JSON.stringify(error))
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'error',
                                    title: 'EL STOCK NO PUDO SER ELIMINADO',
                                    showConfirmButton: false,
                                    timer: 2000
                                })
                            })
                    }
                })
            }
        },
        async buscarStock(e) {        
            if (!this.productoBuscar) {
                this.errorProducto= 'Producto requerido'
                setTimeout(() => this.errorProducto = '', 2000)
            } 
            e.preventDefault();

            if (!this.errorProducto) {
                this.verTodo = true
                const data = {
                    producto:this.productoBuscar
                }

                await axios
                    .post('/api/v1/stock/buscar/', data)
                    .then(response => {
                        this.stock = response.data
                        this.verTodo = true
                        this.productoBuscar = ''
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'EL PRODUCTO NO EXISTE',
                            showConfirmButton: false,
                            timer: 2000
                        }) 
                    })
            }
            
        },
        async todoStock() {
            this.getStock()
            this.verTodo = false
            this.productoBuscar = ''
        },
        async ingresarCategoria(e) {
            if (!this.nombreCategoria) {
                this.errorNombreCategoria = 'Requerida'
                setTimeout(() => this.errorNombreCategoria = '', 2000)
            }
            
            e.preventDefault();

            if (!this.errorNombreCategoria) {
                const data = {
                    nombre:this.nombreCategoria
                }
                await axios
                    .post('/api/v1/stock/categorias/ingresar', data)
                    .then(response => {
                        if (response.data == 'creada') {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'CATEGORIA AÑADIDA',
                                showConfirmButton: false,
                                timer: 2000
                            })
                            this.getStockCategorias();
                            this.nombreCategoria = ''
                        }else if (response.data == 'existe') {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'LA CATEGORIA EXISTE',
                                showConfirmButton: false,
                                timer: 2000
                            }) 
                            this.nombreCategoria = ''
                        }
                        
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'LA CATEGORIA NO PUDO SER AÑADIDA',
                            showConfirmButton: false,
                            timer: 2000
                        }) 
                        this.nombreCategoria = ''
                    })
            }
        },
        async eliminarCategoria(e) {
            Swal.fire({
                title: 'ELIMINAR CATEGORIA?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#279f04',
                cancelButtonColor: '#d33',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                cancelButtonText: 'CANCELAR'
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = {
                        id:e.target.dataset.id
                    }
                    axios
                        .post("/api/v1/stock/categorias/eliminar", data)
                        .then(response => {
                            this.getStockCategorias()
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'CATEGORIA ELIMINADA',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'LA CATEGORIA NO PUDO SER ELIMINADA',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                }
            })
            
        },
        handleChange(e) {
            if(e.target.options.selectedIndex > -1) {
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "categoria"){
                    if (e.target.value == 'TIENDA') {
                        this.categoriaTienda = true
                    }else {
                        this.categoriaTienda = false
                    }   
                }
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "unidad"){
                    this.unidadShort = e.target.options[e.target.options.selectedIndex].dataset.short 
                }
            }
        },
    }
}

</script>

<style>
    #gestion-stock-view {
        padding-top: 180px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: baseline;
    }

    #crear-categoria-tabla-stock-div {
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0px 33px 22px 0px #000000;
        color: white;
        background: var(--bg-box-black);
        background-position: center center;
        background-size: cover;
        display: inline-table;
        margin: 0 20px 50px 20px
    }

    #crear-categoria-tabla-stock-div h1{
        text-shadow: var(--text-shadow);
    }

    #crear-categoria-stock-div {
        margin-top: 10px;
    }

    #ingresar-stock, #buscar-stock, 
    #cambiar-precio-stock {
        min-width: 260px;
        max-width: 300px;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0px 33px 22px 0px #000000;
        max-height: 500px;
        color: white;
        background: var(--bg-box-black);
        background-position: center center;
        background-size: cover;
    }

    #ingresar-stock h1, #cambiar-precio-stock h1 {
        text-shadow: var(--text-shadow);
    }

    #buscar-stock h1 {
        text-shadow: var(--text-shadow);
    }

    #producto-ingreso, #cantidad-ingreso, #precio-ingreso,
    #producto-buscar, #porcentaje-stock, #pesos-stock,
    #categoria-stock-nombre  {
        margin-bottom: 13px;
    }
    
    #producto-stock-div, #cantidad-stock-div {
        display: inline-table;
        margin: 10px 10px 0 10px;
        width: 130px;
    }

    #unidad-stock-div, #categoria-stock-div {
        display: inline-table;
        margin: 20px 10px 0 10px;
        width: 130px;
    }

    #precio-stock-div {
        margin: 20px auto 0 auto;
        width: 130px;
    }

    #unidad-stock,
    #categoria-stock {
        border-bottom: 2px solid var(--fieldBorderColor);
    }

    #cambiar-precio-stock, #buscar-stock {
        display: inline-table;
    }

    #ingresar-stock, #buscar-stock, #cambiar-precio-stock {
        margin: 0 20px 50px 20px;
    }

    #producto-buscar-stock-div {
        width: 130px;
        margin: 10px auto 0 auto;
    }

    #porcentaje-stock-div, #pesos-stock-div {
        display: inline-table;
        margin: 10px 10px 0 10px;
        width: 130px;
    }

    #boton-precio-aumentar, 
    #boton-precio-reducir, 
    #boton-buscar-stock,
    #boton-ver-todo-stock,
    #boton-agregar-stock,
    #boton-crear-categoria-stock {
        background-color: var(--button-color);
        border: none;
        color: rgb(255, 255, 255);
        padding: 0 8px;
        width: 80%;
        margin: 10px auto 0 auto;
    }

    #crear-categoria-stock-tabla {
        margin-top: 10px;
    }

    #tabla-crear-categoria-stock {
        max-height: 373px;
        width: 300px;
        color: white;
        overflow: auto;
        margin-left: auto;
        margin-right: auto;
    }

    #table-wrapper-stock {
        position:relative;
        width: 100%;
    }

    #tabla-stock {
        max-height:400px;
        width: 800px;
        border-radius: 4px;
        box-shadow: 0px 33px 22px 0px #000000;
        color: white;
        overflow:auto;  
        background: 
            var(--image);
        background-position: center center;
        background-size: cover;
        --image: url("@/assets/images/pizarron-grande.png");
        margin: 0 20px 0 20px;
    }

    #tabla-stock table,
    #tabla-crear-categoria-stock table {
        width: 100%;
    }

    #tabla-stock thead tr th,
    #tabla-crear-categoria-stock thead tr th {
        position: sticky;
        top: 0;
        background-color: #000000;
        border: none;
        font-weight: 900;
        font-size: 20px;
        text-align: center;
    }

    #tabla-stock table tr td,
    #tabla-crear-categoria-stock table tr td {
        font-size: 20px;
        letter-spacing: .1em;
        text-transform: uppercase;
        text-align: center;
    }

    #tabla-stock::-webkit-scrollbar-thumb,
    #tabla-crear-categoria-stock::-webkit-scrollbar-thumb  {
        background-color: #555;
    }

    #tabla-stock::-webkit-scrollbar,
    #tabla-crear-categoria-stock::-webkit-scrollbar {
        width: 5px;
        background-color: #000000;
    }

    #tabla-stock #trash-mobile-icon {
        display: none;
    }

    #boton-guardar-stock {
        margin-top: 10px;
        padding-bottom: 10px;
    }

    #boton-buscar-stock {
        padding-bottom: 10px;
    }

    #cantidad-negativa {
        color: red;
    }

    #cantidad-menor {
        color: darkorange;
    }

    #cantidad-normal {
        color: green;
    }

    #trash-stock,
    #trash-categoria-stock {
        width: 20px;
        cursor: pointer;
        filter: invert(100%);
    }

    #settings-stock {
        width: 23px;
        cursor: pointer;
        filter: invert(100%);
        margin-right: 10px;
    }

    #trash-settings-stock-mobile-div {
        display: none;
    }


    
    @media screen and (max-width: 900px) {
        #gestion-stock-view {
            padding-top: 130px;
        }

        #stock-tabla {
            width: 100%;
  
        }

        #stock-tabla table tr td {
            font-size: 18px;
            text-align: right;
            padding: 10px;
        }

        #stock-tabla thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        #stock-tabla tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        #stock-tabla table td {
            border-bottom: none;
            display: block;
            font-size: .8em;
            text-align: right;
        }

        #stock-tabla td::before {
            /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
            color: white;
            text-shadow: var(--text-shadow);
        }

        #stock-tabla td:last-child {
            border-bottom: 0;
        }

        #stock-tabla td:last-child {
            padding-right: 15px;
        }

        #stock-tabla td:first-child {
            padding-left: 15px;
            display: none;
        }

        #tabla-stock::-webkit-scrollbar-thumb,
        #tabla-stock::-webkit-scrollbar {
            display: none;
        }

        #tabla-stock {
            margin: 0 auto 0 auto;
            width: 90%;
        }

        #crear-categoria-tabla-stock-div,
        #ingresar-stock {
            display: inline-table;
        }

        #trash-settings-stock-div {
            display: none;
        }

        #trash-stock-mobile {
            width: 24px;
            cursor: pointer;
            filter: invert(100%);
            margin: 0 5px 0 5px;
        }

        #settings-stock-mobile {
            width: 28px;
            cursor: pointer;
            filter: invert(100%);
            margin: 0 5px 0 5px;
        }

        #trash-settings-stock-mobile-div {
            display: block;
        }
      
    }

    @media screen and (max-width: 400px) {
        #gestion-stock-view {
            padding-top: 130px;
        }

        #crear-categoria-tabla-stock-div, #ingresar-stock, #buscar-stock, #cambiar-precio-stock {
            padding: 10px;
            margin: 0 0 50px 0;
            width: 85%;
        }

        #tabla-crear-categoria-stock {
            width: 90%;
        }
    }


</style>