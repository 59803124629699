<template>
    <div id="grafico-caja-dia">
        <h1>DIA</h1>
        <div id="grafico-year-dia-div">
            <select id="year-dia" class="select-style" @change="handleChange" v-model="yearSelect">
                <option value="" disabled hidden>Año</option>
                <option v-for="year in years" :key="year" data-info="year">{{ year }}</option>
            </select> 
        </div>
      
        <div id="grafico-mes-dia-div">
            <select id="mes-dia" class="select-style" @change="handleChange" v-model="mesSelect">
                <option value="" disabled hidden>Mes</option>
                <option v-for="mes in meses" :key="mes" data-info="mes">{{ mes }}</option>
            </select> 
        </div>

        <div id="grafico-dia-div">
            <select id="dia" class="select-style" @change="handleChange" v-model="diaSelect">
                <option value="" disabled hidden>Dia</option>
                <option v-for="dia in dias" :key="dia" data-info="dia">{{ dia }}</option>
            </select> 
        </div>
    </div>

    <transition name="fade">
        <div id="chart-graficos-dia" v-if="loaded">
            <button @click="minMax" id="boton-cerrar-dia">CERRAR</button>

            <div v-if="show">
                <div id="grafico-totales-dias">
                    <Bar id="grafico-dias-totales" 
                    :options="graficoOpcionesDiasTotal" 
                    :data="graficoDiasTotal" />
                </div>

                <div id="grafico-total-dia-efectivo-tarjeta">
                    <Bar id="grafico-total-efectivo-tarjeta-dia" 
                    :options="graficoOpcionesTotalEfectivoTarjetaDia"
                    :data="graficoTotalEfectivoTarjetaDia" />
                </div>

                <div id="grafico-dia-total">
                    <Bar id="grafico-total-dia" 
                    :options="graficoOpcionesTotalDia"
                    :data="graficoTotalDia" />
                </div>

                <div id="grafico-medios-pago-dia-pie">
                    <Pie id="grafico-pie-medios-pago-dia" 
                    :options="graficoOpcionesMedioPagoPie" 
                    :data="graficoMedioPagoPie" />
                </div>

                <div id="grafico-trabajos-dia">
                    <Bar id="grafico-dia-trabajos" 
                    :options="graficoOpcionesTrabajos" 
                    :data="graficoTrabajos" />
                </div>

                <div id="grafico-productos-dia">
                    <Bar id="grafico-dia-productos" 
                    :options="graficoOpcionesProductos" 
                    :data="graficoProductos" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

import axios from 'axios'
import { Bar, Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, ArcElement, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale, ChartDataLabels)
ChartJS.defaults.font.size = 15;
ChartJS.defaults.color = 'white'
ChartJS.defaults.borderColor = '#575656';

export default {
    name: 'DiaGraficos',
    data() {
        return {
            show: false,
            buttonText: 'Minimizar',
            loaded: false,
            yearSelect: '',
            mesSelect: '',
            diaSelect:'',
            dias:[],
            years: [],
            meses: ['Ene', 'Feb', 'Mar', 'Abr', 'May',
                'Jun', 'Jul', 'Ago', 'Sep',
                'Oct', 'Nov', 'Dic'],
            graficoDiasTotal: {
                labels: [],
                datasets: [
                    {
                        label: 'Servicios Efectivo $',
                        backgroundColor: '#58508d',
                        data: [],
                        datalabels: {
                            display: false,

                        }
                    },
                    {
                        label: 'Servicios Tarjeta $',
                        backgroundColor: '#bc5090',
                        data: [],
                        datalabels: {
                            display: false,
                        }
                    },
                    {
                        label: 'Productos Efectivo $',
                        backgroundColor: '#ff6361',
                        data: [],
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Productos Tarjeta $',
                        backgroundColor: '#ffa600',
                        data: [],
                        datalabels: {
                            display: false
                        }
                    },
                ]
            },
            graficoOpcionesDiasTotal: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return '$' + value;
                            }
                        }
                    }
                }
            },
            graficoTotalEfectivoTarjetaDia: {
                labels: '',
                datasets: [
                    {
                        label: 'Total efectivo $',
                        backgroundColor: "#96c0ff",
                        data: [],
                    },
                    {
                        label: 'Total tarjeta $',
                        backgroundColor: "#00b3ff",
                        data: [],
                    }
                ]
            },
            graficoOpcionesTotalEfectivoTarjetaDia: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    },
                    datalabels: {
                        formatter: function (value, context) {
                            return '$' + value;
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            callback: function (value, index, ticks) {
                                return '$' + value;
                            }
                        }
                    }
                }
            },
            graficoTotalDia: {
                labels: '',
                datasets: [
                    {
                        label: 'Total $',
                        backgroundColor: "#2f5b6b",
                        data: [],
                    },
                    {
                        label: 'Retiros $',
                        backgroundColor: "#ff3838",
                        data: [],
                    }
                ]
            },
            graficoOpcionesTotalDia: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    },
                    datalabels: {
                        formatter: function (value, context) {
                            return '$' + value;
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            callback: function (value, index, ticks) {
                                return '$' + value;
                            }
                        }
                    }
                }
            },
            graficoMedioPagoPie: {
                labels: ['Efectivo', 'Tarjeta'],
                datasets: [
                    {
                        label: 'Cant.',
                        backgroundColor: ['#0b5394', '#6fa8dc'],
                        data: []
                    },
                ]
            },
            graficoOpcionesMedioPagoPie: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    }
                }
            },
            graficoTrabajos: {
                labels: [],
                datasets: [
                    {
                        label: 'Cantidad',
                        backgroundColor: "#ff7abb",
                        data: []
                    }
                ]
            },
            graficoOpcionesTrabajos: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                            stepSize: 1,
                            autoSkip: true,
                        }
                    }
                }
            },
            graficoProductos: {
                labels: [],
                datasets: [
                    {
                        label: 'Cantidad',
                        backgroundColor: '#d793f3',
                        data: []
                    }
                ]
            },
            graficoOpcionesProductos: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                            stepSize: 1,
                            autoSkip: true,
                        }
                    }
                }
            },
        }
    },
    components: { Bar, Pie },
    mounted() {
        this.getYears();
        this.getDias();
        this.checkMobile();
    },
    methods: {
        minMax() {
            this.loaded = false
            this.yearSelect = ''
            this.mesSelect = ''
        },
        checkMobile() {
            let hasTouchScreen = false;
            if ("maxTouchPoints" in navigator) {
                hasTouchScreen = navigator.maxTouchPoints > 0;
            } else if ("msMaxTouchPoints" in navigator) {
                hasTouchScreen = navigator.msMaxTouchPoints > 0;
            } else {
                const mQ = matchMedia?.("(pointer:coarse)");
                if (mQ?.media === "(pointer:coarse)") {
                    hasTouchScreen = !!mQ.matches;
                } else if ("orientation" in window) {
                    hasTouchScreen = true; // deprecated, but good fallback
                } else {
                    // Only as a last resort, fall back to user agent sniffing
                    const UA = navigator.userAgent;
                    hasTouchScreen =
                        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
                }
            }

            if (hasTouchScreen) {

                this.mobile = true
                const scales = {
                    y: {
                        ticks: {
                            maxRotation: 90,
                            minRotation: 90,
                            color: '#FFC512',
                            font: {
                                weight: 'bold'
                            }
                        }
                    },
                    x: {
                        ticks: {
                            callback: function (value, index, ticks) {
                                return '$' + value;
                            }
                        }
                    }
                }
                const scalesProSer = {
                    y: {
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false
                        },
                    },
                    x: {
                        ticks: {
                            stepSize: 1,
                            autoSkip: true,
                        },
                    }
                }
                const p = {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    },
                    datalabels: {
                        formatter: function (value, context) {
                            let w = ''
                            let words = context.chart.data.labels[context.dataIndex].trim().split(' ');
                            if (words.length > 1) {
                                for (let x = 0; x < words.length; x++) {
                                    if (x > 0) {
                                        w += words[x].substring(0, 3) + '. '
                                    } else {
                                        w += words[x] + ' '
                                    }
                                }
                            } else {
                                w = words[0]
                            }

                            return w;
                        }
                    }
                }
                this.graficoOpcionesDiasTotal.indexAxis = 'y'
                this.graficoOpcionesDiasTotal.scales = scales
                this.graficoOpcionesTotalEfectivoTarjetaDia.indexAxis = 'y'
                this.graficoOpcionesTotalEfectivoTarjetaDia.scales = scales
                this.graficoOpcionesTotalDia.indexAxis = 'y'
                this.graficoOpcionesTotalDia.scales = scales
                this.graficoOpcionesProductos.indexAxis = 'y'
                this.graficoOpcionesProductos.scales = scalesProSer
                this.graficoOpcionesProductos.plugins = p
                this.graficoOpcionesTrabajos.indexAxis = 'y'
                this.graficoOpcionesTrabajos.scales = scalesProSer
                this.graficoOpcionesTrabajos.plugins = p
                
            }

        },
        handleChange(e) {
            if (e.target.options.selectedIndex > -1) {
                if (e.target.options[e.target.options.selectedIndex].dataset.info === "dia") {
                    this.getDiaGraficos()
                }
            }
        },
        async getDias() {
            const mesNumero = this.meses.indexOf(this.mesSelect)
            const getDays = new Date(this.yearSelect, mesNumero, 0).getDate()
            let dias = []
            for (let i = 1; i < getDays + 1; i++) {
                dias.push(i)
            }
            this.dias = dias
        },
        async getYears() {
            await axios
                .get("/api/v1/years/")
                .then(response => {
                    this.years = response.data['years']
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        async getDiaGraficos() {
            this.$store.commit('setIsLoading', true)
            this.loaded = false
            this.graficoTotalEfectivoTarjetaDia.datasets[0].data = []
            this.graficoDiasTotal.datasets[0].data = []
            this.graficoDiasTotal.datasets[1].data = []
            this.graficoDiasTotal.datasets[2].data = []
            this.graficoDiasTotal.datasets[3].data = []
            
            const data = {
                year:this.yearSelect,
                mes:this.mesSelect,
                dia:this.diaSelect
            }
            const mesNumero = this.meses.indexOf(this.mesSelect)
            const getDays = new Date(this.yearSelect, mesNumero + 1, 0).getDate()
            let dias = []

            for (let i = 1; i < getDays; i++) {
                dias.push(`${i}/${mesNumero + 1}/${this.yearSelect}`)
                this.graficoDiasTotal.datasets[0].data.push(0)
                this.graficoDiasTotal.datasets[1].data.push(0)
                this.graficoDiasTotal.datasets[2].data.push(0)
                this.graficoDiasTotal.datasets[3].data.push(0)
            }

            await axios
                .post("/api/v1/grafico-caja-dia/", data)
                .then(response => {
                    this.graficoDiasTotal.labels = dias
                    this.graficoOpcionesDiasTotal.plugins.title.text = 'Totales por dia ' + this.mesSelect + ' ' + this.yearSelect
                    
                    for (let i = 0; i < response.data[0]['fecha_total_trabajos_list'].length; i++) {
                        this.graficoDiasTotal.datasets[0].data.splice(parseFloat(response.data[0]['fecha_total_trabajos_list'][i][0].split('/')[0]) - 1, 1, response.data[0]['fecha_total_trabajos_list'][i][1]['efectivo']);
                        this.graficoDiasTotal.datasets[1].data.splice(parseFloat(response.data[0]['fecha_total_trabajos_list'][i][0].split('/')[0]) - 1, 1, response.data[0]['fecha_total_trabajos_list'][i][1]['tarjeta']);
                    }
             
                    for (let i = 0; i < response.data[0]['fecha_total_tienda_list'].length; i++) {
                        this.graficoDiasTotal.datasets[2].data.splice(parseFloat(response.data[0]['fecha_total_tienda_list'][i][0].split('/')[0]) - 1, 1, response.data[0]['fecha_total_tienda_list'][i][1]['efectivo']);
                        this.graficoDiasTotal.datasets[3].data.splice(parseFloat(response.data[0]['fecha_total_tienda_list'][i][0].split('/')[0]) - 1, 1, response.data[0]['fecha_total_tienda_list'][i][1]['tarjeta']);
                    }
                    
                    this.graficoOpcionesTotalEfectivoTarjetaDia.plugins.title.text = 'Efectivo / Tarjeta'
                    this.graficoTotalEfectivoTarjetaDia.labels = [this.diaSelect + ' de ' + this.mesSelect + ' ' + this.yearSelect]
                    this.graficoTotalEfectivoTarjetaDia.datasets[0].data = [response.data[0]['efectivo_total_dia']]
                    this.graficoTotalEfectivoTarjetaDia.datasets[1].data = [response.data[0]['tarjeta_total_dia']]
                    
                    this.graficoOpcionesTotalDia.plugins.title.text = 'Efectivo + Tarjeta / Retiros'
                    this.graficoTotalDia.labels = [this.diaSelect + ' de ' + this.mesSelect + ' ' + this.yearSelect]
                    this.graficoTotalDia.datasets[0].data = [response.data[0]['efectivo_total_dia'] + response.data[0]['tarjeta_total_dia']]
                    this.graficoTotalDia.datasets[1].data = [response.data[0]['retiros']]
                    
                    this.graficoOpcionesMedioPagoPie.plugins.title.text = 'Modalidad de pago ' + this.diaSelect + ' de ' + this.mesSelect + ' ' + this.yearSelect
                    this.graficoMedioPagoPie.datasets[0].data = [response.data[0]['efectivo_cantidad'], response.data[0]['tarjeta_cantidad']]
                    
                    this.graficoTrabajos.labels = response.data[0]['servicios']
                    this.graficoTrabajos.datasets[0].data = response.data[0]['servicios_cantidad']
                    this.graficoProductos.labels = response.data[0]['productos']
                    this.graficoProductos.datasets[0].data = response.data[0]['productos_cantidad']
                    this.graficoOpcionesTrabajos.plugins.title.text = 'Servicios ' + this.diaSelect + ' de ' + this.mesSelect + ' ' + this.yearSelect
                    this.graficoOpcionesProductos.plugins.title.text = 'Productos ' + this.diaSelect + ' de ' + this.mesSelect + ' ' + this.yearSelect
                    this.loaded = true
                    this.show = true
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)
        },
    }
}
</script>

<style>
#grafico-caja-dia {
    max-width: 380px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
    margin: 0 auto 50px auto;
}

#grafico-caja-dia h1 {
    text-shadow: var(--text-shadow);
}

#grafico-year-dia-div, #grafico-mes-dia-div, #grafico-dia-div {
    display: inline-table;
    margin: 10px;
    border-bottom: 2px solid var(--fieldBorderColor);
    width: 100px
}

#boton-cerrar-dia {
    width: 120px;
    background-color: var(--button-close);
    border: none;
    color: rgb(255, 255, 255);
    padding: 0 8px;
}

#grafico-totales-dias, #grafico-total-dia-efectivo-tarjeta, 
#grafico-dia-total, #grafico-medios-pago-dia-pie, 
#grafico-trabajos-dia, #grafico-productos-dia {
    margin: 50px 10px 0 10px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black-big);
    background-position: center center;
    background-size: cover;
}

#grafico-dias-totales,
#grafico-total-efectivo-tarjeta-dia,
#grafico-total-dia,
#grafico-dia-productos,
#grafico-dia-trabajos {
    height: 500px;
}

#grafico-total-dia-efectivo-tarjeta,
#grafico-dia-total,
#grafico-medios-pago-dia-pie {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 500px;
}

#year-mes-dia, #mes-dia, #dia {
    display: inline-table;
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 700px) {
    #grafico-dias-totales {
        height: 700px;
    }

    #grafico-total-efectivo-tarjeta-dia
    #grafico-total-dia {
        height: 400px;
    }

    #grafico-total-dia-efectivo-tarjeta,
    #grafico-dia-total,
    #grafico-medios-pago-dia-pie{
        display: inherit;
        margin-left: inherit;
        margin-right: inherit;
    }

    #grafico-pie-medios-pago-dia {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>