<template>
  <nav class="nav" v-if="this.$store.state.isAuthenticated">
    <div id="container">
      <div class="logo">
          <router-link to="/gestion/inicio"><img id="logo" src="@/assets/images/tijeras-logo2.png" alt="Tijeras Logo" /></router-link>
      </div>
    
      <div id="main-list" class="main_list gestion-list">
        <ul class="navlinks" id="navlinks-regular">
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/inicio"><img id="icon-navbar-width" src="@/assets/images/inicio.png" alt="" title="Inicio"><p id="title-icon-navbar">INICIO</p></router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/buscar"><img id="icon-navbar-width" src="@/assets/images/buscar.png" alt="" title="Buscar"><p id="title-icon-navbar">BUSCAR</p></router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/turnos"><img id="icon-navbar-width" src="@/assets/images/turnos.png" alt="" title="Turnos"><p id="title-icon-navbar">TURNOS</p></router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/caja"><img id="icon-navbar-width" src="@/assets/images/caja.png" alt="" title="Caja"><p id="title-icon-navbar">CAJA</p></router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/stock"><img id="icon-navbar-width" src="@/assets/images/stock.png" alt="" title="Caja"><p id="title-icon-navbar">STOCK</p></router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/empleados"><img id="icon-navbar-width" src="@/assets/images/empleados.png" alt="" title="Empleados"><p id="title-icon-navbar">EMPLEADOS</p></router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/servicios"><img id="icon-navbar-width" src="@/assets/images/servicios.png" alt="" title="Servicios"><p id="title-icon-navbar">SERVICIOS</p></router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/estadisticas"><img id="icon-navbar-width" src="@/assets/images/estadisticas.png" alt="" title="Estadisticas"><p id="title-icon-navbar">GRAFICOS</p></router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/eliminado"><img id="icon-navbar-width" src="@/assets/images/eliminado.png" alt="" title="Eliminado"><p id="title-icon-navbar">ELIMINADO</p></router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/ajustes-tienda"><img id="icon-navbar-width" src="@/assets/images/ajuste.png" alt="" title="Ajustes"><p id="title-icon-navbar">AJUSTES</p></router-link></li>
          <li @click="logout()" ><a href="#" id="desconectarse"><img id="icon-navbar-width" src="@/assets/images/salir.png" alt="" title="Salir"><p id="title-icon-navbar">SALIR</p></a></li>
        </ul>
        <ul class="navlinks" id="navlinks-mobile">
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/inicio">INICIO</router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/buscar">BUSCAR</router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/turnos">TURNOS</router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/caja">CAJA</router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/stock">STOCK</router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/empleados">EMPLEADOS</router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/servicios">SERVICIOS</router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/estadisticas">GRAFICOS</router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/eliminado">ELIMINADO</router-link></li>
          <li @click="dropdown()"><router-link id="nav-item" to="/gestion/ajustes-tienda">AJUSTES</router-link></li>
          <li @click="logout()" ><a href="#" id="desconectarse">SALIR</a></li>
        </ul>
      </div>
      <span id="navTrigger" @click="dropdown()" :class="{ active: isActive }">
        <i></i>
        <i></i>
        <i></i>
      </span>
    </div>
  </nav>
  <nav class="nav-cliente" id="nav-cliente" v-else>
    <div id="container">
      <div class="logo-cliente" >
          <router-link to="/"><img id="logo-cliente" src="@/assets/images/logo.png" alt="Tijeras Logo" /></router-link>
      </div>
      <div id="main-list" class="main_list cliente-list" >
        <ul class="navlinks">
          <li @click="dropdown"><router-link id="nav-item" to="/">PORTADA</router-link></li>
          <li @click="dropdown"><router-link id="nav-item" to="/inicio">INICIO</router-link></li>
          <li @click="dropdown"><router-link id="nav-item" to="/servicios">SERVICIOS</router-link></li>
          <li id="contacto-cliente-nav" @click="contacto"><a id="nav-item">CONTACTO</a></li>
          <li @click="dropdown"><router-link id="nav-item" to="/gestion">GESTION</router-link></li>
          <li id="li-turno-nav" @click="dropdown()"><router-link id="nav-item" class="turno-nav" to="/turnos">RESERVAR TURNO</router-link></li>
        </ul>
      </div>
      <span id="navTrigger" @click="dropdown()" :class="{ active: isActive }">
        <i></i>
        <i></i>
        <i></i>
      </span>
    </div>
  </nav>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      isActive: false,
      mobile:true
    }
  },
  components: {

  },
  mounted() {
  },
  methods: {
    dropdown() {
      this.isActive = !this.isActive
      $("#main-list").toggleClass("show_list");
      $("#main-list").fadeIn();
    },
    contacto() {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
    },
    async logout() {
      localStorage.removeItem("token")
      localStorage.removeItem("username")
      localStorage.removeItem("userid")
      this.$store.commit('removeToken')
      this.$router.push('/gestion')
      await axios
        .post("/api/v1/logout/")
        .then(response => {
          axios.defaults.headers.common["Authorization"] = ""
        })
        .catch(error => {   
          console.log(JSON.stringify(error))   
        })
    },
  }
}

</script>

<style>
#container {
  text-align: center;
}

#li-turno-nav a {
	text-decoration: none;
	color: #ffffff;
  position: relative;
  padding: 0 5px 0 5px;
}

#li-turno-nav a::before {
  content: '';
  background-color: var(--button-color-cliente);
  position: absolute;
  left: 0;
  bottom: 3px;
  width: 100%;
  height: 2px;
  z-index: -1;
  transition: all .3s ease-in-out;
}

#li-turno-nav a:hover::before {
  bottom: 0;
  height: 100%;
}

#navlinks-mobile{
  display: none;
}

#logo, .logo {
  width: 190px;
  margin: -5px 0 0 10px;
}

#logo-cliente, .logo-cliente {
  width: 150px;
  margin: -5px auto 0 auto;
}

#icon-navbar-width {
  max-width: 50px;
  margin-left: auto;
  margin-right: auto;
}

#title-icon-navbar{
  color: #ffffff;
  text-shadow: 0 0 2px #fff, 0 0 3px var(--text-shadow-navbar), 0 0 4px var(--text-shadow-navbar), 0 0 5px var(--text-shadow-navbar), 0 0 6px var(--text-shadow-navbar), 0 0 7px var(--text-shadow-navbar), 0 0 8px var(--text-shadow-navbar);
}

#title-icon-navbar:hover {
  color: rgb(255, 255, 255);
  text-shadow: 0 0 2px #fff, 0 0 3px var(--text-shadow-navbar-hover), 0 0 4px var(--text-shadow-navbar-hover), 0 0 5px var(--text-shadow-navbar-hover), 0 0 6px var(--text-shadow-navbar-hover), 0 0 7px var(--text-shadow-navbar-hover), 0 0 8px var(--text-shadow-navbar-hover);
}

.nav {
  width: 100%;
  height:87px;
  position: fixed;
  background-color: rgb(14, 14, 14);
  z-index: 999;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.nav-cliente {
  width: 100%;
  height:87px;
  position: fixed;
  background-color: rgb(14, 14, 14);
  z-index: 999;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#contacto-cliente-nav a {
  cursor: pointer;
}

.nav-cliente .cliente-list ul {
  width: 100%;
  display: flex;
  list-style: none;
  justify-content: center;
  margin-top: 105px;
}

.nav-cliente .cliente-list ul li {
  width: auto;
}

.nav-cliente .cliente-list ul li a {
  text-decoration: none;
  font-size: 20px;
  color: rgb(255, 255, 255);
}

.nav div.logo a,
.nav-cliente div.logo a {
  text-decoration: none;
}

.nav div.main_list,
.nav-cliente div.main_list {
  margin-top: -105px;
}

.nav div.main_list ul,
.nav-cliente div.main_list ul {
  width: 100%;
  display: flex;
  list-style: none;
  justify-content: center;
}

.nav div.main_list ul li,
.nav-cliente div.main_list ul li {
  width: auto;
}

.nav div.main_list ul li a {
  text-decoration: none;
  font-size: 20px;
  color: rgb(255, 255, 255);
}

.nav div.main_list ul li a:hover {
  color: #ffffff;
}

.nav-cliente div.main_list ul li a:hover {
  color:#8987b1;
}

#navTrigger {
  display: none;
}

@media screen and (min-width: 1000px) and (max-width: 1450px) {
  .nav div.gestion-list,
  .nav-cliente div.gestion-list {
    margin-right: 20px;
  }

  .nav div.gestion-list ul,
  .nav-cliente div.gestion-list ul {
    width: 100%;
    display: flex;
    list-style: none;
    justify-content: flex-end;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {

  .nav div.gestion-list,
  .nav-cliente div.gestion-list {
    margin: -96px 20px 0 0;
  }
  
  .nav div.gestion-list ul,
  .nav-cliente div.gestion-list ul {
    width: 100%;
    display: flex;
    list-style: none;
    justify-content: right;
  }

  #icon-navbar-width {
    width: 40px;
  }

  #title-icon-navbar {
    font-size: 16px;
  }

}

@media screen and (min-width: 900px) and (max-width: 1100px) {
  .nav div.gestion-list,
  .nav-cliente div.gestion-list {
    margin: -80px 20px 0 0;
  }
  
  .nav div.gestion-list ul,
  .nav-cliente div.gestion-list ul {
    width: 100%;
    display: flex;
    list-style: none;
    justify-content: right;
  }

  #logo {
    width: 150px;
    margin-top: 5px;
  }

  #icon-navbar-width {
    width: 30px;
  }

  #title-icon-navbar {
    font-size: 14px;
  }

  #main-list ul li {
    margin: 0px 0px 5px 0px; 
    padding: 0px 0px 0px 15px;
  }
}


@media screen and (max-width:900px) {
  #logo-cliente, .logo-cliente {
    margin: 0 auto 0 auto;
  }

  #container {
    margin: 0px 0px 0px 0px;
  }

  .nav,
  .nav-cliente {
    height:80px;
  }

  .nav div.logo {
    padding-top: 6px;
  }

  #logo {
    width: 150px;
    margin-top: -1px;
  }

  .navlinks {
    margin-top: 102px;
  }

  #contacto-cliente-nav {
    display: none;
  }

  #icon-navbar-width {
    display: none;
  }

  #navTrigger {
    display: block;
  }

  .nav div.main_list,
  .nav-cliente div.main_list {
    width: 100%;
    height: 0;
    overflow: hidden;
  }

  .nav div.show_list,
  .nav-cliente div.show_list {
    height: auto;
    display: none;
  }

  .nav div.main_list ul,
  .nav-cliente div.main_list ul {
    flex-direction: column;
    width: 100%;
    height: 100vh;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(14, 14, 14);
    /*same background color of navbar*/
    background-position: center top;
    justify-content: unset;
  }

  .nav div.main_list ul li,
  .nav-cliente div.main_list ul li {
    width: 100%;
    text-align: right;
    padding: 0;
    top: 0;
  }

  .nav div.main_list ul li a,
  .nav-cliente div.main_list ul li a {
    text-align: center;
    width: 100%;
    font-size: 40px;
    padding: 20px;
  }

  .nav div.media_button,
  .nav-cliente div.media_button {
    display: block;
  }

  #navlinks-regular {
    display: none;
  }

  #navlinks-mobile {
    display:block;
    overflow: auto;
  }

  #desconectarse {
    padding-bottom: 100px;
  }

  #navlinks-mobile::-webkit-scrollbar,
  #navlinks-mobile::-webkit-scrollbar-thumb {
    display: none;
  }

  #li-turno-nav a {
    border: none;
    padding-left: 15px;
    padding-right: 15px;
    color: var(--button-color-cliente);
  }

  #li-turno-nav a:hover {
    background-color:transparent;
  }

}


/* Animation */
/* Inspiration taken from Dicson https://codemyui.com/simple-hamburger-menu-x-mark-animation/ */

#navTrigger {
  cursor: pointer;
  width: 30px;
  height: 25px;
  margin: auto;
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
}

#navTrigger i {
  background-color: #fff;
  border-radius: 2px;
  content: '';
  display: block;
  width: 100%;
  height: 4px;
}

#navTrigger i:nth-child(1) {
  -webkit-animation: outT 0.8s backwards;
  animation: outT 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

#navTrigger i:nth-child(2) {
  margin: 5px 0;
  -webkit-animation: outM 0.8s backwards;
  animation: outM 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

#navTrigger i:nth-child(3) {
  -webkit-animation: outBtm 0.8s backwards;
  animation: outBtm 0.8s backwards;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

#navTrigger.active i:nth-child(1) {
  -webkit-animation: inT 0.8s forwards;
  animation: inT 0.8s forwards;
}

#navTrigger.active i:nth-child(2) {
  -webkit-animation: inM 0.8s forwards;
  animation: inM 0.8s forwards;
}

#navTrigger.active i:nth-child(3) {
  -webkit-animation: inBtm 0.8s forwards;
  animation: inBtm 0.8s forwards;
}

@-webkit-keyframes inM {
  50% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes inM {
  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes outM {
  50% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(45deg);
  }
}

@keyframes outM {
  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes inT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }

  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }

  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes inT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }

  50% {
    transform: translateY(9px) rotate(0deg);
  }

  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes outT {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }

  50% {
    -webkit-transform: translateY(9px) rotate(0deg);
  }

  100% {
    -webkit-transform: translateY(9px) rotate(135deg);
  }
}

@keyframes outT {
  0% {
    transform: translateY(0px) rotate(0deg);
  }

  50% {
    transform: translateY(9px) rotate(0deg);
  }

  100% {
    transform: translateY(9px) rotate(135deg);
  }
}

@-webkit-keyframes inBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }

  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }

  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes inBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }

  50% {
    transform: translateY(-9px) rotate(0deg);
  }

  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

@-webkit-keyframes outBtm {
  0% {
    -webkit-transform: translateY(0px) rotate(0deg);
  }

  50% {
    -webkit-transform: translateY(-9px) rotate(0deg);
  }

  100% {
    -webkit-transform: translateY(-9px) rotate(135deg);
  }
}

@keyframes outBtm {
  0% {
    transform: translateY(0px) rotate(0deg);
  }

  50% {
    transform: translateY(-9px) rotate(0deg);
  }

  100% {
    transform: translateY(-9px) rotate(135deg);
  }
}

</style>


