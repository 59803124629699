<template>
    <div class="iniciar-sesion-view">
        <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
            <div class="lds-dual-ring"></div>
        </div>

        <div class="iniciar-sesion-div" v-if="entrando">
            <h1 class="title">INICIAR SESION</h1>

            <div>

                <div id="usuario-iniciar-sesion-div">
                    <div class="field field_v2">
                        <label for="usuario-iniciar-sesion" class="ha-screen-reader">Usuario</label>
                        <input v-model="username" type="email"  id="usuario-iniciar-sesion" class="field__input" placeholder="Tu e-mail" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Usuario</span>
                        </span>
                    </div>    
                    <span v-if="errorUsuario"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorUsuario }}</p></span>
                </div>

                <div id="password-iniciar-sesion-div">
                    <div class="field field_v2">
                        <label for="password-iniciar-sesion" class="ha-screen-reader">Contraseña</label>
                        <input v-model="password" type="password" id="password-iniciar-sesion" class="field__input" placeholder="Tu contraseña" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Contraseña</span>
                        </span>
                    </div> 
                    <img id="ojo-icono" @click="mostrar" src="@/assets/images/mostrar.png" alt=""> 
                    <span v-if="errorPassword"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorPassword }}</p></span>
                </div>

                <div id="boton-iniciar-sesion-div">
                    <button @click="getRecaptcha" class="button" id="boton-loguearse" data-token="">ENTRAR</button>
                </div>
           
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'IniciarSesionView',
    data() {
        return {
            username: '',
            password: '',
            errorUsuario:'',
            errorPassword: '',
            entrando:true,
            tk:''
        }
    },
    mounted() {
        document.title = 'Iniciar sesion | Tijeras Gestion'
        this.logged()
        this.setBgBody()
    },
    methods: {
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-cliente)");
        },
        mostrar() {
            const x = document.getElementById("password-iniciar-sesion");
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        },
        logged() {
            if(this.$store.state.isAuthenticated == true) {
                const toPath = this.$route.query.to || '/gestion/inicio'
                this.$router.push(toPath)
            }
        },
        async getRecaptcha(e) {
            this.$store.commit('setIsLoading', true)
            const boton = document.getElementById('boton-loguearse');
            grecaptcha.ready(function() {
                grecaptcha.execute('6Ld9Yw0mAAAAAIygstc29M2XIb8f50jMitjPGzkY')
                .then(response => {
                    // Add your logic to submit to your backend server here.
                    boton.dataset.token = response;
                })
            });

            setTimeout(() => {
            this.submitForm(e)
            }, 2000);
        },
        async submitForm(e) {
            if (!this.username) {
            this.errorUsuario = 'Requerid@';
            setTimeout(() => this.errorUsuario = '', 2000)
            }
            if (!this.password) {
            this.errorPassword = 'Requerida';
            setTimeout(() => this.errorPassword = '', 2000)
            }

            if (!this.errors && !this.errorPassword) {
                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")
                const data = {
                    username: this.username,
                    password: this.password,
                    token:  e.target.dataset.token
                }

                axios
                    .post("/api/v1/login/", data)
                    .then(response => {
                        if (response.data == 'usuario no existe') {
                            this.errorUsuario = ('Usuari@ no existe')
                            setTimeout(() => this.errorUsuario = '', 2000)
                        }else if (response.data == 'password incorrecto') {
                            this.errorPassword = ('Contranseña incorrecta')
                            setTimeout(() => this.errorPassword = '', 2000)
                        }else {
                            const token = response.data['token']
                            const horaLogin = response.data['hora_login']
                            this.$store.commit('setToken', token)
                            this.$store.commit('setHoraLogin', horaLogin)
                            axios.defaults.headers.common["Authorization"] = "Token " + token
                            localStorage.setItem("token", token)
                            localStorage.setItem("horaLogin", horaLogin)
                            localStorage.setItem("username", this.username)
                            axios
                            .get("/api/v1/consultar-grupo/")
                            .then(response => {
                                localStorage.setItem("admin", response.data)
                                this.$store.commit('setRol', response.data)         
                            })
                            .catch(error => {
                                console.log(error)
                            })
                            const toPath = this.$route.query.to || '/gestion/inicio'
                            this.$router.push(toPath)
                        }
                    })
                    .catch(error => {
                        this.errors = []
                        if (error.response) {
                            for (const property in error.response.data) {
                                if (error.response.data[property] == 'Unable to log in with provided credentials.')
                                this.errorPassword = ('Usuari@ o contranseña incorrect@')
                                setTimeout(() => this.errorPassword = '', 2000)
                            }
                        } else {
                            console.log(JSON.stringify(error))
                        }
                    })

                this.$store.commit('setIsLoading', false)
            }
        }
    }
}
</script>

<style>

.iniciar-sesion-view {
    padding-top: 150px;
    text-align: center; 
    display: flex;
    flex-direction: column;
}

.iniciar-sesion-div {
    width: 300px;
    height: 260px;
    background-color: rgb(14, 14, 14);
    margin: 50px auto 150px auto;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 33px 22px 0px #000000;
}

#iniciar-sesion-div h1 {
    text-shadow: var(--text-shadow);
}

#usuario-iniciar-sesion-div {
    margin-top: 20px;
}

#password-iniciar-sesion, #usuario-iniciar-sesion {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid var(--fieldBorderColor);
    border-radius: 0;
}

#ojo-icono {
    width: 22px;
    filter: invert(100%);
    position: absolute;
    margin: 22px 0 0 -23px;
}

#usuario-iniciar-sesion,
#password-iniciar-sesion {
    margin-bottom: 13px;
    color: white;
}

#logueado{
    margin-top: 50px;
    color: rgb(155, 155, 155);
}

#boton-iniciar-sesion-div .button {
    background-color: var(--button-color-cliente);
    border: none;
    color: black;
    margin-top: 20px;
}

#boton-iniciar-sesion-div .button:hover {
    background-color: var(--button-color-cliente-hover);
}

@media only screen and (max-width: 900px) {
    .iniciar-sesion-view {
        padding-top: 80px;
        text-align: center;
        height: auto;
        
    }
    .iniciar-sesion-div {
        margin-bottom: 50px;
    }
}
</style>