<template>
    <div class="servicios-view">
        <div id="servicios-cliente-div">
            <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                <div class="lds-dual-ring"></div>
            </div>
            <div id="servicios-cliente-inner">
                <div v-for="item in servicios" :key="item" id="servicios-categorias-div">
                    <h1 id="categoria-servicios">{{ item.categoria }}</h1>
                    <div class="servicios-items-div">
                        <div id="table-wrapper-servicios-cliente">
                            <div id="tabla-servicios-cliente">
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr> 
                                    </thead>

                                    <tbody>
                                        <tr v-for="servicio in item.servicios"  :key="servicio">
                                            <td data-label="SERVICIO" id="td-servicio-cliente-nombre">{{ servicio.servicio }}</td>
                                            <td data-label="DURACION" id="td-servicio-cliente-duracion">{{ servicio.duracion }}MIN.</td>
                                            <td data-label="PRECIO" id="td-servicio-cliente-precio">${{ servicio.precio }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>

<script>
import axios from 'axios'

export default {
   
    name: 'ServiciosView',
    data() {
        return {
            servicios:[]
        }
    },
    components: { 

    },
    mounted() {
        document.title = 'Servicios | Tu sitio nombre'
        this.getServicios();
        if (this.$store.state.isAuthenticated ==false) {
            this.setBgBody()
        }
    },
    methods: {
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-cliente)");
        },
        async getServicios() {
            this.$store.commit('setIsLoading', true)

            let servicios = []
            await axios
                .get('/api/v1/servicios/por-categoria')
                .then(response => {
                    console.log(response.data)
                    for (let i = 0; response.data.length > i; i++) {
                        if (response.data[i].length > 0) {
                            let item = {
                                'categoria':response.data[i][0]['categoria'],
                                'servicios':[]
                            }
                            for (let x = 0; response.data[i].length > x; x++) {
                                item['servicios'].push(
                                    {
                                        'servicio':response.data[i][x]['nombre'],
                                        'precio':response.data[i][x]['precio'],
                                        'duracion':response.data[i][x]['get_duracion']
                                    }
                                )
                            }
                            servicios.push(item)
                        }
                    }
                    console.log('servicios', servicios)
                    this.servicios = servicios
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
    }
}
</script>
  
  <style>
  .servicios-view {
    padding-top: 150px;
    display: flex;
    flex-direction: column;
  }

  #servicios-cliente-div {
    width: 800px;
    background-color: rgb(14, 14, 14);
    margin: 50px auto 110px auto;
    text-align: center;
    color: white;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
  }

  #titulo-servicios-cliente {
    font-size: 40px;
    font-weight: bold;
    padding-top: 20px;
  }

  #servicios-cliente-inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: baseline;
  }

  #categoria-servicios {
    font-size: 30px;
    font-weight: bold;
    color: #8987b1;
  }

  #servicios-categorias-div {
    padding: 30px;
    width: 300px;
  }

  #servicios-clientes {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 0;
  }

  #precio-servicios-cliente {
    color: var(--button-color-cliente);
    float: right;
  }

    #table-wrapper-servicios-cliente {
        position:relative;
    }

    #tabla-servicios-cliente {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    #tabla-servicios-cliente table {
        width: 100%;
    }

    #td-servicio-cliente-nombre {
        width: 170px;
        text-align: left;
        font-weight: bold;
    }

    #td-servicio-cliente-duracion {
        width: 80px;
        text-align: center;
        font-weight: bold;
    }

    #td-servicio-cliente-precio {
        width: 80px;
        text-align: right;
        color: var(--button-color-cliente);
        font-weight: bold;
    }

    #tabla-servicios-cliente table th,
    #tabla-servicios-cliente table td {
        padding: unset;
    }


    #tabla-servicios-cliente table tbody tr:hover {
        background-color: transparent;
    }


  @media screen and (max-width: 900px) {
    .servicios-view {
        padding-top: 80px;
    }

    #servicios-cliente-div {
        margin-bottom: 50px;
    }

    #servicios-cliente-div {
        width: 90%;
    }

    #servicios-categorias-div {
        padding: 20px;
        width: 300px;
    }
  }

  @media screen and (max-width: 370px) {

    #tabla-servicios-cliente {
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }

    #tabla-servicios-cliente table th,
    #tabla-servicios-cliente table td {
        font-size: 15px;
    }

  }
  </style>