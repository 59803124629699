<template>
    <div class="portada-view">
        <div id="header-portada">
            <div id="imagen-header-portada"></div>
            <div class="animated-title">
                <div class="text-top">
                    <div>
                        <span>BIENVENID@S</span>
                    <span>EL FUTURO DE TU SALON COMIENZA ACA!</span>
                    </div>
                </div>
                <div class="text-bottom">
                    <div>
                        <img id="logo-portada" src="@/assets/images/tijeras-logo-fino.png" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div id="texto-boton-medio-div">
            <div id="boton-ingresar-portada">
                <a href="/inicio" class="">INGRESAR</a>
            </div>

            <div id="texto-medio-div">
                <div id="img-despegar-div">
                    <img id="img-despegar" src="@/assets/images/impulsar.png" alt="">
                </div>

                <div id="texto-portada-1-div">
                    <p>
                        Impulsa tu negocio al siguiente nivel con nuestro servicio integral que 
                        combina una página web profesional con una herramienta de gestión inteligente. <br>
                        Vas a poder llevar control completo de tus finanzas, turnos, ventas y mucho más, 
                        todo en un solo lugar.
                    </p>
                </div>
            </div>
        </div>

        <div id="opciones-portada-div">
            <div id="tienda-opciones-div">
                <div id="imagen-opciones">
                    <img src="@/assets/images/tienda-icono-portada.png" alt="">
                </div>
                <div id="texto-opciones">
                    <div id="titulo-texto-opciones">
                        <p>Tienda</p>
                    </div>
                    <div id="contenido-texto-opciones">
                        Guarda y lleva registro de todas 
                        las ventas de productos y servicios.
                    </div>
                </div>
            </div>

            <div id="turnos-opciones-div">
                <div id="imagen-opciones">
                    <img src="@/assets/images/calendario-icono-portada.png" alt="">
                </div>
                <div id="texto-opciones">
                    <div id="titulo-texto-opciones">
                        <p>Turnos</p>
                    </div>
                    <div id="contenido-texto-opciones">
                        Reservas online + turnos en tienda.
                        Turnero inteligente, busca espacios 
                        segun la duracion del servicio.
                    </div>
                </div>
            </div>

            <div id="buscar-opciones-div">
                <div id="imagen-opciones">
                    <img src="@/assets/images/buscar-icono-portada.png" alt="">
                </div>
                <div id="texto-opciones">
                    <div id="titulo-texto-opciones">
                        <p>Buscar</p>
                    </div>
                    <div id="contenido-texto-opciones">
                        Vizualiza el historial de los clientes
                        y las ventas de la tienda.
                    </div>
                </div>
            </div>

            <div id="empleados-opciones-div">
                <div id="imagen-opciones">
                    <img src="@/assets/images/empleados-icono-portada.png" alt="">
                </div>
                <div id="texto-opciones">
                    <div id="titulo-texto-opciones">
                        <p>Empleados</p>
                    </div>
                    <div id="contenido-texto-opciones">
                        Controla ventas, horario, 
                        servicios, adelantos y vacaciones.
                    </div>
                </div>
            </div>

            <div id="stock-opciones-div">
                <div id="imagen-opciones">
                    <img src="@/assets/images/stock-icono-portada.png" alt="">
                </div>
                <div id="texto-opciones">
                    <div id="titulo-texto-opciones">
                        <p>Stock</p>
                    </div>
                    <div id="contenido-texto-opciones">
                        Crea y administra tus 
                        productos de forma rapida y eficaz.
                    </div>
                </div>
            </div>

            <div id="graficos-opciones-div">
                <div id="imagen-opciones">
                    <img src="@/assets/images/estadistica-icono-portada.png" alt="">
                </div>
                <div id="texto-opciones">
                    <div id="titulo-texto-opciones">
                        <p>Graficos</p>
                    </div>
                    <div id="contenido-texto-opciones">
                        Compara totales, 
                        productos y servicios por año, mes o dia.
                    </div>
                </div>
            </div>

            <div id="ajustes-opciones-div">
                <div id="imagen-opciones">
                    <img src="@/assets/images/settings-icono-portada.png" alt="">
                </div>
                <div id="texto-opciones">
                    <div id="titulo-texto-opciones">
                        <p>Ajustes</p>
                    </div>
                    <div id="contenido-texto-opciones">
                        Edita horario laboral, dias cerrados,
                        duracion turnos, servicios y categorias.
                    </div>
                </div>
            </div>

            <div id="multitienda-opciones-div">
                <div id="imagen-opciones">
                    <img src="@/assets/images/multitienda.png" alt="">
                </div>
                <div id="texto-opciones">
                    <div id="titulo-texto-opciones">
                        <p>Multi-tienda</p>
                    </div>
                    <div id="contenido-texto-opciones">
                        Controla tus sucursales desde
                        un mismo lugar.
                    </div>
                </div>
            </div>

            <div id="responsiva-opciones-div">
                <div id="imagen-opciones">
                    <img src="@/assets/images/responsiva-icono-portada.png" alt="">
                </div>
                <div id="texto-opciones">
                    <div id="titulo-texto-opciones">
                        <p>Responsiva</p>
                    </div>
                    <div id="contenido-texto-opciones">
                        Usa la app en cualquier dispositivo.
                        Pc, movil o tablet.
                    </div>
                </div>
            </div>
        </div>

        <div id="texto-planes-portada-div">
            <div id="texto-planes-div">
                <p id="texto-planes">
                    En Tijeras Gestion, entendemos que cada negocio es diferente
                    y por eso te ofrecemos tres opciones para que puedas elegir la que mejor 
                    se adapta a tus necesidades.
                </p>
            </div>
            <div id="planes-portada-div">
                <div class="card-wrap one">
                    <div class="card-header">
                        <h1>SOLO-GESTION</h1>
                    </div>
                    <div class="card-content">
                        <p class="card-content-option true">Sistema de gestion</p>
                        <p class="card-content-option true">Descuento por pago anual</p>
                        <p class="card-content-option true">1 Administrador</p>
                        <p class="card-content-option true">1 Cajero</p>
                        <p class="card-content-option true">1 Estilista</p>
                        <p class="card-content-option true">1 Sucursal</p>
                        <p class="card-content-option true">Asistencia 24/7</p>
                    </div>
                    <div class="card-footer">
                        <p>Desde $4000</p>
                    </div>
                </div>

                <div class="card-wrap two">
                    <div class="card-header">
                        <h1>BASICO</h1>
                    </div>
                    <div class="card-content">
                        <p class="card-content-option true">Pagina web profesional</p>
                        <p class="card-content-option true">Sistema de gestion</p>
                        <p class="card-content-option true">Descuento por pago anual</p>
                        <p class="card-content-option true">1 Administrador</p>
                        <p class="card-content-option true">1 Cajero</p>
                        <p class="card-content-option true">1 Estilista</p>
                        <p class="card-content-option true">1 Sucursal</p>
                        <p class="card-content-option true">Asistencia 24/7</p>
                    </div>
                    <div class="card-footer">
                        <p>Desde $6000</p>
                    </div>
                </div>

                <div class="card-wrap three">
                    <div class="card-header">
                        <h1>PERSONALIZADO</h1>
                    </div>
                    <div class="card-content">
                        <p class="card-content-option true">Pagina web profesional perzonalizada</p>
                        <p class="card-content-option true">Se pueden agregar modulos al sistema de gestion</p>
                        <p class="card-content-option true">Cantidad de usuarios y empleados a eleccion</p>
                        <p class="card-content-option true">Cantidad de sucursales a medida</p>
                        <p class="card-content-option true">Asistencia 24/7</p>
                    </div>
                    <div class="card-footer">
                        <p>$ Consultar</p>
                    </div>
                </div>
            </div>

            <div id="texto-planes-2-div">
                <p id="texto-planes-2">
                    En todos los casos, te ofrecemos una solución completa y eficaz para que puedas concentrarte 
                    en lo que realmente importa: hacer crecer tu negocio. <br>
                    ¡Unite a nuestra comunidad y haz que tu negocio destaque en el mundo digital!
                </p>
            </div>

            <div id="footer-portada">
                <img src="@/assets/images/tijeras-logo-portada.png" alt="">
                <p>&copy; {{ new Date().getFullYear() }}</p>
            </div>
        </div>
    </div>
  </template>

<script>
import axios from 'axios'

export default {
   
    name: 'PortadaView',
    data() {
        return {
        
        }
    },
    components: { 

    },
    mounted() {
        document.title = 'Bienvenid@s | Tijeras Gestion'
        var store = document.querySelector(':root');
        store.style.setProperty('--bg-body', "var(--bg-body-portada)");
        document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
        document.getElementById( 'footer-div' ).style.display = 'none'
        document.getElementById( 'nav-cliente' ).style.display = 'none'
        
        $(window).scroll(function(e){ 
            var $el = $('#boton-ingresar-portada'); 
            var isPositionFixed = ($el.css('position') == 'fixed');
            if ($(this).scrollTop() > 470 && !isPositionFixed){ 
                $el.css({'position': 'fixed', 'top': '30px', 'left': '50%','transform': 'translate(-50%, -50%)'}); 
            }
            if ($(this).scrollTop() < 470 && isPositionFixed){
                $el.css({'position': 'relative', 'top': '35px'}); 
            } 
        });
    },
    methods: {
    }
}
</script>

  
  <style>
    #imagen-header-portada {
        height: 500px;
        max-width: 100%;
        background-attachment: fixed;
        background-position:top;
        background-repeat: no-repeat;
        background-size:  cover ;
        position: relative;
        background-image:linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),url("@/assets/images/imagen-portada4.jpg");
        filter:brightness(40%);
        margin: 0 auto;
        
    }

    #logo-portada {
        width: 500px;
    }

    #texto-boton-medio-div {
        background-color: rgb(243 243 243);
        margin-top: -35px;
    }

    #boton-ingresar-portada {
        margin: 0 auto -10px auto;
        text-align: center;
        padding: 20px;
        z-index: 3;
    }

    @keyframes showTopText {
        0% { transform: translate3d(0, 100%, 0); }
        40%, 60% { transform: translate3d(0, 50%, 0); }
        100% { transform: translate3d(0, 0, 0); }
    }

    @keyframes showBottomText {
        0% { transform: translate3d(0, -100%, 0); }
        100% { transform: translate3d(0, 0, 0); }
    }
    
    .animated-title {
        color: #222;
        height: 90vmin;
        left: 50%;
        position: absolute;
        top: 220px;
        transform: translate(-50%, -50%);
        width: 90vmin;
    }

    .animated-title > div {
        height: 50%;
        overflow: hidden;
        position: absolute;
        width: 100%;
    }

    .animated-title > div div {
        font-size: 12vmin;
        padding: 2vmin 0;
        position: absolute;
    }

    .animated-title > div div span {
        display: block;
    }

    .animated-title > div.text-top {
        border-bottom: 1vmin solid #000;
        top: 0;
        z-index: 2;
    }

    .animated-title > div.text-top div {
        animation: showTopText 1s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
        bottom: 0;
        transform: translate(0, 100%);
    }

    .animated-title > div.text-top div span{
        font-size: 30px;
        font-weight: bold;
        color: rgb(221, 221, 221);
    }

    .animated-title > div.text-top div span:first-child {
        color: #ffffff;
        font-size: 50px;
        
    }
    
    .animated-title > div.text-bottom {
        bottom: 0;
    }

    .animated-title > div.text-bottom div {
        animation: showBottomText 0.5s;
        animation-delay: 1.75s;
        animation-fill-mode: forwards;
        top: 10px;
        transform: translate(0, -100%);
    }

    #texto-planes-portada-div{
        background-color: rgb(243 243 243);
    }

    #texto-portada-1-div{
        margin: 50px;
        width: 60%;
    }

    #texto-portada-1-div p,
    #texto-portada-2-div p{
        font-size: 30px;
        text-transform: uppercase;
        margin: 0 auto 0 auto;
     
    }
    
    #texto-medio-div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: center;
        margin-top: -20px;
    }

    #boton-ingresar-portada a {
        font-size: 18px;
        background-color: #987bc7;
        color: black;
        z-index: 9999;
        text-decoration: none;
        padding: 5px 30px 5px 30px;
        letter-spacing: 2px;
        cursor: pointer;
        border: 3px solid;
        box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
        position: relative;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        border-radius: 10px;
        }

    #boton-ingresar-portada a:active {
    box-shadow: 0px 0px 0px 0px;
    top: 5px;
    left: 5px;
    border-radius: 10px;
    }

    #img-despegar {
        width: 150px;
    }

    #texto-planes-div,
    #texto-planes-2-div{
        width: 70%;
        text-align: center;
        margin: auto;
    }

    #texto-planes {
        padding-top: 30px;
    }

    #texto-planes,
    #texto-planes-2 {
        text-align: center;
        font-size: 25px;
        text-transform: uppercase;
    }

    #planes-portada-div {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }

    :root {
    --color-text1: #000000;
    --color-text2: #000000;
    --color-btn: #565656;
    --card1-gradient-color1: #2bdfff;
    --card1-gradient-color2: #48bcff;
    --card2-gradient-color1: #fad62b;
    --card2-gradient-color2: #fcb30c;
    --card3-gradient-color1: #F574FF;
    --card3-gradient-color2: #C03FCA;
    }

    .card-wrap {
    width: 350px;
    height: 500px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    }

    .card-wrap.one {
    background: linear-gradient(
        to bottom,
        var(--card1-gradient-color1),
        var(--card1-gradient-color2)
    );
    transform: scale(0.9);
    transition: 0.4s;
    margin-top: 20px;
    }

    .card-wrap.two {
    background: linear-gradient(
        to bottom,
        var(--card2-gradient-color1),
        var(--card2-gradient-color2)
    );
    transform: scale(0.9);
    transition: 0.4s;
    margin-top: 20px;
    }

    .card-wrap.one:hover,
    .card-wrap.two:hover,
    .card-wrap.three:hover {
        transform: scale(1,1);

    }

    .card-wrap.three {
    background: linear-gradient(
        to bottom,
        var(--card3-gradient-color1),
        var(--card3-gradient-color2)
    );
    transform: scale(0.9);
    transition: 0.4s;
    margin-top: 20px;
    }

    .card-header {
    width: 160px;
    height: 105px;
    background: rgb(180, 180, 180);
    position: absolute;
    top: -10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: polygon(0 0, 100% 0%, 100% 75%, 50% 100%, 0 75%);
    }

    .card-header h1 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 21px;
    color: var(--color-text1);
    margin-top: -10px;
    }
    .card-content {
    width: 300px;
    position: absolute;
    top: 13%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    }
    .card-content-option {
    color: var(--color-text2);
    font-size: 18px;
    margin-bottom: 10px;

    }
    .card-content-option.false {
    color: #000;
    }
    .card-content-option.false::before {
    content: "\f00d";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
    font-size: 20px;
    vertical-align: middle;
    }
    .card-content-option.true::before {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
    font-size: 20px;
    vertical-align: middle;
    }
    .card-footer {
    width: 150px;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff81;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    }
    .card-footer p {
    font-size: 20px;
    text-align: center;
    margin: 10px 0;
    font-weight: bold;
    }
    .card-footer button {
    border: 1px solid var(--color-btn);
    border-radius: 5px;
    padding: 5px 15px;
    text-transform: uppercase;
    font-size: 12px;
    background: transparent;
    }

    #opciones-portada-div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: baseline;
        width: 80%;
        margin: auto;
    }

    #tienda-opciones-div, #buscar-opciones-div,
    #empleados-opciones-div, #stock-opciones-div,
    #graficos-opciones-div, #ajustes-opciones-div,
    #turnos-opciones-div, #multitienda-opciones-div,   
    #responsiva-opciones-div {
        margin: 50px;
        width: 200px;
    }

    #tienda-opciones-div:hover #imagen-opciones img,
    #turnos-opciones-div:hover #imagen-opciones img,
    #buscar-opciones-div:hover #imagen-opciones img,
    #empleados-opciones-div:hover #imagen-opciones img, 
    #stock-opciones-div:hover #imagen-opciones img,
    #graficos-opciones-div:hover #imagen-opciones img, 
    #ajustes-opciones-div:hover #imagen-opciones img,
    #multitienda-opciones-div:hover #imagen-opciones img,
    #responsiva-opciones-div:hover #imagen-opciones img {
        transform: scale(1.2,1.2);
    }

    #imagen-opciones {
        width: 200px;
        text-align: center;
    }

    #imagen-opciones img {
        width: 70px;
        transition: 0.4s;
    }

    #titulo-texto-opciones {
        width: 200px;
        text-align: center;
    }

    #titulo-texto-opciones p {
        font-weight: bold;
    }

    #contenido-texto-opciones {
        width: 200px;
        text-align: center;
    }

    #footer-portada {
        text-align: center;
    }

    #footer-portada p {
        font-size: 15px;
        margin-top: -30px;
        margin-bottom: 0;
        padding-bottom: 10px;
    }

    #footer-portada img {
        width: 150px;
    }

  @media screen and (max-width: 900px) {
    #logo-portada {
        width: 500px;
    }

    .card-wrap.one:hover,
    .card-wrap.two:hover,
    .card-wrap.three:hover {
        transform: scale(0.9,0.9);
    }

    #tienda-opciones-div:hover #imagen-opciones,
    #buscar-opciones-div:hover #imagen-opciones,
    #empleados-opciones-div:hover #imagen-opciones, 
    #stock-opciones-div:hover #imagen-opciones,
    #graficos-opciones-div:hover #imagen-opciones, 
    #ajustes-opciones-div:hover #imagen-opciones,
    #turnos-opciones-div:hover #imagen-opciones {
        transform: scale(1,1);
    }

    #tienda-opciones-div, #buscar-opciones-div,
    #empleados-opciones-div, #stock-opciones-div,
    #graficos-opciones-div, #ajustes-opciones-div,
    #turnos-opciones-div {
        margin: 20px;
    }

    #texto-portada-1-div {
        width: 90%;
        margin: 30px 0 30px 0;
    }

    #texto-portada-1-div p, #texto-portada-2-div p {
        font-size: 20px;
        text-align: center;
    }

    #img-despegar-div {
        display: none;
    }
    
    #texto-planes,
    #texto-planes-2 {
        font-size: 20px;
    }
  }

  @media screen and (max-width: 725px) {

    .animated-title > div.text-top div span {
        font-size: 20px;
    }

    .animated-title > div.text-top div span:first-child {
        font-size: 35px;
    }

    #logo-portada {
        width: 340px;
    }
  }

  @media screen and (max-width: 400px) {
    #logo-portada {
        width: 200px;
    }
  }
  </style>