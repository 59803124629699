<template>
    <div id="gestion-estadisticas-view">
        <div id="graficos-estadisticas" v-if="this.$store.state.admin">
            <h1 id="titulo-estadisticas">GRAFICOS ESTADISTICOS</h1>

            <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                <div class="lds-dual-ring"></div>
            </div>
            <div id="comp">
                <CajaGraficoAnual />
            </div>
            <div id="comp">
                <CajaGraficoMensual />
            </div>
            <div id="comp">
                <CajaGraficoDia />
            </div> 
        </div>
        
    </div>
</template>

<script>
import axios from 'axios'
import CajaGraficoAnual from '@/components/CajaGraficoAnualComp.vue'
import CajaGraficoMensual from '@/components/CajaGraficoMensualComp.vue'
import CajaGraficoDia from '@/components/CajaGraficoDiaComp.vue'

export default {
    name: 'GestionEstadisticasView',
    data() {
        return {
           
        }
    },
    components: {
        CajaGraficoAnual,
        CajaGraficoMensual,
        CajaGraficoDia
    },
    mounted() {
        this.setBgBody();
    },
    methods: {
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-gestion)");
            document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
        },
    },
    computed: {
    }
}
</script>

<style>
#gestion-estadisticas-view {
    padding-top: 180px;
    text-align: center;
}

#titulo-estadisticas {
    background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-10&color=#000000);
    width: 320px;
    margin: 0 auto 10px auto;
    text-shadow: var(--text-shadow);
}

#comp {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}


@media screen and (min-width: 900px) and (max-width: 1500px) {

    #gestion-estadisticas-view {
        width:90%;
        margin-left: auto;
        margin-right: auto;
    }

}

@media screen and (min-width: 700px) and (max-width: 900px) {
    #gestion-estadisticas-view {
        padding-top: 130px;
        width:90%;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 700px) {
    #gestion-estadisticas-view {
        padding-top: 130px;
        width:90%;
        margin-left: auto;
        margin-right: auto;
    } 
}


</style>