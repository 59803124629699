<template>
  <HeaderComp />
  <div v-if="this.$store.state.isAuthenticated" id="iconos-afip-usuario">
    <a href="https://facturador.afip.gob.ar/#/bienvenida" target="_blank">
      <img id="afip-icon" src="@/assets/images/afip.png" alt="" title="Facturador Afip">
    </a>

    <img id="usuario-icon" src="@/assets/images/user.png" alt="">
    <div id="info-usuario-icono">
      <p>ULTIMO INICIO <br> {{ this.$store.state.horaLogin }}HS </p>
    </div>
  </div>
  
  <div id="solicitar-demo-div">
    <p @click="mandarWhatsApp">SOLICITAR DEMO</p>
  </div>

  <router-view v-slot="{ Component }">
    <transition name="scale" mode="out-in">
    <component :is="Component" :key="$route.path" />
    </transition>
  </router-view>

  <FooterComp />
</template>

<script>
  import HeaderComp from '@/components/HeaderComp';
  import FooterComp from '@/components/FooterComp';
  import axios from 'axios'

  export default {
    data() {
      return {
        showModalDemo:false,
        usuarioInfo:''
      }
    },
    beforeCreate() {
      this.$store.commit('initializeStore')
      const token = this.$store.state.token
      if (token) {
          axios.defaults.headers.common['Authorization'] = "Token " + token
      } else {
          axios.defaults.headers.common['Authorization'] = ""
      }
    },
    components: {
      HeaderComp,
      FooterComp,
    },
    mounted() {
     
    },
    methods: {
      mandarWhatsApp (e) {
          let a= document.createElement('a');
          const fecha = e.target.dataset.fecha
          const hora = e.target.dataset.hora
          a.target= '_blank';
          a.href= "https://wa.me/5492235110667/?text=Hola! me gustaria solicitar una demo de Tijeras Gestion, gracias!"
          a.click();
      },
    }
  };
</script>

<style lang="scss">
:root {
  --bg-body: white;
  --bg-body-gestion: url('@/assets/images/fondo1.png');
  --bg-body-cliente: url('@/assets/images/violeta.png');
  --bg-body-portada: white;
  --text-shadow-navbar: #000000;
  --text-shadow-navbar-hover: #084d50;
  --title-h1: #1b0550;
  --button-color: #615aa2;
  --button-color-cliente: rgb(209, 44, 94);
  --button-color-hover: #3e3969;
  --button-color-cliente-hover: rgb(206, 0, 62);
  --button-hour-color: #046677;
  --button-close: #bd466a;
  --button-color-small: #44793f;
  --fieldBorderColor: #519ba7;
  --fieldBorderColorActive: #2f5a61;
  --bg-box-black: url("@/assets/images/pizarron.png");
  --bg-box-black-big: url("@/assets/images/pizarron-grande.png");
  --bg-grey: url("@/assets/images/bg-grey.jpg");
  --text-shadow: 0 0 2px #fff, 
                0 0 3px var(--title-h1), 
                0 0 4px var(--title-h1), 
                0 0 5px var(--title-h1), 
                0 0 6px var(--title-h1), 
                0 0 7px var(--title-h1), 
                0 0 8px var(--title-h1);
}

@import url('https://fonts.googleapis.com/css2?family=Khand&display=swap');

html {
  height: 0;
}

body {
  background: var(--bg-body);
  background-attachment: fixed;
}

.grecaptcha-badge {
  visibility: hidden;
}

input[type="checkbox"] {
  accent-color: #5a65a2;
  cursor: pointer;
}

*{
  font-family: 'Khand', sans-serif;
  font-size: 18px;
  letter-spacing: .1em;
}

*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.4s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.95);
}

#iconos-afip-usuario {
  top: 80%;
  left:10px;
  position:fixed;
  z-index:100;
  display: flex;
  flex-direction: column;
}

#afip-icon {
  width: 50px;
	height:50px;
	background-color:#139ed9;
	border-radius:50px;
  z-index:100;
  box-shadow: 0px 15px 12px 0px #000000;
}

#usuario-icon {
  width: 50px;
	height:50px;
	background-color:#8b5aa2;
	border-radius:50px;
  z-index:100;
  box-shadow: 0px 15px 12px 0px #000000;
}

#usuario-icon:hover ~ #info-usuario-icono{
  display: block;
}

#info-usuario-icono {
  display: none;
}

#info-usuario-icono p{
  background-color: #615aa2;
  padding: 5px;
  top: 90%;
  left:70px;
  position: fixed;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0px 15px 12px 0px #000000;
  color: white;
  z-index: 999;
}

#solicitar-demo-div p{
  top: 90%;
  right: 0;
  z-index:999;
  position: fixed;
  background-color: var(--button-color-cliente);
  padding: 10px;
  border-radius: 10px 0 0 10px;
  box-shadow: 0px 15px 12px 0px #000000;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

#solicitar-demo-div p:hover {
  background-color: var(--button-color-cliente-hover);
}

table tbody tr:hover {
  background-color: #555;
}

/* ===== SweetAlert ===== */

.swal2-popup {
  color: white;
  background: var(--bg-grey);
  background-position: center center;
  background-size: cover;
}

.swal2-icon-content{
  letter-spacing: 0;
}

.swal2-title {
  text-shadow: var(--text-shadow);
  letter-spacing: .1em;
  font-size: 33px;
  font-weight: 500;
}

.swal2-styled {
  margin: .3125em;
  padding: 0px 15px 0px 15px;
  transition: box-shadow .1s;
  box-shadow: 0 0 0 3px rgba(0,0,0,0);
  font-weight: 500;
}

/* ===== DatePicker ===== */

.dp__clear_icon {
    top: 50%;
    right: -5px;
    color: white;
}

.dp__input::placeholder{
    color: #ffffff;
    font-weight: 500;
    opacity: 1;
}

.dp__theme_dark, .dp__action_row, .dp__arrow_bottom {
    background-color: rgb(80, 80, 80);
    border: none;
}

/* ===== Loading ring ===== */

.lds-dual-ring-wallet:after{
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 6px solid #2cbebe;
  border-color: #2cbebe transparent #2cbebe transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-top: 200px;
}

.lds-dual-ring {
  display: inline-block;
  width: 90px;
  height: 90px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #2cbebe;
  border-color: #2cbebe transparent #2cbebe transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.is-loading-bar {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  &.is-loading {
    height: 100px;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: #000000 #ffffff00;
}

/* Chrome, Edge, and Safari */
body::-webkit-scrollbar {
  width: 5px;
  background-color: #000000 #ffffff00;
}

body::-webkit-scrollbar-thumb {
  background-color: #555;
}

@media only screen and (max-width: 900px) {
  table tbody tr:hover {
    background-color:  transparent;
  }
}
</style>
