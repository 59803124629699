<template>
    <div class="turnos-view">
            <div id="col-1">
                <div id="reserva-cliente-div">
                    <h1>RESERVA ONLINE</h1>
                    <div id="nombre-reserva-div">
                        <div class="field field_v2">
                            <label for="nombre-reserva" class="ha-screen-reader">Nombre</label>
                            <input v-model="cliente.nombre"  id="nombre-reserva" class="field__input" placeholder="ej. Lorena" autocomplete="off">
                            <span class="field__label-wrap" aria-hidden="true">
                                <span class="field__label">Nombre</span>
                            </span>
                        </div>    
                        <span v-if="errors.name"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errors.name }}</p></span>
                    </div>

                    <div id="apellido-reserva-div">
                        <div class="field field_v2">
                            <label for="apellido-reserva" class="ha-screen-reader">Apelllido</label>
                            <input v-model="cliente.apellido"  id="apellido-reserva" class="field__input" placeholder="ej. Lorena" autocomplete="off">
                            <span class="field__label-wrap" aria-hidden="true">
                                <span class="field__label">Apellido</span>
                            </span>
                        </div>     
                        <span v-if="errors.lastname"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errors.lastname }}</p></span>
                    </div>
                    
                    <div id="telefono-reserva-div">
                        <div class="field field_v2">
                            <label for="telefono-reserva" class="ha-screen-reader">Telefono</label>
                            <input v-model="cliente.telefono" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="telefono-reserva" title="Solo numeros" class="field__input" placeholder="ej. 114123456" autocomplete="off">
                            <span class="field__label-wrap" aria-hidden="true">
                                <span class="field__label">Telefono</span>
                            </span>
                        </div> 
                        <span v-if="errors.phone"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errors.phone }}</p></span>
                    </div>
        
                    <div id="servicio-reserva-div">
                        <div id="servicio-reserva-inner">
                            <select id="servicio-reserva" class="select-style" @change="handleChange" v-model="servicioReservaSelect">
                            <option value="" disabled hidden>Servicio</option>
                            <option v-for="servicio in servicios" :key="servicio.id" data-info="servicio" :data-id="servicio.id" :data-precio="servicio.precio">{{ servicio.nombre }}</option>
                            </select>
                        </div>
                        <span v-if="errors.service"><p class="error-select" style="color: red; height: 0;">{{ errors.service }}</p></span>
                    </div>

                    <div id="empleado-reserva-div">
                        <div id="empleado-reserva-inner">
                            <select id="empleado-reserva" class="select-style" @change="handleChange" v-model="empleadoReservaSelect">
                                <option value="" disabled hidden>Emplead@</option>
                                <option v-for="empleado in empleados" :key="empleado" data-info="empleado-turno" 
                                    :data-posicion="empleado.posicion" :data-id="empleado.id" 
                                    :data-dias="empleado.get_no_trabaja" :data-vacaciones="empleado.vacaciones_list">
                                        {{ empleado.nombre }} {{ empleado.apellido }}
                                </option>
                            </select>
                        </div>
                        <span v-if="errors.employee"><p class="error-select" style="color: red; height: 0;">{{ errors.employee }}</p></span>
                    </div>

                    <div id="reserva-fecha">
                        <Datepicker 
                            dark
                            auto-apply
                            hide-input-icon
                            hide-offset-dates
                            placeholder="Fecha"
                            v-model="cliente.fecha"
                            :enable-time-picker="false"
                            :format="format" 
                            locale="es" 
                            cancelText="Cancelar" 
                            selectText="Seleccionar" 
                            id="fecha-reserva"
                            @cleared="limpiar"                     
                            :auto-position="false"
                            :disabled-week-days="noTrabaja"
                            :disabled-dates="vacaciones"
                            :year-range="[new Date().getFullYear()]"
                        />
                        <div id="field-errors" v-if="errors.date">{{ errors.date }}</div>          
                    </div>

                    <div id="boton-buscar-turno" v-if="mostrarHoras">
                        <button @click="buscarTurno">MOSTRAR HORAS DISPONIBLES</button>
                    </div>
                    
                    <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                        <div class="lds-dual-ring"></div>
                    </div>
                    
                    <div id="horas-disponible-turnos" v-if="horasDisponibles">
                        <button id="boton-hora" class="ripple" @click="seleccionarTurno" v-for="hora in horasDisponibles" :key="hora" :value="hora">{{ hora }}Hs</button> 
                    </div> 

                    <div id="reservar-turno-text" v-if="reservarTexto">
                        <h1> RESERVAR TURNO PARA {{ cliente.fecha.toLocaleDateString('es-ES', options) }} {{ cliente.hora }}HS?</h1>
                        <button id="boton-cambiar-hora" @click="cambiarReserva">CAMBIAR HORA</button>
                        <button id="boton-reservar" data-token="" @click="getRecaptcha">RESERVAR</button>
                    </div>

                    <!--<vue-recaptcha sitekey="Your key here">
                        <div id="boton-guardar-turno">
                            <button @click="guardar">GUARDAR</button>
                        </div>
                    </vue-recaptcha>-->
                </div>

                <div id="por-whatsapp">
                    <h1>WHATSAPP</h1>
                    <div id="img-whtasapp-div">
                        <a href="https://wa.me/5492235110667/?text=Hola!%20me%20gustaria%20hacer%20una%20reserva.%20Gracias!" class="btn-wsp" target="_blank">
                            <img id="whatsapp-logo" src="@/assets/images/whatsapp-logo-white.png" alt="whatsapp-logo">
                        </a>
                    </div>
                </div>
            </div>

            <div id="col-2">
                <div id="img-turnos-div">
                    <img id="img-turnos" src="@/assets/images/fondo-turnos.jpg" alt="">
                </div>
            </div>
            
        </div>
</template>

<script>
import axios from 'axios'
import { VueRecaptcha } from 'vue-recaptcha';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';

const date = ref(new Date());
// In case of a range picker, you'll receive [Date, Date]
const format = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
}

export default {
   
    name: 'TurnosView',
    data() {
        return {
            format,
            date,
            empleados:[],
            turnos:[],
            empleadoReservaSelect:'',
            servicioReservaSelect:'',
            contador:1,
            exist:'',
            created:'',
            cliente: {
                fecha:'',
                nombre:'',
                apellido:'',
                telefono:'',
                empleadoId:'',
                servicioId:'',
                hora:''
            },
            errors:{
                name:'',
                lastname:'',
                phone:'',
                employee:'',
                date:'',
                service:''
            },
            ip:'',
            servicios:'',
            noTrabaja:[],
            vacaciones:[],
            reservarBoton:false,
            mostrarHoras:true,
            horasDisponibles:[],
            reservarTexto:false,
            options:{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
        }
    },
    components: { 
        Datepicker,
        VueRecaptcha
    },
    mounted() {
        document.title = 'Turnos | Tu sitio nombre'
        this.getServicios();
        getIPs().then(response => this.ip = response.join('\n'));
        if (this.$store.state.isAuthenticated ==false) {
            this.setBgBody()
        }
    },
    methods: {
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-cliente)");
        },
        async getDiasCerrado() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/comercio/dias-cerrado/')
                .then(response => {
                    for (let i = 0;response.data.length > i; i++) {
                        if (response.data[i]['cerrado'] == true) {
                            let numero = response.data[i]['numero']
                            let existe;
                            if (numero == 6){
                                numero = 0
                                existe = this.noTrabaja.includes(numero.toString())
                            }else{
                                existe = this.noTrabaja.includes(numero.toString())
                            }
                            if (existe == false) {
                                this.noTrabaja.push(numero.toString())
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getEmpleados() {
            this.$store.commit('setIsLoading', true)
            this.empleados = []
            const data = {
                id:this.cliente.servicioId
            }
            await axios
                .post('/api/v1/empleados/por-servicio/', data)
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        if(response.data[i]['empleado']['posicion'] != 'ADMIN' && response.data[i]['empleado']['posicion'] != 'CAJER@') {
                            this.empleados.push(response.data[i]['empleado'])
                        }
                    }     
                })
                .catch(error => {
                   
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getServicios() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/servicios/')
                .then(response => {
                    this.servicios = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        limpiar() {
            this.horasDisponibles = []
        },
        async buscarTurno(e) {
            this.$store.commit('setIsLoading', true)
          
            if (!this.cliente.nombre) {
                this.errors.name = 'Requerido'
                setTimeout(() => this.errors.name = '', 3000)
            }
            if (!this.cliente.apellido) {
                this.errors.lastname = 'Requerido'
                setTimeout(() => this.errors.lastname = '', 3000)
            }
            if (!this.cliente.telefono) {
                this.errors.phone = 'Requerido'
                setTimeout(() => this.errors.phone = '', 3000)
            }
            if (!this.cliente.servicioId) {
                this.errors.service = 'Requerido'
                setTimeout(() => this.errors.service = '', 3000)
            }
            if (!this.cliente.empleadoId) {
                this.errors.employee = 'Requerid@'
                setTimeout(() => this.errors.employee = '', 3000)
            }
            if (!this.cliente.fecha) {
                this.errors.date = 'Requerida'
                setTimeout(() => this.errors.date = '', 3000)
            }

            e.preventDefault();

            if (!this.errors.name && !this.errors.lastname && !this.errors.phone && !this.errors.employee && !this.errors.date) {     
                const data = {
                    fecha:this.cliente.fecha,
                    servicio:this.cliente.servicioId,
                    empleado:this.cliente.empleadoId
                }
                await axios
                    .post('/api/v1/turnos/buscar/', data)
                    .then(response => {
                        this.horasDisponibles = response.data[0]['horas']
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            this.$store.commit('setIsLoading', false)
        },
        async seleccionarTurno(e) {
            this.cliente.hora = e.target.value
            this.mostrarHoras = false
            this.horasDisponibles = []
            this.reservarTexto = true
        },
        cambiarReserva(e) {
            this.reservarTexto = false
            this.mostrarHoras = true
            this.buscarTurno(e)
        },
        async getRecaptcha(e) {
            this.horasDisponibles = []
            this.reservarTexto = false
            this.mostrarHoras = true
            this.$store.commit('setIsLoading', true)
            const boton = document.getElementById('boton-reservar');
            grecaptcha.ready(function() {
                grecaptcha.execute('6Ld9Yw0mAAAAAIygstc29M2XIb8f50jMitjPGzkY')
                .then(response => {
                    // Add your logic to submit to your backend server here.
                    boton.dataset.token = response;
                })
            });

            setTimeout(() => {
                this.reservar(e)
            }, 2000);
        },
        async reservar(e) {
            if (!this.cliente.nombre) {
                this.errors.name = 'Requerido'
                setTimeout(() => this.errors.name = '', 3000)
            }
            if (!this.cliente.apellido) {
                this.errors.lastname = 'Requerido'
                setTimeout(() => this.errors.lastname = '', 3000)
            }
            if (!this.cliente.telefono) {
                this.errors.phone = 'Requerido'
                setTimeout(() => this.errors.phone = '', 3000)
            }
            if (!this.cliente.servicioId) {
                this.errors.service = 'Requerido'
                setTimeout(() => this.errors.service = '', 3000)
            }
            if (!this.cliente.empleadoId) {
                this.errors.employee = 'Requerid@'
                setTimeout(() => this.errors.employee = '', 3000)
            }
            if (!this.cliente.fecha) {
                this.errors.date = 'Requerida'
                setTimeout(() => this.errors.date = '', 3000)
            }

            e.preventDefault();

            if (!this.errors.name && !this.errors.lastname && !this.errors.phone && !this.errors.employee && !this.errors.date) {     
                const data = {
                    cliente:this.cliente,
                    ip:this.ip,
                    confirmado:false,
                    token:e.target.dataset.token
                }
                
                await axios
                    .post('/api/v1/reservas/crear/', data)
                    .then(response => {
                        if (response.data == 'reserva creada') {
                            Swal.fire({
                                title:'Turno reservado con exito.\nTe enviamos un Whatsapp a la brevedad.\nGracias!',
                                confirmButtonText: '<i class="fa fa-thumbs-up"></i> ENTENDIDO!',
                            })
                        }
                        if (response.data == 'mas-3') {
                            Swal.fire({
                                title:'Corfirma las 3 reservas anteriores para realizar una nueva.\n Cualquier consulta mandanos un Whatsapp o llamanos. Gracias!',
                                confirmButtonText: '<i class="fa fa-thumbs-up"></i> ENTENDIDO!',
                            })
                        }
                        if (response.data == 'robot') {
                            Swal.fire({
                                title:'Na na na robot',
                                confirmButtonText: '<i class="fa fa-thumbs-up"></i> ENTENDIDO!',
                            })
                        }
                        this.cliente = {
                            fecha:'',
                            nombre:'',
                            apellido:'',
                            telefono:'',
                            empleadoId:'',
                            servicioId:'',
                            hora:''
                        }
                        this.empleadoReservaSelect = ''
                        this.servicioReservaSelect = ''
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            this.$store.commit('setIsLoading', false)
        },
        getDatesInRange(startDate, endDate) {
            const end = new Date(endDate.replaceAll('/','-'))
            const date = new Date(startDate.replaceAll('/','-'))
            
            date.setDate(date.getDate() + 1);
            end.setDate(end.getDate() + 1)

            while (date <= end) {
                this.vacaciones.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }
        },
        async handleChange(e) {
            if(e.target.options.selectedIndex > -1) {
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "servicio"){
                    this.cliente.servicioId = e.target.options[e.target.options.selectedIndex].dataset.id
                    this.getEmpleados()
                }
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "empleado-turno"){
                    this.cliente.empleadoId = e.target.options[e.target.options.selectedIndex].dataset.id 
                    let noTrabaja = e.target.options[e.target.options.selectedIndex].dataset.dias
                    if (noTrabaja != ''){
                        this.noTrabaja = noTrabaja.split(',')
                    }else {
                        this.noTrabaja = []
                    }
                    this.vacaciones = []
                    let vacaciones = e.target.options[e.target.options.selectedIndex].dataset.vacaciones
                    
                    if (vacaciones != '-') {
                        let jsonVacaciones = JSON.parse(vacaciones)

                        if (jsonVacaciones.length > 1) {
                            for (let i = 0; jsonVacaciones.length > i; i++) {
                                let fechas = jsonVacaciones[i]['fecha']
                                this.getDatesInRange(fechas[0].trim(), fechas[1].trim())
                            }
                        }
                        if (jsonVacaciones.length == 1){
                            let fechas = jsonVacaciones[0]['fecha']
                            this.getDatesInRange(fechas[0].trim(), fechas[1].trim())
                        } 
                    }
                    this.getDiasCerrado()
                } 
            }
        },
    }
}
</script>

<style>
.turnos-view {
    padding-top: 125px;
    color: white;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.turnos-view .select-style {
    background-color: transparent;
    border: none;
    margin-bottom: 1px;
    padding-left: 12px;
}

#servicio-reserva-inner,
#empleado-reserva-inner,
#fecha-reserva {
  border-bottom: 2px solid var(--fieldBorderColor);
}

.turnos-view p {
    margin-top: inherit;
}

#col-1, #col-2 {
    width: 50%;
}

#col-1 h1 {
    font-size: 50px;
}

#reserva-cliente-div {
    background-color: rgb(14, 14, 14);
    max-width: 500px;
    margin: 0 auto 0 auto;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 33px 22px 0px #000000;
}

#img-turnos-div {
    height: 1000px;
}

#img-turnos {
    object-fit:cover;
    object-position: top;
    width: 100%;
    height: 1000px;
}

#servicio-reserva-inner,
#empleado-reserva-inner {
    height: 34px;
}

#nombre-reserva-div, #apellido-reserva-div, #telefono-reserva-div,
#empleado-reserva-div, #servicio-reserva-div, #reserva-fecha {
    display: inline-table;
    margin: 20px 5px 0 5px;
    height: 67px;
}

#nombre-reserva, #apellido-reserva, #telefono-reserva {
    width: 150px;
    margin-bottom: 13px;
}

#fecha-reserva, #empleado-reserva, #servicio-reserva  {
    width: 150px;
    color:white
}

#fecha-reserva {
    background-color: transparent;
}

#fecha-reserva input {
    height: 38px;
    text-align: left;
    font-family: 'Khand';
    color: white;
    font-size: 18px;
    background-color: transparent;
    border: none;
    padding-left: 18px;
}

#fecha-reserva .dp__input_icon {
    top: 40%;
    color: #000000;
}

#fecha-reserva .dp__input::placeholder{
    color: #ffffff;
    font-weight: 500;
}

.dp__selection_preview {
    font-size: 18px;
}

.dp__clear_icon {
    top: 35%;
    right: -5px;
    color: black;
}

#boton-buscar-turno button {
    background-color: var(--button-color-cliente);
    border: none;
    color: white;
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: 10px;
}

#boton-buscar-turno button:hover {
    background-color: var(--button-color-cliente-hover);
}

#boton-hora {
    color: black;
    background-color: #ff7fff;
    width: 61px;
    margin-bottom: 5px;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    padding: 0;
    font-size: 15px;
}

#boton-hora:hover {
    background-color: rgb(199 53 129);
}

#boton-cambiar-hora, #boton-reservar {
    margin-left: 5px;
    margin-right: 5px;
    color: black;
    border: none;
    font-size: 15px;
}

#boton-cambiar-hora {
    background-color: #e74f4f;
}

#boton-reservar {
    background-color: rgb(159, 93, 165);
}

#reservar-turno-text {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

#img-whtasapp-div {
    width: fit-content;
    margin: 0 auto 0 auto;
}

#por-whatsapp {
    margin: 50px;
    max-width: 500px;
    background-color: rgb(14, 14, 14);
    margin: 50px auto 50px auto;
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0px 33px 22px 0px #000000;
}

#whatsapp-logo {
    display: block;
    width: 50px;
    margin: 20px auto 0 auto;
    animation-name: floating;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    cursor: pointer;
}

#telefono-reserva::-webkit-inner-spin-button,
#telefono-reserva::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}


@keyframes floating {
    0% { }
    5%,25% {
        transform: translate(0, -12px);
        }
        15%,35% {
        transform: translate(0, 0);
        }
        40% {
        transform: translate(0, 0);
        }
}


@media screen and (min-width: 1350px) and (max-width: 1670px) {


}


@media screen and (max-width: 1000px) {

    .turnos-view {
        padding-top: 130px;
        margin-bottom: 10px;
        display: block;
    }

    #col-1, #col-2 {
       width: 90%;
       margin: auto;
    }

    #img-turnos-div {
        display: none;
    }

    #por-whatsapp {
        margin-top: 50px;
    }

    #boton-hora:hover {
        background-color: rgb(203 112 159);
    }

    #horas-disponibles-turnos {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    /* Ripple effect */
    .ripple {
        background-position: center;
        transition: background 0.8s;
    }
    .ripple:hover {
        background: rgb(199 53 129) radial-gradient(circle, transparent 1%, rgb(199 53 129) 1%) center/15000%;
    }
    .ripple:active {
        background-color: rgb(199 53 129);
        background-size: 100%;
        transition: background 0s;
    } 
    
    .dp__menu.dp__menu_index {
        top: 280px;
    }

}

@media screen and (max-width: 380px) {
    #nombre-reserva, #apellido-reserva, #telefono-reserva,
    #fecha-reserva, #empleado-reserva, #servicio-reserva {
        width: 120px;
    }
}

@media screen and (max-width: 312px) {
    #nombre-reserva, #apellido-reserva, #telefono-reserva,
    #fecha-reserva, #empleado-reserva, #servicio-reserva {
        width: 105px;
    }
}

</style>