<template>
    <div id="gestion-caja-view">
        <div id="col-tabla-caja">
            <div id="table-wrapper">
                <div id="tabla-caja">
                    <table>
                        <thead>
                            <tr>
                                <th>CAJERO</th>
                                <th>C.INICIO</th>
                                <th>EFECTIVO</th>
                                <th>TARJETA</th>
                                <th>RETIRADO</th>
                                <th>TOTAL</th>
                            </tr> 
                        </thead>

                        <tbody>
                            <tr>
                                <td data-label="CAJERO">{{ user }}</td>
                                <td data-label="C.INICIO"><img id="trash-caja" src="@/assets/images/trash.png" alt="trash-icon" title="ELIMINAR" @click="cajaInicio" data-info="eliminar">${{ inicio }}</td>
                                <td data-label="EFECTIVO">${{ efectivo }}</td>
                                <td data-label="TARJETA">${{ tarjeta }}</td>
                                <td data-label="RETIRO"><img id="trash-caja" src="@/assets/images/trash.png" alt="trash-icon" title="ELIMINAR" @click="retirar" data-info="eliminar">${{ retirado }}</td>
                                <td data-label="TOTAL" id="total-usuario-caja">${{ sumaCaja }}</td>
                            </tr>
                        </tbody> 
                    </table>
                </div>
            </div>
        </div>

        <div id="caja-inicio-retiro-div">
            <div id="caja-inicio">
                <h1>CAJA INICIO</h1>
                <div id="inicio-div">
                    <div class="field field_v2">
                        <label for="inicio" class="ha-screen-reader">Monto</label>
                        <input v-model="inicial" id="inicio" class="field__input" placeholder="ej. 1000" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Monto</span>
                        </span>    
                    </div>
                    <span v-if="errorInicio"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorInicio }}</p></span>
                </div>
                <div id="boton-inicio-div">
                    <button id="button" @click="cajaInicio" data-info="guardar">AGREGAR</button>
                </div>
            </div>

            <div id="retirar">
                <h1>RETIRO</h1>
                <div id="retiro-div">
                    <div class="field field_v2">
                        <label for="retiro" class="ha-screen-reader">Monto</label>
                        <input v-model="retiro" id="retiro" class="field__input" placeholder="ej. 1000" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Monto</span>
                        </span>    
                    </div>
                    <span v-if="errorRetiro"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorRetiro }}</p></span>
                </div>
                <div id="boton-retiro-div">
                    <button id="button" @click="retirar" data-info="guardar">RETIRAR</button>
                </div>
            </div>  
        </div>

        <div id="col-tabla-cajeros" v-if="this.$store.state.isAuthenticated">
            <div id="table-wrapper">
                <div id="tabla-cajeros">
                    <table>
                        <thead>
                            <tr>
                                <th>CAJERO</th>
                                <th>C.INICIO</th>
                                <th>EFECTIVO</th>
                                <th>TARJETA</th>
                                <th>RETIRADO</th>
                                <th>TOTAL</th>
                            </tr> 
                        </thead>

                        <tbody>
                            <tr v-for="cajero in cajeros" :key="cajero.id">
                                <td data-label="CAJERO">{{ cajero.usuario }}</td>
                                <td data-label="C.INICIO">${{ cajero.inicio }}</td>
                                <td data-label="EFECTIVO">${{ cajero.efectivo }}</td>
                                <td data-label="TARJETA">${{ cajero.tarjeta }}</td>
                                <td data-label="RETIRO">${{ cajero.retirado }}</td>
                                <td data-label="TOTAL" id="total-usuario-caja">$ {{ cajero.efectivo + cajero.tarjeta + cajero.inicio - cajero.retirado }} </td>
                            </tr>
                        </tbody> 
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'GestionCajaView',
    data() {
        return {
            user: localStorage.getItem("username"),
            efectivo:'0',
            tarjeta:'0',
            retirado:'0',
            inicio:'0',
            inicial:'',
            retiro:'',
            errorRetiro: '',
            errorInicio: '',
            cajeros:[]
        }
    },
    components: {
    },
    mounted() {
        this.getCaja();
        this.setBgBody();
    },
    methods: {
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-gestion)");
            document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
        },
        async getCaja() {
            await axios
                .get("/api/v1/caja/")
                .then(response => {
                    this.efectivo = response.data['total_efectivo']
                    this.tarjeta = response.data['total_tarjeta']
                    this.retirado = response.data['total_retirado']
                    this.inicio = response.data['total_caja_inicio']
                    this.cajeros = response.data['cajeros']
                })
                .catch(error => {
                    if (error.response.status === 401){
                        localStorage.removeItem("token")
                        localStorage.removeItem("username")
                        localStorage.removeItem("userid")
                        this.$store.commit('removeToken')
                        this.$router.push('/gestion')
                    }
                    console.log(error)
                })
            
        },
        async retirar(e) {
            if (e.target.dataset.info != 'eliminar'){
                if (!this.retiro) {
                    this.errorRetiro = 'Requerido'
                    setTimeout(() => this.errorRetiro = '', 2000)
                }
            }

            e.preventDefault();

            if (!this.errorRetiro) {
                let data = {
                    total:this.retiro,
                    info:e.target.dataset.info
                }
                
                await axios
                    .post("/api/v1/caja/retiro/", data)
                    .then(response => {
                        this.getCaja();
                        this.retiro = ''
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                    })
            }
        },
        async cajaInicio(e) {
            if (e.target.dataset.info != 'eliminar'){
                if (!this.inicial) {
                    this.errorInicio = 'Requerido'
                    setTimeout(() => this.errorInicio = '', 2000)
                }
            }

            e.preventDefault();

            if (!this.errorInicio) {
                let data = {
                    total:this.inicial,
                    info:e.target.dataset.info
                }

                await axios
                    .post("/api/v1/caja/inicial/", data)
                    .then(response => {
                    this.getCaja();
                    this.inicial = ''
                    })
                    .catch(error => {
                    console.log(JSON.stringify(error))
                    })
            }
        }
    },
    computed: {
        sumaCaja() {
            return (parseFloat(this.inicio) + parseFloat(this.efectivo) + parseFloat(this.tarjeta)) - parseFloat(this.retirado)   
        }
    }
}
</script>

<style>
#gestion-caja-view {
    padding-top: 180px;
    text-align: center;
}

#col-tabla-caja #table-wrapper,
#col-tabla-cajeros #table-wrapper  {
    position:relative;
    margin-bottom: 0;
}

#col-tabla-caja, #col-tabla-cajeros {
    text-align: center;
    margin: 0 auto 0 auto;
    max-width: 900px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    overflow:auto;  
    background: var(--bg-box-black-big);
    background-position: center center;
    background-size: cover;
}

#col-tabla-cajeros {
    margin-top: 50px;
}

#tabla-caja table tr td,
#tabla-cajeros table tr td {
    font-size: 20px;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;
}

#tabla-caja table thead tr th,
#tabla-cajeros table thead tr th {
    font-size: 20px;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;
}

#tabla-caja::-webkit-scrollbar-thumb,
#tabla-cajeros::-webkit-scrollbar-thumb {
	background-color: #555;
    border-radius: 4px;
}

#tabla-caja::-webkit-scrollbar,
#tabla-cajeros::-webkit-scrollbar {
    width: 5px;
	background-color: #000000;
    height: 5px;
}

#tabla-caja thead tr th,
#tabla-cajeros thead tr th {
    position: sticky;
    top: 0;
    background-color: #000000;
    border: none;
    font-weight: 900;
    font-size: 20px;
}

#total-usuario-caja {
    color: #ff3bff;
}

#caja-inicio, #retirar {
    display: inline-table;
    max-width: 400px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
    margin: 50px 50px 0 50px;
}

#caja-inicio h1, 
#retirar h1 {
    text-shadow: var(--text-shadow);
}

#inicio, #retiro {
  margin: 0 10px 13px 10px;

}

#inicio-div, #retiro-div {
    margin: 10px;
}

#retiro, #inicio {
    width: 130px;
}

#inicio-div, #boton-inicio-div {
    display: inline-table;
    margin-left: 5px;
    margin-right: 5px;
}

#retiro-div, #boton-retiro-div {
    display: inline-table;
    margin-left: 5px;
    margin-right: 5px;
}

#trash-caja {
    width: 20px;
    margin-right: 5px;
    cursor: pointer;
    filter: invert(100%);
}

@media screen and (min-width: 900px) and (max-width: 1500px) {

    #trash-caja {
        width: 15px;
    }
}



@media screen and (max-width: 900px) {
    #gestion-caja-view {
        padding-top: 130px;
    }

    #col-tabla-caja, #col-tabla-cajeros {
        width:90%;
    }

    #tabla-caja, #tabla-cajeros {
        padding: 10px;
    }

    #tabla-caja table thead,
    #tabla-cajeros table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    
    #tabla-caja table tr {
        border-bottom: none;
        display: block;
    }

    #tabla-cajeros table tr {
        border-bottom: 3px solid #ddd;
        display: block;
    }
    
    #tabla-caja table tr td,
    #tabla-cajeros table tr td {
        border-bottom: none;
        display: block;
        text-align: right;
    }
    
    #tabla-caja table td::before,
    #tabla-cajeros table td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }
  
    #tabla-caja tbody,
    #tabla-cajeros tbody {
        display: block;
        overflow-y: scroll;
        max-height: 400px;
    }

    #tabla-caja tbody::-webkit-scrollbar,
    #tabla-caja tbody::-webkit-scrollbar-thumb,
    #tabla-cajeros tbody::-webkit-scrollbar,
    #tabla-cajeros tbody::-webkit-scrollbar-thumb {
        display: none;
    }

    #trash-caja {
        width: 15px;
    }
    
}


</style>