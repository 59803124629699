import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import PortadaView from '../views/PortadaView.vue'
import HomeView from '../views/HomeView.vue'
import ServiciosView from '../views/ServiciosView.vue'
import TurnosView from '../views/TurnosView.vue'
import LogInView from '../views/LogInView.vue'
import GestionHomeView from '../views/GestionHomeView.vue'
import GestionBuscarView from '../views/GestionBuscarView.vue'
import GestionTurnosView from '../views/GestionTurnosView.vue'
import GestionCajaView from '../views/GestionCajaView.vue'
import GestionStockView from '../views/GestionStockView.vue'
import GestionEmpleadosView from '../views/GestionEmpleadosView.vue'
import GestionEstadisticasView from '../views/GestionEstadisticasView.vue'
import GestionServiciosView from '../views/GestionServiciosView.vue'
import GestionConfiguracionView from '../views/GestionConfiguracionView.vue'
import GestionEliminadoView from '../views/GestionEliminadoView.vue'


const routes = [
  {
    path: '/',
    name: 'portada',
    component: PortadaView,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: HomeView,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: ServiciosView,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/turnos',
    name: 'turnos',
    component: TurnosView,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/gestion',
    name: 'gestion',
    component: LogInView,
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/gestion/inicio',
    name: 'gestion-inicio',
    component: GestionHomeView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/gestion/buscar',
    name: 'gestion-buscar',
    component: GestionBuscarView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/gestion/turnos',
    name: 'gestion-turnos',
    component: GestionTurnosView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/gestion/caja',
    name: 'gestion-caja',
    component: GestionCajaView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/gestion/stock',
    name: 'gestion-stock',
    component: GestionStockView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/gestion/empleados',
    name: 'gestion-empleados',
    component: GestionEmpleadosView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/gestion/estadisticas',
    name: 'gestion-estadisticas',
    component: GestionEstadisticasView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/gestion/servicios',
    name: 'gestion-servicios',
    component: GestionServiciosView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/gestion/ajustes-tienda',
    name: 'gestion-ajustes-tienda',
    component: GestionConfiguracionView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/gestion/eliminado',
    name: 'gestion-eliminado',
    component: GestionEliminadoView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/"
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 5)
  }
 }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'inicio'});
  }else if (to.matched.some(record => !record.meta.requireLogin) && store.state.isAuthenticated) {
    next({ name: 'gestion-inicio'});
  }else {
    next()
  }
})

export default router
