<template>
    <div class="gestion-home row">
        <div id="servicio-col">
          <h1>Servicio</h1>
          <form id="form-trabajo" action="" method="post">
              <div id="nombre">
                <div class="field field_v2">
                  <label for="name" class="ha-screen-reader">Nombre</label>
                  <input v-model="trabajo.cliente.nombre"  id="name" class="field__input" placeholder="ej. Lorena" autocomplete="off">
                  <span class="field__label-wrap" aria-hidden="true">
                    <span class="field__label">Nombre</span>
                  </span>
                </div>    
                <span v-if="errors.name"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errors.name }}</p></span>
              </div>

              <div id="apellido">
                <div class="field field_v2">
                  <label for="last-name" class="ha-screen-reader">Apellido</label>
                  <input v-model="trabajo.cliente.apellido" id="last-name"  class="field__input" placeholder="ej. Gonzales" autocomplete="off">
                  <span class="field__label-wrap" aria-hidden="true">
                    <span class="field__label">Apellido</span>
                  </span>
                </div>    
                <span v-if="errors.lastname"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errors.lastname }}</p></span>
              </div>

              <div id="telefono">
                <div class="field field_v2">
                  <label for="phone" class="ha-screen-reader">Telefono</label>
                  <input v-model="trabajo.cliente.telefono" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="phone" class="field__input" placeholder="ej. 114123456" autocomplete="off">
                  <span class="field__label-wrap" aria-hidden="true">
                    <span class="field__label">Telefono</span>
                  </span>
                </div>   
                <span v-if="errors.phone"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errors.phone }}</p></span>
              </div>
           
              <div class="servicio-div">
                <div id="empleado">
                  <select id="employee" class="select-style" @change="handleChange" v-model="empleadoSelect">
                    <option value="" disabled hidden>Emplead@</option>
                    <option v-for="empleado in empleados" :key="empleado" data-info="empleado" :data-posicion="empleado.posicion" :data-id="empleado.id">{{ empleado.nombre }} {{ empleado.apellido }}</option>
                  </select> 
                </div>
                <span v-if="errors.employee"><p class="error-select" style="color: red; height: 0;">{{ errors.employee }}</p></span>
              </div>

              <div class="servicio-div">
                <div id="servicio">
                  <select id="service" class="select-style" @change="handleChange" v-model="servicioSelect">
                    <option value="" disabled hidden>Servicio</option>
                    <option v-for="servicio in servicios" :key="servicio.id" data-info="servicio" :data-id="servicio.id" :data-precio="servicio.precio">{{ servicio.nombre }}, ${{ servicio.precio }}</option>
                  </select>   
                </div>
                <span v-if="errors.service"><p class="error-select" style="color: red; height: 0;">{{ errors.service }}</p></span>
              </div>  

            <form id="form-producto" @submit="checkFormProducto" action="" method="post">
                <div class="producto-div">
                  <div id="producto">
                    <select id="product" class="select-style" @change="handleChange" v-model="productoSelect">
                      <option disabled hidden value="">Producto</option>
                      <option v-for="producto in trabajo.stock" :key="producto.id" :data-unidad="producto.unidad" :data-categoria="producto.categoria" :data-id="producto.id" data-info="producto">{{ producto.producto }}</option>
                    </select>
                  </div>
                  <span v-if="errorsProducto.name"><p class="error-select" style="color: red; height: 0;">{{ errorsProducto.name }}</p></span>
                </div>

                <div id="cantidad">
                  <div class="field field_v2">
                    <label for="quantity" class="ha-screen-reader">Cantidad</label>
                    <input v-model="trabajo.producto.cantidad" id="quantity" class="field__input" placeholder="ej. 100" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    <span class="field__label-wrap" aria-hidden="true">
                      <span class="field__label">Cantidad</span>
                    </span>
                  </div>   
                  <span v-if="errorsProducto.quantity"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorsProducto.quantity }}</p></span>
                </div>
               
                <div id="boton-producto-cantidad">
                  <button id="button">Agregar</button>
                </div> 
            </form>

            <div id="descuento">
              <select id="discount" class="select-style" @change="handleChange" v-model="trabajo.data.descuento">
                <option disabled hidden value="">Desc</option>
                <option v-for="descuento in descuentos" data-info="descuento">{{ descuento }}%</option>
              </select>
            </div>

            <div id="tarjeta-servicio">
              <input id="card" v-model="trabajo.data.tarjeta" type="checkbox" name="card">
              Tarjeta      
            </div>

          </form>   
        </div>
        
          <TotalColumn 
            id="total-col-medium"
            :trabajo="trabajo" 
            :tienda="tienda" 
            :showPrice="showPrice"
            :showTotal="showTotal"
            :showPriceTienda="showPriceTienda"
            :showTotalTienda="showTotalTienda"
            :sumaTotal="sumaTotal"
            :remove="remove"
            :check="checkFormTrabajo"
          />

        <div id="tienda-col">
          <h1>Tienda</h1>
          <form id="form-tienda" @submit="checkFormTienda" action="" method="post">
              <div id="producto-tienda-div">
                <div id="producto-tienda">
                  <select id="product-sell" class="select-style" @change="handleChange" v-model="productoTiendaSelect">
                    <option value="" disabled hidden>Producto</option>
                    <option v-for="producto in tienda.stock" :key="producto.id" :data-unidad="producto.unidad" :data-precio="producto.precio" :data-categoria="producto.categoria" :data-id="producto.id" data-info="producto-tienda">{{ producto.producto }}</option>
                  </select>
                </div>
                <span v-if="errorsTienda.product"><p class="error-select" style="color: red; height: 0;">{{ errorsTienda.product }}</p></span>
              </div>
              <div id="cantidad-tienda">
                <div class="field field_v2">
                  <label for="quantity-sell" class="ha-screen-reader">Cantidad</label>
                  <input id="quantity-sell" v-model="tienda.cantidad" class="field__input" placeholder="ej. 10" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                  <span class="field__label-wrap" aria-hidden="true">
                    <span class="field__label">Cantidad</span>
                  </span>
                </div>   
                <span v-if="errorsTienda.quantity"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorsTienda.quantity}}</p></span>
              </div>

              <div id="boton-tienda">
                <button id="button-sell">AGREGAR</button>
              </div>
              <br>

              <div id="descuento-tienda">
                <select id="discount" class="select-style" @change="handleChange" v-model="tienda.descuento">
                  <option disabled hidden value="">Desc</option>
                  <option v-for="descuento in descuentos" :key="descuento" data-info="descuento-tienda">{{ descuento }}%</option>
                </select>
              </div>

              <div id="tarjeta-tienda">
                <input id="card" v-model="tienda.tarjeta" type="checkbox" name="card">
                Tarjeta
              </div>
          </form>
        </div>

        
          <TotalColumn 
            id="total-col-bottom"
            :trabajo="trabajo" 
            :tienda="tienda" 
            :showPrice="showPrice"
            :showTotal="showTotal"
            :showPriceTienda="showPriceTienda"
            :showTotalTienda="showTotalTienda"
            :sumaTotal="sumaTotal"
            :remove="remove"
            :check="checkFormTrabajo"
          />
        

        <div v-if="sumaTotal > 0" id="total-pagar">
          <div >
            <h1>TOTAL: ${{ sumaTotal }}</h1>
          </div>
          <div id="boton-guardar">
            <button id="button-add" @click="checkFormTrabajo">GUARDAR</button>
          </div>
        </div>
    </div>
    
</template>

<script>
import axios from 'axios'
import TotalColumn from '@/components/TotalColumn.vue';

export default {
  data() {
    return {
      totalPagar:0,
      errors: {
        name:'',
        lastname:'',
        phone:'',
        employee:'',
        service:'',
      },
      errorsProducto:{
        name:'',
        quantity:''
      },
      servicioSelect: '',
      empleadoSelect: '',
      productoSelect: '',
      servicioProducto:'',
      productoTiendaSelect: '',
      showPrice:false,
      showTotal:false,
      showPriceTienda:false,
      showTotalTienda:false,
      errorsTienda:{
        product:'',
        quantity:''
      },
      servicios:[],
      empleados:[],
      tarjeta:false,
      subtotal:0,
      total:0,
      descuentos:['0', '5', '10', '15', '20', '25', '30' ,'35', '40', '45', '50', 
                  '55', '60', '65', '70', '75', '80', '85', '90', '95', '100'],   
      tienda: {
        stock:[],
        productos:[],
        producto:null,
        cantidad:null,
        unidad:null,
        id:null,
        categoria:null,
        precio:null,
        descuento:'',
        tarjeta:false,
        subtotal:0,
        total:0
      },
      trabajo:{
        cliente:{
          nombre:null,
          apellido:null,
          telefono:null
        },
        servicios:[],
        stock:[],
        productos:[],
        producto:{ 
          nombre:null,
          cantidad:null,
          unidad:null,
          id:null,
          categoria:null,
        },
        data:{
          servicio:null,
          empleado:null,
          descuento:'',
          precio:null,
          tarjeta:false,
          subtotal:0,
          total:0
        }
      },
    }
  },
  components: {
    'TotalColumn': TotalColumn
  },
  mounted() {
    document.title = 'Inicio | Tijeras Gestion'
    this.getServicios();
    this.getEmpleados();
    this.getStock();
    this.setBgBody();
  },
  methods: {
    setBgBody() {
      var store = document.querySelector(':root');
      store.style.setProperty('--bg-body', "var(--bg-body-gestion)");
      document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
    },
    async getServicios() {
      this.$store.commit('setIsLoading', true)
      await axios
        .get('/api/v1/servicios/')
        .then(response => {
         this.servicios = response.data
        })
        .catch(error => {
          if (error.response.status === 401){
            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            this.$store.commit('removeToken')
            this.$router.push('/gestion')
          }
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    async getEmpleados() {
      this.$store.commit('setIsLoading', true)
      await axios
        .get('/api/v1/empleados/')
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            if(response.data[i]['posicion'] != 'ADMIN' && response.data[i]['posicion'] != 'CAJER@') {
              this.empleados.push(response.data[i])
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    async getStock() {
      this.$store.commit('setIsLoading', true)
      await axios
        .get('/api/v1/stock/')
        .then(response => {
          for (let i = 0; i < response.data.length; i++) {
            if(response.data[i]['categoria'] != 'TIENDA') {
              this.trabajo.stock.push(response.data[i])
            }else {
              this.tienda.stock.push(response.data[i])
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
      this.$store.commit('setIsLoading', false)
    },
    handleChange(e) {
      if(e.target.options.selectedIndex > -1) {
        if(e.target.options[e.target.options.selectedIndex].dataset.info === "servicio"){
          this.trabajo.data.servicio = e.target.value
          if (this.trabajo.servicios.some(x => x.nombre === e.target.value)) {
            this.servicioSelect = ''
          }
          else {
            this.trabajo.servicios.push(
              {
                nombre: e.target.value,
                id: e.target.options[e.target.options.selectedIndex].dataset.id,
                precio: e.target.options[e.target.options.selectedIndex].dataset.precio,
                productos: []
              }
            )
            this.servicioSelect = ''
            this.trabajo.data.descuento = ''
            this.trabajo.data.precio = e.target.options[e.target.options.selectedIndex].dataset.precio
            this.trabajo.data.subtotal += parseFloat(e.target.options[e.target.options.selectedIndex].dataset.precio)
            this.subtotal += parseFloat(e.target.options[e.target.options.selectedIndex].dataset.precio)       
            this.showPrice = true
            this.showTotal = false
            this.trabajo.data.total = 0
          }
        }
        if(e.target.options[e.target.options.selectedIndex].dataset.info === "empleado"){
          this.trabajo.data.empleado = e.target.options[e.target.options.selectedIndex].dataset.id 
        }
        if(e.target.options[e.target.options.selectedIndex].dataset.info === "producto"){
          this.trabajo.producto.unidad = e.target.options[e.target.options.selectedIndex].dataset.unidad
          this.trabajo.producto.id = e.target.options[e.target.options.selectedIndex].dataset.id
          this.trabajo.producto.categoria = e.target.options[e.target.options.selectedIndex].dataset.categoria
          this.trabajo.producto.nombre = e.target.value
        }
        if(e.target.options[e.target.options.selectedIndex].dataset.info === "descuento"){
          if (this.trabajo.data.precio != null){
            this.trabajo.data.descuento  = e.target.value
            let percentage = e.target.value.replace('%', '')
            let discount = parseFloat(this.trabajo.data.subtotal) * parseFloat(percentage) / 100
            const total = parseFloat(this.trabajo.data.subtotal) - discount
            this.trabajo.data.total = total
            this.total = total
            this.showPrice = false
            this.showTotal = true
          }
        }
        if(e.target.options[e.target.options.selectedIndex].dataset.info === "producto-tienda"){
          this.tienda.unidad = e.target.options[e.target.options.selectedIndex].dataset.unidad
          this.tienda.id = e.target.options[e.target.options.selectedIndex].dataset.id
          this.tienda.categoria = e.target.options[e.target.options.selectedIndex].dataset.categoria
          this.tienda.precio = e.target.options[e.target.options.selectedIndex].dataset.precio
          this.tienda.producto = e.target.value
        }
        if(e.target.options[e.target.options.selectedIndex].dataset.info === "descuento-tienda"){
          if (this.tienda.precio != null){
            this.tienda.descuento  = e.target.value
            let percentage = e.target.value.replace('%', '')
            let discount = parseFloat(this.tienda.subtotal) * parseFloat(percentage) / 100
            const total = parseFloat(this.tienda.subtotal) - discount
            this.tienda.total = total
            this.total += total
            this.showPriceTienda = false
            this.showTotalTienda = true
          }
        }
      }
    },
    checkFormProducto: async function (e) {
      this.errorsProducto = {
        name:'',
        quantity:''
      }

      if (!this.trabajo.producto.nombre) {
        this.errorsProducto.name = 'Requerido'
        setTimeout(() => this.errorsProducto = '', 2000)
      }
      if (!this.trabajo.producto.cantidad) {
        this.errorsProducto.quantity = 'Requerida'
        setTimeout(() => this.errorsProducto = '', 2000)
      }
      
      e.preventDefault();

      if (!this.errorsProducto.name  && !this.errorsProducto.quantity) {
        if (this.trabajo.servicios.length) {
          for (let i = 0; i < this.trabajo.servicios.length; i++) {
            if(this.trabajo.servicios[i].nombre == this.trabajo.data.servicio) {
              if (this.trabajo.servicios[i].productos.some(e => e.producto === this.trabajo.producto.nombre)) {
                for (let x = 0; x < this.trabajo.servicios[i].productos.length; x++) {  
                  if(this.trabajo.servicios[i].productos[x].producto == this.trabajo.producto.nombre) {
                    this.trabajo.servicios[i].productos[x]['cantidad'] += parseFloat(this.trabajo.producto.cantidad) 
                    break
                  }            
                }    
              }else {
                this.trabajo.servicios[i].productos.push(
                  {
                    producto: this.trabajo.producto.nombre,
                    cantidad: parseFloat(this.trabajo.producto.cantidad),
                    categoria: this.trabajo.producto.categoria,
                    unidad: this.trabajo.producto.unidad,
                    id: this.trabajo.producto.id    
                  }
                )
              }
            }
          }
        }
        this.productoSelect = ''
        this.trabajo.producto = { 
          nombre:null,
          cantidad:null,
          unidad:null,
          id:null,
          categoria:null,
        }
      }
    },
    checkFormTrabajo: async function (e) {
      this.errors={
        name:'',
        lastname:'',
        phone:'',
        employee:'',
        service:''
      }

      const data = {
        trabajo: {
          cliente: this.trabajo.cliente,
          data: this.trabajo.data,
          servicios: this.trabajo.servicios
        },
        tienda: {
          productos: this.tienda.productos,
          descuento: this.tienda.descuento,
          tarjeta: this.tienda.tarjeta,
          subtotal: this.tienda.subtotal,
          total: this.tienda.total
        }
      }

      if (this.tienda.productos.length > 0 && this.trabajo.servicios.length == 0) {
        await axios
          .post('/api/v1/trabajos/guardar/', data)
          .then(response => {
            if(response.data == 'tienda guardada') {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'VENTA GUARDADA',
                showConfirmButton: false,
                timer: 2000
              })
              this.getStock()
              this.productoTiendaSelect = '',
              this.productoTiendaSelect = '',
              this.showPriceTienda = false,
              this.showPriceTienda = false,
              this.total = 0,
              this.subtotal = 0,
              this.tienda = {
                stock:[],
                productos:[],
                producto:null,
                cantidad:null,
                unidad:null,
                id:null,
                categoria:null,
                precio:null,
                descuento:'',
                tarjeta:false,
                subtotal:0,
                total:0
              }
            }
          })
          .catch(error => {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'LA VENTA NO SE PUDO GUARDAR.\nINTENTA NUEVAMENTE.',
              showConfirmButton: false,
              timer: 3000
            })
              console.log(error)
          })  

      }else {
        if (!this.trabajo.cliente.nombre) {
          this.errors.name = 'Requerido'
          setTimeout(() => this.errors.name = '', 2000)
        }
        if (!this.trabajo.cliente.apellido) {
          this.errors.lastname = 'Requerido'
          setTimeout(() => this.errors.lastname = '', 2000)
        }
        if (!this.trabajo.cliente.telefono) {
          this.errors.phone = 'Requerido'
          setTimeout(() => this.errors.phone = '', 2000)
        }
        if (!this.trabajo.servicios.length) {
          this.errors.service = 'Requerido'
          setTimeout(() => this.errors.service = '', 2000)
        }
        if (!this.trabajo.data.empleado) {
          this.errors.employee = 'Requerid@'
          setTimeout(() => this.errors.employee = '', 2000)
        }

        e.preventDefault();

        if (!this.errors.name && !this.errors.lastname && !this.errors.phone && !this.errors.employee && !this.errors.service) {     
          await axios
            .post('/api/v1/trabajos/guardar/', data)
            .then(response => {
              if(response.data == 'trabajo guardado') {
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'SERVICIO GUARDADO',
                  showConfirmButton: false,
                  timer: 3000
                })
                this.servicioSelect = '',
                this.empleadoSelect = '',
                this.productoSelect = '',
                this.total = 0,
                this.subtotal = 0,
                this.trabajo.cliente = {
                  nombre: null,
                  apellido: null,
                  telefono: null
                },
                this.trabajo.producto = { 
                  nombre: null,
                  cantidad: null,
                  unidad: null,
                  id: null,
                  categoria: null,
                },
                this.trabajo.data = {
                  servicio: null,
                  empleado: null,
                  descuento: '',
                  precio: null,
                  tarjeta: false,
                  subtotal:0,
                  total: 0
                }
              }
            })
            .catch(error => {
              Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'EL SERVICIO NO SE PUDO GUARDAR.\nINTENTA NUEVAMENTE.',
              showConfirmButton: false,
              timer: 3000
            })
                console.log(error)
            })  
        }
      }
    },
    checkFormTienda: async function (e) {
      this.errorsTienda = {
        product:'',
        quantity: ''
      };

      if (!this.tienda.producto) {
        this.errorsTienda.product = 'Requerido'
        setTimeout(() => this.errorsTienda.product = '', 2000)
      }
      if (!this.tienda.cantidad) {
        this.errorsTienda.quantity = 'Requerida'
        setTimeout(() => this.errorsTienda.quantity = '', 2000)
      }
      
      e.preventDefault();

      if (!this.errorsTienda.product && !this.errorsTienda.quantity) {
        this.tienda.subtotal = 0
        if (this.tienda.productos.some(e => e.producto === this.tienda.producto)) {
          for (let i = 0; i < this.tienda.productos.length; i++) {
            if(this.tienda.productos[i]['producto'] == this.tienda.producto) {
              this.tienda.productos[i]['cantidad'] += parseFloat(this.tienda.cantidad) 
              this.tienda.productos[i]['total'] = parseFloat(this.tienda.precio) * this.tienda.productos[i]['cantidad']
            }
          }
        } else {
          this.tienda.productos.push({
            producto: this.tienda.producto,
            cantidad: parseFloat(this.tienda.cantidad),
            precio: this.tienda.precio,
            total: parseFloat(this.tienda.cantidad) * parseFloat(this.tienda.precio),
            categoria: this.tienda.categoria,
            unidad: this.tienda.unidad,
            id: this.tienda.id
          })
        }
        this.tienda.subtotal = 0
        for (let i = 0; i < this.tienda.productos.length; i++) {
          this.tienda.subtotal += this.tienda.productos[i]['total']

        }
        this.subtotal = this.trabajo.data.subtotal
        this.subtotal += parseFloat(this.tienda.subtotal)
        this.showTotalTienda = false
        this.tienda.descuento = ''
        this.showPriceTienda = true
        this.productoTiendaSelect = ''
        this.tienda.cantidad = null
        this.tienda.producto = ''
      }
    },
    resetPrecio() {
      this.showTotal = false
      this.showPrice = true
      this.trabajo.data.total = 0
      this.trabajo.data.descuento = ''
    },
    remove(e) {
      if (e.target.dataset.info == 'tienda') {
        for (let i = 0; i < this.tienda.productos.length; i++) {
          if(this.tienda.productos[i]['id'] == e.target.dataset.id) {
            this.tienda.subtotal -= parseFloat(this.tienda.productos[i]['total'])
            this.subtotal -= parseFloat(this.tienda.productos[i]['total'])
            this.tienda.productos = this.tienda.productos.filter((item) => item.id !== e.target.dataset.id);
            this.tienda.descuento = ''  
            this.tienda.total = 0
            this.total = this.trabajo.data.total         
            this.showTotalTienda = false
            if (this.tienda.productos.length) {
              this.showPriceTienda = true 
            }else{
              this.showPriceTienda = false
            }    
          }
        }
      }
      if (e.target.dataset.info == 'producto') {  
        for (let i = 0; i < this.trabajo.servicios.length; i++) {
          for (let x = 0; x < this.trabajo.servicios[i].productos.length; x++) {
            if(this.trabajo.servicios[i].productos[x].id == e.target.dataset.id) {
              this.trabajo.servicios[i].productos = this.trabajo.servicios[i].productos.filter((item) => item.id !== e.target.dataset.id);
            }
          }
        }
      }
      if (e.target.dataset.info == 'servicio') {  
        for (let i = 0; i < this.trabajo.servicios.length; i++) {
          if(this.trabajo.servicios[i]['id'] == e.target.dataset.id) {
            this.trabajo.data.subtotal -= parseFloat(this.trabajo.servicios[i]['precio'])
            this.subtotal -= parseFloat(this.trabajo.servicios[i]['precio'])
            this.trabajo.servicios = this.trabajo.servicios.filter((item) => item.id !== e.target.dataset.id);
            this.trabajo.data.total = 0
            this.total = this.tienda.total
            this.trabajo.data.descuento = ''
            this.showTotal = false
            this.showPrice = true
            if (this.trabajo.servicios.length) {
              this.showPrice = true 
            }else{
              this.showPrice= false
            }    
          }
        }
        
      }
    },
  },
  computed: {
    sumaTotal() {
      let t = 0
      if (this.total) {
        if (this.trabajo.data.total == 0) {
          return this.tienda.total + this.trabajo.data.subtotal
        }else if (this.tienda.total == 0) {
          return this.trabajo.data.total + this.tienda.subtotal
        }else {
          return this.total
        }
      }else {
        return this.subtotal
      }
    }
  }
}


</script>

<style>

*:focus {
  border-color: #ced4da00; /* default B5 color or set your own color*/
  border: none !important; /* if you want to remove borders at all*/
  outline: none !important;
  box-shadow: none !important;
}

.gestion-home {
  padding-top: 180px;  
  text-align: center;
  min-height: 700px;
}

#servicio-col, #tienda-col, #total-col-medium{
  display: inline-table;
  margin-left:auto;
  margin-right: auto;

}

#servicio-col {
  width: 500px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 33px 22px 0px #000000;
  color: white;
  background: var(--bg-box-black);
  background-position: center center;
  background-size: cover;
}

#tienda-col {
  width: 500px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 33px 22px 0px #000000;
  color: white;
  background: var(--bg-box-black);
  background-position: center center;
  background-size: cover;
}


#servicio-col h1, #tienda-col h1 {
  text-shadow: var(--text-shadow);
}

#total-col-medium {
  max-width: 500px;
  margin-left: 30px;
  margin-right: 30px;
}

#total-col-bottom {
  display: none;
}

#nombre, #apellido, #telefono {
  display: inline-table;
  margin: 10px 10px 0 10px;
}

#nombre input, #apellido input, #telefono input {
  width: 130px;
  margin-bottom: 13px;
}

.empleado-div, .servicio-div {
  display: inline-table;
  margin: 20px 10px 0 10px;
}

#service, #employee, #product, #product-sell{
  width: 130px;
  height: 38px;
  cursor: pointer;
}

.producto-div, #cantidad, #boton-producto-cantidad {
  display: inline-table;
  margin: 20px 10px 0 10px;
}

#cantidad input, #quantity-sell {
  width: 110px;
  margin-bottom: 13px;
}

#agregar-icon {
  width: 30px;
}

#button , #button-sell{
  background-color: var(--button-color);
  border: none;
  color: rgb(255, 255, 255);
  padding: 0 8px;
}

#descuento, #tarjeta-servicio {
  display: inline-table;
  margin: 20px;
}

#discount {
  cursor: pointer;
}

#descuento-tarjeta-div {
  padding-top: 20px;
  padding-bottom: 40px;
}

#card:not(:checked){opacity:0.7;};

.error-select {
  color: red !important;
}

#trash {
  width: 15px;
  cursor: pointer;
}

#boton-guardar {
  margin-top: 30px;
}

#boton-guardar-medio, #total-pagar-medio {
  display: none;
}

#producto-tienda-div, #cantidad-tienda, #boton-tienda {
  display: inline-table;
  margin: 10px 10px 0 10px;
}

#descuento-tarjeta-tienda-div {
  padding-top: 20px;
  padding-bottom: 40px;
}

#descuento-tienda, #tarjeta-tienda {
  display: inline-table;
  margin: 20px;
}

#button-add {
  width: 50%;
  height: 50px;
  background-color: var(--button-color);
  border: none;
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 18px;
  box-shadow: 0px 33px 22px 0px #000000;
}

#total-pagar {
  margin-top: 20px;
}

#total-pagar h1 {
  color: #ff3bff;
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-10&color=000000);
  border-radius: 4px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1130px) and (max-width: 1550px) {
  #total-col-medium {
    display: none;
  }

  #total-col-bottom {
    display: inherit;
  }

  #servicio-col, #tienda-col {
    display: inline-table;
    margin-left: 20px;
    margin-right: 20px;
  }

}

@media screen and (min-width: 900px) and (max-width: 1130px) {

  #servicio-col, #tienda-col,  #total-col {
    display: inherit;
  }

  #servicio-col h1, #tienda-col h1 {
    padding-top: 20px;
  }

  #total-col-medium {
    margin-top: 50px;
  }

  #boton-guardar-medio, #total-pagar-medio {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  #afip-div {
    margin-top: 40px;
  }

  #afip-div a{
    padding: 10px 10px 10px 10px;
  }

  #button-add {
    margin-top: 20px;
  }

  #boton-guardar, #total-pagar {
    display: none;
  }

  #trash-home {
    width: 15px;
  }

}

@media only screen and (max-width: 900px) {
  .upper {
    display: block;
  }

  .lower {
    display: none;
  }

  .gestion-home {
    padding-top: 130px;  
  }

  #servicio-col, #tienda-col {
    max-width: 90%;
  }
  
  #servicio-col, #tienda-col,  #total-col {
    display: inherit;
  }

  #servicio-col h1, #tienda-col h1 {
    padding-top: 20px;
  }

  #total-col-medium {
    margin-top: 50px;
  }

  #button-add {
    width: 100%;
  }

  #boton-guardar-medio, #total-pagar-medio {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }

  #afip-div {
    margin-top: 40px;
  }

  #afip-div a{
    padding: 10px 10px 10px 10px;
  }

  #button-add {
    margin-top: 20px;
  }

  #boton-guardar, #total-pagar {
    display: none;
  }

  #trash-home {
    width: 15px;
  }
  
}

@media only screen and (max-width: 335px) {
  #nombre input, #apellido input, #telefono input,
  #service, #employee, #product, #product-sell {
    width: 120px;
  }
}

@media only screen and (max-width: 312px) {
  #nombre input, #apellido input, #telefono input,
  #service, #employee, #product, #product-sell {
    width: 110px;
  }
}



/*
=====
HELPERS
=====
*/

.ha-screen-reader{
  width: var(--ha-screen-reader-width, 1px);
  height: var(--ha-screen-reader-height, 1px);
  padding: var(--ha-screen-reader-padding, 0);
  border: var(--ha-screen-reader-border, none);

  position: var(--ha-screen-reader-position, absolute);
  clip: var(--ha-screen-reader-clip, rect(1px, 1px, 1px, 1px));
  overflow: var(--ha-screen-reader-overflow, hidden);
}

/*
=====
RESET STYLES
=====
*/

.field__input{ 
  --uiFieldPlaceholderColor: var(--fieldPlaceholderColor, #767676);
  
  background-color: transparent;
  border-radius: 0;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  font-family: inherit;
  font-size: inherit;
}

.field__input:focus::-webkit-input-placeholder{
  color: var(--uiFieldPlaceholderColor);
}

.field__input:focus::-moz-placeholder{
  color: var(--uiFieldPlaceholderColor);
}

/*
=====
CORE STYLES
=====
*/

.field{
  --uiFieldBorderWidth: var(--fieldBorderWidth, 2px);
  --uiFieldPaddingRight: var(--fieldPaddingRight, 1rem);
  --uiFieldPaddingLeft: var(--fieldPaddingLeft, 1rem);   
  --uiFieldBorderColorActive: var(--fieldBorderColorActive, rgba(22, 22, 22, 1));

  display: var(--fieldDisplay, inline-flex);
  position: relative;
  font-size: var(--fieldFontSize, 1rem);
}

.field__input{
  box-sizing: border-box;
  width: var(--fieldWidth, 100%);
  height: var(--fieldHeight, 3rem);
  padding: var(--fieldPaddingTop, 1.25rem) var(--uiFieldPaddingRight) var(--fieldPaddingBottom, .5rem) var(--uiFieldPaddingLeft);
  border-bottom: var(--uiFieldBorderWidth) solid var(--fieldBorderColor, rgba(0, 0, 0, .25));  
}

.field__input:focus{
  outline: none;
}

.field__input::-webkit-input-placeholder{
  opacity: 0;
  transition: opacity .2s ease-out;
}

.field__input::-moz-placeholder{
  opacity: 0;
  transition: opacity .2s ease-out;
}

.field__input:focus::-webkit-input-placeholder{
  opacity: 1;
  transition-delay: .2s;
}

.field__input:focus::-moz-placeholder{
  opacity: 1;
  transition-delay: .2s;
}

.field__label-wrap{
  box-sizing: border-box;
  pointer-events: none;
  cursor: text;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.field__label-wrap::after{
  content: "";
  box-sizing: border-box;
  width: 100%;
  height: 0;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 25px;
}

.field__input:focus ~ .field__label-wrap::after{
  opacity: 1;
}

.field__label{
  position: absolute;
  left: var(--uiFieldPaddingLeft);
  top: calc(50% - .5em);
  line-height: 1;
  font-size: var(--fieldHintFontSize, inherit);
  transition: top .2s cubic-bezier(0.9, -0.15, 0.1, 1.15), opacity .2s ease-out, font-size .2s ease-out;
}

.field__input:focus ~ .field__label-wrap .field__label,
.field__input:not(:placeholder-shown) ~ .field__label-wrap .field__label{
  --fieldHintFontSize: var(--fieldHintFontSizeFocused, .75rem);
  top: var(--fieldHintTopHover, .25rem);
}

/* 
effect 2
*/

.field_v2 .field__label-wrap{
  overflow: hidden;
  color: white;
}

.field_v2 .field__label-wrap::after{
  border-bottom: var(--uiFieldBorderWidth) solid var(--uiFieldBorderColorActive);
  margin-bottom: 12px;
}

.field_v2 .field__input:focus ~ .field__label-wrap::after{
  transform: translate3d(0, 0, 0);
  transition-delay: 0;
}


/*
=====
LEVEL 4. SETTINGS
=====
*/

.select-style {
  background-color: transparent;
  border: none;
  margin-bottom: 1px;
  padding-left: 14px;
}

#producto, #empleado, #servicio, #descuento, 
#producto-tienda, #descuento-tienda {
  border-bottom: 2px solid var(--fieldBorderColor);
}

.select-style option {
  background-color: #767676;
}


</style>