<template>
    <div class="modal-overlay" @click="this.$emit('close-modal')">
        <div class="modal" @click.stop>
            <h1 id="titulo-modificar-empleado">EDITAR DATOS EMPLEADO</h1>

            <div id="nombre-empleado-div">
                <div class="field field_v2">
                    <label for="nombre-empleado" class="ha-screen-reader">Nombre</label>
                    <input v-model="empleado.nombre" id="nombre-empleado" class="field__input" placeholder="ej. Lorena" autocomplete="off">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Nombre</span>
                    </span>
                </div>    
                <span v-if="errorNombre"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorNombre }}</p></span>
            </div>

            <div id="apellido-empleado-div">
                <div class="field field_v2">
                    <label for="apellido-empleado" class="ha-screen-reader">Apellido</label>
                    <input v-model="empleado.apellido" id="apellido-empleado" class="field__input" placeholder="ej. Gonzales" autocomplete="off">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Apellido</span>
                    </span>
                </div>    
                <span v-if="errorApellido"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorApellido }}</p></span>
            </div>

            <div id="dni-empleado-div">
                <div class="field field_v2">
                    <label for="dni-empleado" class="ha-screen-reader">Dni</label>
                    <input v-model="empleado.dni" id="dni-empleado" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="field__input" placeholder="ej. 20123456" autocomplete="off">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Dni</span>
                    </span>
                </div>    
                <span v-if="errorDni"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorDni }}</p></span>         
            </div>

            <div id="telefono-empleado-div">
                <div class="field field_v2">
                    <label for="telefono-empleado" class="ha-screen-reader">Telefono</label>
                    <input v-model="empleado.telefono" id="telefono-empleado" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="field__input" placeholder="ej. 114123456" autocomplete="off">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Telefono</span>
                    </span>
                </div>   
                <span v-if="errorTelefono"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorTelefono }}</p></span>
            </div>

            <div id="direccion-empleado-div">
                <div class="field field_v2">
                    <label for="direccion-empleado" class="ha-screen-reader">Direccion</label>
                    <input v-model="empleado.direccion" id="direccion-empleado" class="field__input" placeholder="ej. Salta 1234" autocomplete="off">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Direccion</span>
                    </span>
                </div>   
                <span v-if="errorDireccion"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorDireccion }}</p></span>
            </div>

            <div id="posicion-empleado-div">
                <div class="field field_v2">
                    <label for="posicion-empleado" class="ha-screen-reader">Posicion</label>
                    <input v-model="posicionEmpleadoSelect" id="posicion-empleado" class="field__input" :placeholder="posicionEmpleadoSelect" autocomplete="off" readonly>
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Posicion</span>
                    </span>
                </div> 
            </div>

            <div id="servicios-empleado-div" v-if="posicionEmpleadoSelect != 'ADMIN' && posicionEmpleadoSelect != 'CAJER@'">
                <label id="label-servicios-title" for="servicio-empleado">SERVICIOS</label>
                <div v-for="servicio in servicios" :key="servicio.id" id="servicio-empleado">
                    <label id="label-servicio-nombre" for="servicio-checkbox">{{ servicio.nombre }}</label>
                    <input type="checkbox" id='servicio-checkbox' :value="servicio.id" v-model="serviciosList">   
                </div> 
            </div>

            <label id="label-no-trabaja-empleados" for="dia-div-settings">NO TRABAJA</label>
            <div v-for="dia in dias" :key="dia" id="dia-div-settings">
                <label for="dia">{{ dia.nombre }}</label>
                <input type="checkbox" id='dia' :value="dia.numero" v-model="empleado.noTrabaja">   
            </div> 

            <div id="fecha-vacaciones">
                <div id="vacaciones-div">
                    <div id="vacaciones-settings-div" v-if="vacaciones.length > 0" v-for="vacacion in vacaciones" :key="vacacion.id">
                        <img id="trash-empleado-settings" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarVacacion" title="Eliminar" data-info="vacacion" :data-id="vacacion.id">
                        {{ vacacion.fecha }}
                    </div>
                </div>
                <div id="fecha-settings-div">
                    <Datepicker
                        dark
                        range 
                        hide-input-icon
                        auto-apply
                        placeholder="Vaciones rango"
                        v-model="empleado.vacaciones"
                        :enable-time-picker="false"
                        format="dd/MM/yyyy"
                        locale="es" 
                        cancelText="Cancelar" 
                        selectText="Seleccionar" 
                        id="fecha-setting"
                        position="center"
                        :year-range="[new Date().getFullYear()]"
                        @cleared="limpiar"
                    />
                </div>
            </div>

            <div id="boton-actualizar-cancelar-empleado">
                <button id="button-empleado-actualizar" @click="actualizarEmpleado" data-info="actualizar-empleado">ACTUALIZAR</button>
                <button id="button-cancelar-actualizar" @click="this.$emit('close-modal')" data-info="cancelar-actualizar">CERRAR</button>
            </div>

        </div>

    </div>
</template>


<script>
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
    name: 'SettingsEmpleadoModal',
    props: {
        dias:Array, 
        posiciones:Array,
        trigger:String,
        servicios:Array
    },
    data() {
        return {
            vacaciones:[],
            empleado: {
                id:'',
                nombre:'',
                apellido:'',
                dni:'',
                telefono:'',
                direccion:'',
                posicionId:'',
                noTrabaja:[],
                vacaciones:[]
            },
			errorNombre: '',
            errorApellido: '',
            errorDni:'',
            errorTelefono:'',
            errorDireccion:'',
            errorPosicion:'',
            posicionEmpleadoSelect:'',
            serviciosList:[]
        }
    },
    components: { 
        Datepicker
    },
    mounted() {
        
    },
    watch: { 
        trigger: function(newVal, oldVal) {
            if(newVal) {
                this.empleado.id = newVal
                this.getDatosEmpleado()
                this.getServiciosEmpleado()
            } 
        }
    },
    methods: {  
        limpiar() {
            //console.log('limpio')
        },     
        async getServiciosEmpleado() {
            const data = {
                id:this.empleado.id
            }
            this.serviciosList = []
            await axios
                .post('/api/v1/servicios/empleado', data)
                .then(response => {
                    if (response.data.length > 0) {
                        for (let i = 0;response.data.length > i; i++) {
                            this.serviciosList.push(response.data[i]['servicio'])
                        }
                        
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getDatosEmpleado() {
            const data = {
                id:this.empleado.id
            }

            await axios
                .post('/api/v1/empleados/settings/', data)
                .then(response => {                   
                    this.empleado = {
                        id:data.id,
                        nombre:response.data[0]['empleado']['nombre'],
                        apellido:response.data[0]['empleado']['apellido'],
                        dni:response.data[0]['empleado']['dni'],
                        telefono:response.data[0]['empleado']['telefono'],
                        direccion:response.data[0]['empleado']['direccion'],
                        posicionId:response.data[0]['empleado']['get_posicion_id'],
                        noTrabaja:response.data[0]['empleado']['get_no_trabaja'],
                    }
                    this.posicionEmpleadoSelect = response.data[0]['empleado']['posicion']
                    this.vacaciones = response.data[0]['vacaciones']
                })
                .catch(error => {
                    console.log(error)
                })

            console.log('empleado data', this.empleado)
        },
        async actualizarEmpleado(e) {
            let vacaciones = this.empleado.vacaciones
            if (this.empleado.vacaciones == undefined) {
                vacaciones = []
            }
            const data = {
                empleado:this.empleado,
                vacaciones:vacaciones,
                servicios:this.serviciosList
            }

            await axios
                .post('/api/v1/empleados/settings/actualizar/', data)
                .then(response => {
                    this.getDatosEmpleado()
                    this.$emit('empleados')
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async eliminarVacacion(e) {
            const data = {
                id:e.target.dataset.id
            }

            await axios
                .post('/api/v1/empleados/settings/eliminar-vacacion/', data)
                .then(response => {
                    this.getDatosEmpleado()
                    this.$emit('empleados')
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style scoped>

.modal-overlay {
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;

 
}
.modal {
    text-align: center;
    color: white;
    background: var(--bg-grey);
    background-position: center center;
    background-size: cover;
    max-height: 100%;
    width: 32em;
    border-radius: 4px;
}

#titulo-modificar-empleado {
    color: white;
    margin-top: 20px;
    text-shadow: var(--text-shadow)
}

.modal .field__input:focus::-webkit-input-placeholder{
  opacity: 1;
  transition-delay: .2s;
  color: #000000;
}

#posicion-empleado {
    margin-bottom: 13px;
}

#label-no-trabaja-empleados {
    margin: 10px auto 10px auto;
    text-shadow: var(--text-shadow)
}

#vacaciones-div {
    margin: 10px auto -10px auto;
}

#fecha-setting{
    background-color: transparent;
    border-bottom: 2px solid var(--fieldBorderColor);
    width: 300px;
    margin: 20px auto 40px auto;
}

#dia-div-settings {
    display: inline-table;
    margin-left: 5px;
    margin-right: 5px;
}

#button-empleado-actualizar {
    background-color: rgb(39, 159, 4);
    border: none;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1em;
    font-weight: 500;
}

#button-empleado-actualizar:hover {
    background-color: rgb(32, 131, 2);
}

#button-cancelar-actualizar {
    background-color: rgb(221, 51, 51);
    border: none;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1em;
    font-weight: 500;
}

#button-cancelar-actualizar:hover {
    background-color: rgb(175, 42, 42);
}

#boton-actualizar-cancelar-empleado {
    margin-bottom: 10px;
}

select {
    padding: 2px 10px;
}

#trash-empleado-settings {
    width: 18px;
    filter: invert(100%);
}

@media screen and (max-width: 900px) {
    .modal {
        width: 90%;
    }
}


</style>
