<template>
    <div id="grafico-caja-mensual">
        <h1>MENSUAL</h1>
        <div id="year-mensual-div">
            <select id="year-mensual" class="select-style" @change="handleChange" v-model="yearSelect">
                <option value="" disabled hidden>Año</option>
                <option v-for="year in years" :key="year" data-info="year">{{ year }}</option>
            </select> 
        </div>

        <div id="mes-mensual-div">
            <select id="mes-mensual" class="select-style" @change="handleChange" v-model="mesSelect">
                <option value="" disabled hidden>Mes</option>
                <option v-for="mes in meses" :key="mes" data-info="mes">{{ mes }}</option>
            </select> 
        </div>
    </div>

    <transition name="fade">
        <div id="chart-graficos-mensual" v-if="loaded">
            <button @click="min" id="boton-cerrar-mensual">CERRAR</button>

            <div v-if="show">
                <div id="grafico-totales-mensual">
                    <Bar id="grafico-mensual-totales" :options="graficoOpcionesMesesTotal" :data="graficoMesesTotal" />
                </div>

                <div id="grafico-mes-efectivo-tarjeta">
                    <Bar id="grafico-efectivo-tarjeta-mes" :options="graficoOpcionesEfectivoTarjetaMes"
                        :data="graficoEfectivoTarjetaMes" />
                </div>

                <div id="grafico-mes-total">
                    <Bar id="grafico-total-mes" :options="graficoOpcionesTotalMes" :data="graficoTotalMes" />
                </div>

                <div id="grafico-efectivo-tarjeta-mensual-pie">
                    <Pie id="grafico-mensual-pie" :options="graficoOpcionesEfectivoTarjeta" :data="graficoEfectivoTarjeta" />
                </div>

                <div id="grafico-trabajos-mensual">
                    <Bar id="grafico-mensual-trabajos" :options="graficoOpcionesTrabajos" :data="graficoTrabajos" />
                </div>

                <div id="grafico-productos-mensual">
                    <Bar id="grafico-mensual-productos" :options="graficoOpcionesProductos" :data="graficoProductos" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import axios from 'axios'
import { Bar, Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, ArcElement, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, BarElement, ArcElement, CategoryScale, LinearScale, ChartDataLabels)
ChartJS.defaults.font.size = 15;
ChartJS.defaults.color = 'white'
ChartJS.defaults.borderColor = '#575656';

export default {
    name: 'CajaGraficos',
    data() {
        return {
            show: false,
            buttonText: 'Minimizar',
            loaded: false,
            yearSelect: '',
            mesSelect: '',
            years: [],
            meses: ['Ene', 'Feb', 'Mar', 'Abr', 'May',
                'Jun', 'Jul', 'Ago', 'Sep',
                'Oct', 'Nov', 'Dic'],
            graficoMesesTotal: {
                labels: [],
                datasets: [
                    {
                        label: 'Servicios Efectivo $',
                        backgroundColor: '#58508d',
                        data: [],
                        datalabels: {
                            display: false,

                        }
                    },
                    {
                        label: 'Servicios Tarjeta $',
                        backgroundColor: '#bc5090',
                        data: [],
                        datalabels: {
                            display: false,
                        }
                    },
                    {
                        label: 'Productos Efectivo $',
                        backgroundColor: '#ff6361',
                        data: [],
                        datalabels: {
                            display: false
                        }
                    },
                    {
                        label: 'Productos Tarjeta $',
                        backgroundColor: '#ffa600',
                        data: [],
                        datalabels: {
                            display: false
                        }
                    },
                ]
            },
            graficoOpcionesMesesTotal: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return '$' + value;
                            }
                        }
                    }
                }
            },
            graficoEfectivoTarjetaMes: {
                labels: '',
                datasets: [
                    {
                        label: 'Total efectivo $',
                        backgroundColor: "#96c0ff",
                        data: [],
                    },
                    {
                        label: 'Total tarjeta $',
                        backgroundColor: "#00b3ff",
                        data: [],
                    }
                ]
            },
            graficoOpcionesEfectivoTarjetaMes: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    },
                    datalabels: {
                        formatter: function (value, context) {
                            return '$' + value;
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            callback: function (value, index, ticks) {
                                return '$' + value;
                            }
                        }
                    }
                }
            },
            graficoTotalMes: {
                labels: '',
                datasets: [
                    {
                        label: 'Total $',
                        backgroundColor: "#2f5b6b",
                        data: [],
                    },
                    {
                        label: 'Total $',
                        backgroundColor: "#ff3838",
                        data: [],
                    }
                ]
            },
            graficoOpcionesTotalMes: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    },
                    datalabels: {
                        formatter: function (value, context) {
                            return '$' + value;
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            callback: function (value, index, ticks) {
                                return '$' + value;
                            }
                        }
                    }
                }
            },
            graficoEfectivoTarjeta: {
                labels: ['Efectivo', 'Tarjeta'],
                datasets: [
                    {
                        label: 'Cant.',
                        backgroundColor: ['#0b5394', '#6fa8dc'],
                        data: []
                    },
                ]
            },
            graficoOpcionesEfectivoTarjeta: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    }
                }
            },
            graficoTrabajos: {
                labels: [],
                datasets: [
                    {
                        label: 'Cantidad',
                        backgroundColor: "#ff7abb",
                        data: []
                    }
                ]
            },
            graficoOpcionesTrabajos: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                            stepSize: 1,
                            autoSkip: true,
                        }
                    }
                }
            },
            graficoProductos: {
                labels: [],
                datasets: [
                    {
                        label: 'Cantidad',
                        backgroundColor: '#d793f3',
                        data: []
                    }
                ]
            },
            graficoOpcionesProductos: {
                indexAxis: 'x',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                            stepSize: 1,
                            autoSkip: true,
                        }
                    }
                }
            },
        }
    },
    components: { Bar, Pie },
    mounted() {
        this.getYears();
        this.checkMobile();
    },
    methods: {
        min() {
            this.loaded = false
            this.yearSelect = ''
            this.mesSelect = ''
        },
        checkMobile() {
            let hasTouchScreen = false;
            if ("maxTouchPoints" in navigator) {
                hasTouchScreen = navigator.maxTouchPoints > 0;
            } else if ("msMaxTouchPoints" in navigator) {
                hasTouchScreen = navigator.msMaxTouchPoints > 0;
            } else {
                const mQ = matchMedia?.("(pointer:coarse)");
                if (mQ?.media === "(pointer:coarse)") {
                    hasTouchScreen = !!mQ.matches;
                } else if ("orientation" in window) {
                    hasTouchScreen = true; // deprecated, but good fallback
                } else {
                    // Only as a last resort, fall back to user agent sniffing
                    const UA = navigator.userAgent;
                    hasTouchScreen =
                        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
                }
            }

            if (hasTouchScreen) {

                this.mobile = true
                const scales = {
                    y: {
                        ticks: {
                            maxRotation: 90,
                            minRotation: 90,
                            color: '#FFC512',
                            font: {
                                weight: 'bold'
                            }
                        }
                    },
                    x: {
                        ticks: {
                            callback: function (value, index, ticks) {
                                return '$' + value;
                            }
                        }
                    }
                }
                const scalesProSer = {
                    y: {
                        ticks: {
                            display: false,
                        },
                        grid: {
                            display: false
                        },
                    },
                    x: {
                        ticks: {
                            stepSize: 1,
                            autoSkip: true,
                        },
                    }
                }
                const p = {
                    title: {
                        display: true,
                        text: '',
                        font: {
                            size: 20
                        }
                    },
                    datalabels: {
                        formatter: function (value, context) {
                            let w = ''
                            let words = context.chart.data.labels[context.dataIndex].trim().split(' ');
                            if (words.length > 1) {
                                for (let x = 0; x < words.length; x++) {
                                    if (x > 0) {
                                        w += words[x].substring(0, 3) + '. '
                                    } else {
                                        w += words[x] + ' '
                                    }
                                }
                            } else {
                                w = words[0]
                            }

                            return w;
                        }
                    }
                }
                this.graficoOpcionesMesesTotal.indexAxis = 'y'
                this.graficoOpcionesMesesTotal.scales = scales
                this.graficoOpcionesEfectivoTarjetaMes.indexAxis = 'y'
                this.graficoOpcionesEfectivoTarjetaMes.scales = scales
                this.graficoOpcionesTotalMes.indexAxis = 'y'
                this.graficoOpcionesTotalMes.scales = scales
                this.graficoOpcionesProductos.indexAxis = 'y'
                this.graficoOpcionesProductos.scales = scalesProSer
                this.graficoOpcionesProductos.plugins = p
                this.graficoOpcionesTrabajos.indexAxis = 'y'
                this.graficoOpcionesTrabajos.scales = scalesProSer
                this.graficoOpcionesTrabajos.plugins = p
            }

        },
        handleChange(e) {
            if (e.target.options.selectedIndex > -1) {
                if (e.target.options[e.target.options.selectedIndex].dataset.info === "mes") {
                    this.getMesGraficos()
                }
            }
        },
        async getYears() {
            await axios
                .get("/api/v1/years/")
                .then(response => {
                    this.years = response.data['years']
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
        },
        async getMesGraficos() {
            this.$store.commit('setIsLoading', true)
            this.loaded = false

            const data = {
                year: this.yearSelect,
                mes: this.mesSelect
            }
            const mesNumero = this.meses.indexOf(this.mesSelect)
            const getDays = new Date(this.yearSelect, mesNumero, 0).getDate()

            let dias = []
            for (let i = 1; i < getDays + 1; i++) {
                dias.push(`${i}/${mesNumero + 1}/${this.yearSelect}`)
            }

            await axios
                .post("/api/v1/grafico-caja-mensual/", data)
                .then(response => {
                    let efectivoMensualTrabajo = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    let tarjetaMensualTrabajo = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    let efectivoMensualTienda = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    let tarjetaMensualTienda = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    
                    for (let i = 0; i < response.data[0]['efectivo_trabajo_mensual'].length; i++) {
                        efectivoMensualTrabajo.splice(response.data[0]['efectivo_trabajo_mensual'][i]['mes'] - 1, 1, response.data[0]['efectivo_trabajo_mensual'][i]['total']);
                    }
                    for (let i = 0; i < response.data[0]['tarjeta_trabajo_mensual'].length; i++) {
                        tarjetaMensualTrabajo.splice(response.data[0]['tarjeta_trabajo_mensual'][i]['mes'] - 1, 1, response.data[0]['tarjeta_trabajo_mensual'][i]['total']);
                    }
                    for (let i = 0; i < response.data[0]['efectivo_tienda_mensual'].length; i++) {
                        efectivoMensualTienda.splice(response.data[0]['efectivo_tienda_mensual'][i]['mes'] - 1, 1, response.data[0]['efectivo_tienda_mensual'][i]['total']);
                    }
                    for (let i = 0; i < response.data[0]['tarjeta_tienda_mensual'].length; i++) {
                        efectivoMensualTrabajo.splice(response.data[0]['tarjeta_tienda_mensual'][i]['mes'] - 1, 1, response.data[0]['tarjeta_tienda_mensual'][i]['total']);
                    }
                   
                    this.graficoMesesTotal.labels = this.meses
                    this.graficoOpcionesMesesTotal.plugins.title.text = 'Totales por mes ' + this.yearSelect
                    this.graficoMesesTotal.datasets[0].data = efectivoMensualTrabajo
                    this.graficoMesesTotal.datasets[1].data = tarjetaMensualTrabajo
                    this.graficoMesesTotal.datasets[2].data = efectivoMensualTienda
                    this.graficoMesesTotal.datasets[3].data = tarjetaMensualTienda
                    
                    this.graficoEfectivoTarjetaMes.labels = [this.mesSelect + ' ' + this.yearSelect]
                    this.graficoOpcionesEfectivoTarjetaMes.plugins.title.text = 'Efectivo / Tarjeta'
                    this.graficoEfectivoTarjetaMes.datasets[0].data = [response.data[0]['total_efectivo']]
                    this.graficoEfectivoTarjetaMes.datasets[1].data = [response.data[0]['total_tarjeta']]
                    
                    this.graficoTotalMes.labels = [this.mesSelect]
                    this.graficoOpcionesTotalMes.plugins.title.text = 'Efectivo + Tarjeta / Retiros'
                    this.graficoTotalMes.datasets[0].data = [response.data[0]['total_efectivo'] + response.data[0]['total_tarjeta']]
                    this.graficoTotalMes.datasets[1].data = [response.data[0]['retiros']]

                    this.graficoEfectivoTarjeta.datasets[0].data = [response.data[0]['efectivo_cantidad'], response.data[0]['tarjeta_cantidad']]
                    this.graficoOpcionesEfectivoTarjeta.plugins.title.text = 'Modalidad de pago ' + this.mesSelect + ' ' + this.yearSelect
                    
                    this.graficoTrabajos.labels = response.data[0]['trabajos_tipo']
                    this.graficoTrabajos.datasets[0].data = response.data[0]['trabajos_cantidad']
                    this.graficoProductos.labels = response.data[0]['productos_nombre']
                    this.graficoProductos.datasets[0].data = response.data[0]['productos_cantidad']
                    this.graficoOpcionesTrabajos.plugins.title.text = 'Servicios ' + this.mesSelect + ' ' + this.yearSelect
                    this.graficoOpcionesProductos.plugins.title.text = 'Productos ' + this.mesSelect + ' ' + this.yearSelect
                    this.loaded = true
                    this.show = true
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)
        },
    }
}
</script>

<style>
#grafico-caja-mensual {
    max-width: 270px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
    margin: 0 auto 50px auto;
}

#grafico-caja-mensual h1{
    text-shadow: var(--text-shadow);
}

#year-mensual,
#mes-mensual {
    display: inline-table;
    margin-left: 10px;
    margin-right: 10px;
}

#year-mensual-div, #mes-mensual-div {
    display: inline-table;
    border-bottom: 2px solid var(--fieldBorderColor);
    width: 100px;
    margin: 10px;
}

#chart-graficos-mensual {
  margin-bottom: 50px;
}

#boton-cerrar-mensual {
  width: 120px;
  background-color: var(--button-close);
  border: none;
  color: rgb(255, 255, 255);
  padding: 0 8px;
}

#grafico-totales-mensual, #grafico-mes-efectivo-tarjeta, 
#grafico-mes-total, #grafico-efectivo-tarjeta-mensual-pie, 
#grafico-trabajos-mensual, #grafico-productos-mensual {
    margin: 50px 10px 0 10px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black-big);
    background-position: center center;
    background-size: cover;
}

#grafico-mensual-totales,
#grafico-efectivo-tarjeta-mes,
#grafico-total-mes,
#grafico-mensual-trabajos,
#grafico-mensual-productos {
    height: 500px;
}

#grafico-mes-efectivo-tarjeta,
#grafico-mes-total,
#grafico-efectivo-tarjeta-mensual-pie {
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 500px;
}

@media screen and (max-width: 700px) {
    #grafico-mensual-totales {
        height: 700px;
    }

    #grafico-total-mes,
    #grafico-efectivo-tarjeta-mes {
        height: 400px;
    }

    #grafico-mes-efectivo-tarjeta,
    #grafico-mes-total,
    #grafico-efectivo-tarjeta-mensual-pie {
        display: inherit;
        margin-left: inherit;
        margin-right: inherit;
    }

    #grafico-efectivo-tarjeta-mensual-pie {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>


<!--
    async getMesGraficos() {
        this.loaded = false
        this.graficoEfectivoTarjeta.datasets[0].data = []
        this.graficoMesesTotal.datasets[0].data = []
        this.graficoMesesTotal.datasets[1].data = []
        this.graficoMesesTotal.datasets[2].data = []
        this.graficoMesesTotal.datasets[3].data = []


        const data = {
            year:this.yearSelect,
            mes:this.mesSelect
        }
        const mesNumero = this.meses.indexOf(this.mesSelect)
        const getDays = new Date(this.yearSelect, mesNumero, 0).getDate()

        let dias = []
        for (let i = 1; i < getDays + 1; i++) {
            dias.push(`${i}/${mesNumero + 1}/${this.yearSelect}`)
        }

        await axios
            .post("/api/v1/grafico-caja-mensual/", data)
            .then(response => {
                console.log(response.data)
                this.graficoMesesTotal.labels = dias
                this.graficoOpcionesMesesTotal.plugins.title.text = 'Totales por dia ' + this.mesSelect + ' ' + this.yearSelect
                let totalEfectivo = 0
                let totaltarjeta = 0
                
                for (let i = 0; i < dias.length; i++) {
                    if (response.data[0]['dias'][i] == undefined){
                        this.graficoMesesTotal.datasets[0].data.push(0)
                        this.graficoMesesTotal.datasets[1].data.push(0)
                    }else {
                        this.graficoMesesTotal.datasets[0].data.push(response.data[0]['total'][i]['efectivo'])
                        this.graficoMesesTotal.datasets[1].data.push(response.data[0]['total'][i]['tarjeta'])
                        totalEfectivo += response.data[0]['total'][i]['efectivo']
                        totaltarjeta += response.data[0]['total'][i]['tarjeta']
                    }

                    if (response.data[0]['dias_tienda'][i] == undefined){
                        this.graficoMesesTotal.datasets[2].data.push(0)
                        this.graficoMesesTotal.datasets[3].data.push(0)
                    }else {
                        this.graficoMesesTotal.datasets[2].data.push(response.data[0]['total_tienda'][i]['efectivo'])
                        this.graficoMesesTotal.datasets[3].data.push(response.data[0]['total_tienda'][i]['tarjeta'])
                        totalEfectivo += response.data[0]['total_tienda'][i]['efectivo']
                        totaltarjeta += response.data[0]['total_tienda'][i]['tarjeta']
                    }
                }
                     
                this.graficoEfectivoTarjeta.datasets[0].data.push(response.data[0]['efectivo_cantidad'])
                this.graficoEfectivoTarjeta.datasets[0].data.push(response.data[0]['tarjeta_cantidad'])
                this.graficoOpcionesEfectivoTarjeta.plugins.title.text = 'Modalidad de pago ' + this.mesSelect + ' ' + this.yearSelect
                this.graficoTrabajos.labels = response.data[0]['trabajos_tipo']
                this.graficoTrabajos.datasets[0].data = response.data[0]['trabajos_cantidad']
                this.graficoProductos.labels = response.data[0]['productos_nombre']
                this.graficoProductos.datasets[0].data = response.data[0]['productos_cantidad']
                this.graficoOpcionesTrabajos.plugins.title.text = 'Servicios ' + this.mesSelect + ' ' + this.yearSelect
                this.graficoOpcionesProductos.plugins.title.text = 'Productos ' + this.mesSelect + ' ' + this.yearSelect
                this.loaded = true
             
            
            })
            .catch(error => {
              console.log(JSON.stringify(error))
            })
        
    },
-->