<template>
    <div id="gestion-empleados-view">
        <div id="crear-empleado">
            <h1>CREAR EMPLEADO</h1>
            <div id="datos-empleado-div">
                <div id="nombre-empleado-div">
                    <div class="field field_v2">
                        <label for="nombre-empleado" class="ha-screen-reader">Nombre</label>
                        <input v-model="empleado.nombre" id="nombre-empleado" class="field__input" placeholder="ej. Lorena" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Nombre</span>
                        </span>
                    </div>    
                    <span v-if="errorNombre"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorNombre }}</p></span>
                </div>

                <div id="apellido-empleado-div">
                    <div class="field field_v2">
                        <label for="apellido-empleado" class="ha-screen-reader">Apellido</label>
                        <input v-model="empleado.apellido" id="apellido-empleado" class="field__input" placeholder="ej. Gonzales" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Apellido</span>
                        </span>
                    </div>    
                    <span v-if="errorApellido"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorApellido }}</p></span>
                </div>

                <div id="dni-empleado-div">
                    <div class="field field_v2">
                        <label for="dni-empleado" class="ha-screen-reader">Dni</label>
                        <input v-model="empleado.dni" id="dni-empleado" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="field__input" placeholder="ej. 20123456" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Dni</span>
                        </span>
                    </div>    
                    <span v-if="errorDni"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorDni }}</p></span>         
                </div>

                <div id="telefono-empleado-div">
                    <div class="field field_v2">
                        <label for="telefono-empleado" class="ha-screen-reader">Telefono</label>
                        <input v-model="empleado.telefono" id="telefono-empleado" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="field__input" placeholder="ej. 114123456" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Telefono</span>
                        </span>
                    </div>   
                    <span v-if="errorTelefono"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorTelefono }}</p></span>
                </div>

                <div id="direccion-empleado-div">
                    <div class="field field_v2">
                        <label for="direccion-empleado" class="ha-screen-reader">Direccion</label>
                        <input v-model="empleado.direccion" id="direccion-empleado" class="field__input" placeholder="ej. Salta 1234" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Direccion</span>
                        </span>
                    </div>   
                    <span v-if="errorDireccion"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorDireccion }}</p></span>
                </div>

                <div id="posicion-empleado-div">
                    <div id="empleado">
                        <select id="posicion-empleado" class="select-style" @change="handleChange" v-model="posicionEmpleadoSelect">
                            <option value="" disabled hidden>Posicion</option>
                            <option v-for="posicion in posiciones" :key="posicion.id" data-info="posicion" :data-id="posicion.id">{{ posicion.nombre }}</option>
                        </select> 
                    </div>
                    <span v-if="errorPosicion"><p class="error-select" style="color: red; height: 0;">{{ errorPosicion }}</p></span>
                </div>

                <div id="email-empleado-div" v-if="usuario">
                    <div class="field field_v2">
                        <label for="email-empleado" class="ha-screen-reader">E-mail</label>
                        <input v-model="empleado.email" id="email-empleado" class="field__input" placeholder="ej. ej@ej.com" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">E-mail</span>
                        </span>
                    </div>   
                    <span v-if="errorEmail"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorEmail }}</p></span>
                </div>

                <div id="password-empleado-div" v-if="usuario">
                    <div class="field field_v2">
                        <label for="password-empleado" class="ha-screen-reader">Contraseña</label>
                        <input v-model="empleado.password" id="password-empleado" class="field__input" placeholder="ej. Ejemplo123" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Contraseña</span>
                        </span>
                    </div>   
                    <span v-if="errorPassword"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorPassword }}</p></span>
                </div>

                <div id="servicios-empleado-div">
                    <label id="label-servicios-title" for="servicio-empleado">SERVICIOS</label>
                    <div v-if="servicios.length > 0" v-for="servicio in servicios" :key="servicio.id" id="servicio-empleado">
                        <label id="label-servicio-nombre" for="servicio-checkbox">{{ servicio.nombre }}</label>
                        <input type="checkbox" id='servicio-checkbox' :value="servicio.id" v-model="empleado.servicios">   
                    </div> 
                    <div v-else>
                        <a href="/gestion/ajustes-tienda">CREA ALGUNOS AQUI</a>
                    </div>
                </div>

                <div id="no-trabaja-empleado-div">
                    <label id="label-no-trabaja-title" for="dia-div">NO TRABAJA</label>
                    <div v-for="dia in dias" :key="dia" id="dia-div">
                        <label id="label-dia-nombre" for="dia">{{ dia.nombre }}</label>
                        <input type="checkbox" id='dia' :value="dia.numero" v-model="empleado.noTrabaja">   
                    </div> 
                </div>

                <div id="boton-crear-empleado-div">
                    <button id="boton-crear-empleado" @click="crearEmpleado" data-info="crear-empleado">CREAR</button>
                </div>
            </div>
        </div>  
        
        <div id="empleados-tabla">
            <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                <div class="lds-dual-ring"></div>
            </div>
            
            <div id="table-wrapper-empleados">
                <div id="tabla-empleados">
                    <table>
                        <thead>
                            <tr>
                                <th id="eliminar-config-tabla-titulo"></th>
                                <th>POSICION</th>
                                <th>NOMBRE</th>
                                <th>APELLIDO</th>
                                <th>DNI</th>
                                <th>TELEFONO</th>
                                <th>DIRECCION</th>
                                <th>NO TRABAJA</th>
                                <th id="vacaciones-tabla-titulo">VACACIONES</th>
                            </tr> 
                        </thead>

                        <tbody>
                            <tr
                                v-for="empleado in empleados"
                                :key="empleado.id">
                    
                                <td id="settings-delete-icon-empleado">
                                    <img id="settings-empleado" src="@/assets/images/settings.png" alt="settings-icon" @click="settingsEmpleado" title="Editar" data-info="empleados-tabla" :data-id="empleado.id"> 
                                    <img id="trash-empleado" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarEmpleado" title="Eliminar" data-info="empleados-tabla" :data-id="empleado.id" :data-posicion="empleado.posicion">
                                </td>
                                <td id="posicion" data-label="POSICION">{{ empleado.posicion }}</td>
                                <td data-label="NOMBRE">{{ empleado.nombre }}</td>
                                <td data-label="APELLIDO">{{ empleado.apellido }}</td>
                                <td data-label="DNI">{{ empleado.dni }}</td>
                                <td data-label="TELEFONO">{{ empleado.telefono }}</td>
                                <td data-label="DIRECCION">{{ empleado.direccion }}</td>
                                <td data-label="NO TRABAJA" ><div id="dias-no-trabaja" v-for="dia in empleado.no_trabaja">{{ dia.dia }}</div></td>                         
                                <td data-label="VACACIONES">
                                    <div v-if="empleado.vacaciones == '-'" >{{ empleado.vacaciones }}</div>
                                    <div v-else v-for="vacacion in empleado.vacaciones">{{ vacacion.fecha }}</div>
                                </td>
                                <div id="settings-delete-mobile-empleados">
                                    <img id="settings-empleado-mobile" src="@/assets/images/settings.png" alt="settings-icon" @click="settingsEmpleado" title="Editar" data-info="empleados-tabla" :data-id="empleado.id">
                                    <img id="trash-empleado-mobile" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarEmpleado" data-info="empleados-tabla" :data-id="empleado.id">
                                </div>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div id="usuarios-tabla">
            <div id="table-wrapper-usuarios">
                <div id="tabla-usuarios">
                    <table>
                        <thead>
                            <tr>
                                <th id="eliminar-config-tabla-titulo"></th>
                                <th id="usuarios-tabla-titulo">USUARIOS</th>
                            </tr> 
                        </thead>

                        <tbody>
                            <tr
                                v-for="usuario in usuarios"
                                :key="usuario.id">
                    
                                <td id="settings-delete-icon-empleado">
                                    <img v-if="this.$store.state.admin" id="trash-empleado-usuarios" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarUsuario" title="Eliminar" data-info="usuarios-tabla" :data-id="usuario.id">
                                </td>
                                <td data-label="USUARIOS">{{ usuario.username }}</td>
                               
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        
        <div id="entrada-salida-empleado">
            <div id="buscar-entrada-salida-empleado">
                <h1>ENTRADAS - SALIDAS</h1>
                <div id="empleado-entrada-salida-div">
                    <div id="empleado-entrada-salida-inner">
                        <select id="empleado-entrada-salida" class="select-style" @change="handleChange" v-model="empleadoEntradaSalidaSelect">
                            <option value="" disabled hidden>Emplead@</option>
                            <option v-for="empleado in empleadosEntradaSalida" :key="empleado.id" data-info="empleado-entrada-salida" :data-id="empleado.id">
                                    {{ empleado.nombre }} {{ empleado.apellido }}
                            </option>
                        </select> 
                    </div>
                    <span v-if="errorEmpleadoEntradaSalida"><p class="error-select" style="color: red; height: 0;">{{ errorEmpleadoEntradaSalida }}</p></span>
                </div>

                <div id="fecha-turno-div" class="fecha-entrada-salida">
                    <div id="fecha-turno">
                        <Datepicker 
                            dark
                            auto-apply
                            hide-input-icon
                            hide-offset-dates
                            placeholder="Fecha"
                            v-model="entradaSalidaFecha"
                            :enable-time-picker="false"
                            :format="format" 
                            locale="es" 
                            id="fecha"
                            position="center"
                            @cleared="limpiar"
                        />      
                    </div>
                    <span v-if="errorFechaEntradaSalida"><p class="error-select" style="color: red; height: 0;">{{ errorFechaEntradaSalida }}</p></span>
                </div>

                <div id="buscar-boton-entrada-salida-div">
                    <button @click="buscarEntradasSalidas" id="boton-buscar-entrada-salida" data-info="buscar-entradas-salidas">BUSCAR</button>
                </div>
            </div>

            <transition name="fade">
                <div id="table-wrapper-entradas-salidas" v-if="entradasSalidas.length > 0">
                    <div id="tabla-entradas-salidas">
                        <table>
                            <thead>
                                <tr>
                                    <th>FECHA ENTRADA</th>
                                    <th>HORA ENTRADA</th>
                                    <th>FECHA SALIDA</th>
                                    <th>HORA SALIDA</th>
                                    <th>EMPLEADO</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="item in entradasSalidas" :key="item.id">
                                    <td data-label="FECHA ENTRADA">{{ item.get_fecha_entrada }}</td>
                                    <td data-label="HORA ENTRADA">{{ item.get_hora_entrada }} HS</td>
                                    <td data-label="FECHA SALIDA">{{ item.get_fecha_salida }}</td>
                                    <td data-label="HORA SALIDA">{{ item.get_hora_salida }} HS</td>
                                    <td data-label="EMPLEADO">{{ item.empleado }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </transition>
        </div>
        
        <InformacionPersonalEmpleado
        :empleados="empleados"
        />
        <transition name="bounce">
            <SettingsEmpleadoModal 
                v-show="showModal" 
                @empleados="getEmpleados"
                @close-modal="showModal = false" 
                :dias="dias"
                :posiciones="posiciones"
                :trigger="empleado.id"
                :servicios="servicios"
            /> 
        </transition>
    </div>
</template>

<script>
import axios from 'axios'
import SettingsEmpleadoModal from '@/components/SettingsEmpleadoComp.vue'
import InformacionPersonalEmpleado from '@/components/InformacionPersonalEmpleadoComp.vue'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';

// In case of a range picker, you'll receive [Date, Date]
const format = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}


export default {
    name: 'gestionEmpleadosView',
    data() {
        return {
            format,
            showModal: false,
            empleado: {
                id:'',
                nombre:'',
                apellido:'',
                dni:'',
                telefono:'',
                direccion:'',
                posicionId:'',
                noTrabaja:[],
                vacaciones:[],
                email:'',
                password:'',
                servicios:[]
            },
            errorNombre: '',
            errorApellido: '',
            errorDni:'',
            errorTelefono:'',
            errorDireccion:'',
            errorPosicion:'',
            errorEmail:'',
            errorPassword:'',
            errorServicio:'',
            errorFechaEntradaSalida:'',
            errorEmpleadoEntradaSalida:'',
            dias:[
                {
                    nombre:'LUN',
                    numero:1
                },
                {
                    nombre:'MAR',
                    numero:2
                },
                {
                    nombre:'MIE',
                    numero:3
                },
                {
                    nombre:'JUE',
                    numero:4
                },
                {
                    nombre:'VIE',
                    numero:5
                },
                {
                    nombre:'SAB',
                    numero:6
                },
                {
                    nombre:'DOM',
                    numero:0
                },
            ],
            posiciones:[],
            posicionEmpleadoSelect:'',
            empleados:[],
            usuarios:[],
            usuario:false,
            posicionNombre:'',
            servicios:[],
            servicioEmpleadoSelect:'',
            entradaSalidaFecha:'',
            entradasSalidas:[],
            empleadoEntradaSalidaSelect:'',
            empleadosEntradaSalida:[],
            empleadoEntradaSalidaId:''
        }
    },
    components: {
        SettingsEmpleadoModal,
        InformacionPersonalEmpleado,
        Datepicker
    },
    mounted() {
        this.getEmpleados();
        this.getPosiciones();
        this.getUsuarios();
        this.getServicios();
        this.setBgBody();
    },
    methods: {
        limpiar(e) {
            this.entradasSalidas = []
        },
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-gestion)");
            document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
        },
        async getEmpleados() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/empleados/')
                .then(response => {
                    this.empleados = response.data
                    for (let i = 0; response.data.length > i; i++) {
                        if (response.data[i]['posicion'] == 'ADMIN' || response.data[i]['posicion'] == 'CAJER@') {
                            this.empleadosEntradaSalida.push(response.data[i])
                        }
                    }
                })
                .catch(error => {
                    if (error.response.status === 401){
                        localStorage.removeItem("token")
                        localStorage.removeItem("username")
                        localStorage.removeItem("userid")
                        this.$store.commit('removeToken')
                        this.$router.push('/gestion')
                    }
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getUsuarios() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/usuarios/')
                .then(response => {
                    this.usuarios = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getPosiciones() {
            await axios
                .get('/api/v1/empleados/posiciones/')
                .then(response => {
                    this.posiciones = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async getServicios() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/servicios/')
                .then(response => {
                    this.servicios = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async buscarEntradasSalidas(e) {
            if (!this.entradaSalidaFecha) {
                this.errorFechaEntradaSalida = 'Requerida'
                setTimeout(() => this.errorFechaEntradaSalida = '', 2000)
            }
            if (!this.empleadoEntradaSalidaSelect) {
                this.errorEmpleadoEntradaSalida = 'Requerid@'
                setTimeout(() => this.errorEmpleadoEntradaSalida = '', 2000)
            }

            e.preventDefault();

            if (!this.errorFechaEntradaSalida && !this.errorEmpleadoEntradaSalida) {
                let data = {
                    id:this.empleadoEntradaSalidaId,
                    fecha:this.entradaSalidaFecha
                }

                await axios
                    .post("/api/v1/empleados/entradas-salidas/", data)
                    .then(response => {                 
                        this.entradasSalidas = response.data                  
                    })
                    .catch(error => {
                        console.log(JSON.stringify(error))
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL BUSCAR TRABAJO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
            }
        },
        async crearEmpleado(e) {
            if (!this.empleado.nombre) {
                this.errorNombre = 'Requerido'
                setTimeout(() => this.errorNombre = '', 2000)
            }
            if (!this.empleado.apellido) {
                this.errorApellido = 'Requerido'
                setTimeout(() => this.errorApellido = '', 2000)
            }
            if (!this.empleado.dni) {
                this.errorDni = 'Requerido'
                setTimeout(() => this.errorDni = '', 2000)
            }
            if (!this.empleado.telefono) {
                this.errorTelefono = 'Requerido'
                setTimeout(() => this.errorTelefono = '', 2000)
            }
            if (!this.empleado.direccion) {
                this.errorDireccion = 'Requerida'
                setTimeout(() => this.errorDireccion = '', 2000)
            }
            if (!this.empleado.posicionId) {
                this.errorPosicion = 'Requerida'
                setTimeout(() => this.errorPosicion = '', 2000)
            }
               
            e.preventDefault();

            if (!this.errorNombre && !this.errorApellido&& 
                !this.errorDni && !this.errorTelefono && 
                !this.errorDireccion && !this.errorPosicion) {

                const data = this.empleado

                await axios
                    .post('/api/v1/empleados/crear/', data)
                    .then(response => {
                        Swal.fire({
                            position: 'top-end',
                            icon: response.data['icon'],
                            title: response.data['title'],
                            showConfirmButton: false,
                            timer: 2000
                        })
                        if (response.data['icon'] == 'success') {
                            this.getEmpleados()
                            this.getUsuarios()
                            this.empleado = {
                                nombre:'',
                                apellido:'',
                                dni:'',
                                telefono:'',
                                direccion:'',
                                posicionId:'',
                                noTrabaja:[],
                                vacaciones:[],
                            }
                            this.posicionEmpleadoSelect = ''
                            this.empleado.noTrabaja = []
                            this.usuario = false
                        }else {
                            this.empleado = {
                                nombre:'',
                                apellido:'',
                                dni:'',
                                telefono:'',
                                direccion:'',
                                posicionId:'',
                                noTrabaja:[],
                                vacaciones:[],
                            }
                            this.posicionEmpleadoSelect = ''
                            this.empleado.noTrabaja = []
                            this.usuario = false
                        }
                        
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL CREAR EMPLEADO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
            }
        },
        async settingsEmpleado(e) {
            this.empleado.id = e.target.dataset.id
            this.showModal = true
        },
        async eliminarEmpleado(e) {
            Swal.fire({
                title: 'ELIMINAR EMPLEAD@?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#279f04',
                cancelButtonColor: '#d33',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                cancelButtonText: 'CANCELAR',
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = {
                        id:e.target.dataset.id,
                    }
                    axios
                        .post("/api/v1/empleados/eliminar/", data)
                        .then(response => {
                            this.getEmpleados()
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'EMPLEAD@ ELIMINAD@',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'ERROR AL ELIMINAR EMPLEAD@',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                }
            })
        },
        async eliminarUsuario(e) {
            Swal.fire({
                title: 'ELIMINAR USUARI@?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#279f04',
                cancelButtonColor: '#d33',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                cancelButtonText: 'CANCELAR',
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = {
                        id:e.target.dataset.id,
                    }
                    axios
                        .post("/api/v1/usuarios/eliminar/", data)
                        .then(response => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'USUARI@ Y EMPLEAD@ ELIMINAD@',
                                showConfirmButton: false,
                                timer: 2000
                            })
                            this.getUsuarios()
                            this.getEmpleados()
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'ERROR AL ELIMINAR USUARI@',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                }
            })
        },
        handleChange(e) {
            if(e.target.options.selectedIndex > -1) {
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "posicion"){
                    if (e.target.value == 'ADMIN' || e.target.value == 'CAJER@') {
                        this.usuario = true
                    }
                    else{
                        this.usuario = false
                    }
                    this.empleado.posicionId = e.target.options[e.target.options.selectedIndex].dataset.id
                }
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "empleado-entrada-salida"){
                    this.empleadoEntradaSalidaId = e.target.options[e.target.options.selectedIndex].dataset.id
                }
            }
        },
    }
}

</script>

<style>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
    
    #gestion-empleados-view {
        padding-top: 180px;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
    }

    #crear-empleado h1 {
        text-shadow: var(--text-shadow)
    }

    #crear-empleado, #empleados-tabla, 
    #usuarios-tabla, #datos-empleado,
    #entrada-salida-empleado {
        display: inline-table;
        margin: 0 20px 50px 20px;
    }

    #crear-empleado {
        max-width: 500px;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0px 33px 22px 0px #000000;
        max-height: 500px;
        color: white;
        background: var(--bg-box-black);
        background-position: center center;
        background-size: cover;
    }

    #tabla-empleados, 
    #tabla-usuarios,
    #tabla-entradas-salidas {
        max-height: 520px;
        border-radius: 4px;
        box-shadow: 0px 33px 22px 0px #000000;
        overflow: auto;
        background: var(--bg-box-black-big);
        background-position: center center;
        background-size: cover;
        color: white;
    }

    #usuarios-tabla {
        max-width: 350px;
        max-height: 400px;
        border-radius: 4px;
        box-shadow: 0px 33px 22px 0px #000000;;
        overflow: auto;
        background: var(--bg-box-black-big);
        background-position: center center;
        background-size: cover;
        color: white;
    }

    #tabla-usuarios table {
        width: 100%;
    }

    #dia-div {
        display: inline-table;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 10px;
    }

    #nombre-empleado-div, #apellido-empleado-div, #dni-empleado-div {
        display: inline-table;
        margin: 10px 5px 0 5px;
    }

    #telefono-empleado-div, #direccion-empleado-div, #posicion-empleado-div{
        display: inline-table;
        margin: 10px 5px 0 5px;
    }

    #password-empleado-div, #email-empleado-div {
        display: inline-table;
        margin: 10px 5px 0 5px;
    }

    #nombre-empleado, #apellido-empleado, 
    #telefono-empleado, #direccion-empleado,
    #dni-empleado, #email-empleado, 
    #password-empleado {
        width: 150px;
        margin-bottom: 13px;
    }

    #posicion-empleado {
        width: 150px;
        margin-bottom: 5px;
    }

    #boton-crear-empleado {
        background-color: var(--button-color);
        border: none;
        color: rgb(255, 255, 255);
        width: 90%;
    }

    
    #label-no-trabaja-title,
    #label-servicios-title {
        text-shadow: var(--text-shadow);
        padding: 20px;
    }

    #servicio-empleado {
        display: inline-table;
        margin: 0 5px 0 5px;
    }

    #servicios-empleado-div a {
        color: white;
    }

    #eliminar-config-tabla-titulo {
        border-radius: 4px 0 0 0;
    }

    #vacaciones-tabla-titulo {
        width: 210px;
    }

    #vacaciones-tabla-titulo, #usuarios-tabla-titulo {
        border-radius: 0 4px 0 0;
    }

    #table-wrapper-empleados, 
    #table-wrapper-usuarios {
        position:relative;
        width: 100%;
    }

    #tabla-empleados tr th,
    #usuarios-tabla tr th,
    #tabla-entradas-salidas tr th {
        position: sticky;
        top: 0;
        background-color: #000000;
        border: none;
        font-weight: 900;
        font-size: 20px;
        text-align: center;
        text-shadow: var(--text-shadow);
        color: white;
    }

    #tabla-empleados table tr td,
    #tabla-entradas-salidas table tr td {
        font-size: 20px;
        letter-spacing: .1em;
        text-transform: uppercase;
        text-align: center;
        max-width: 205px;
        padding: 10px;
    }

    #tabla-usuarios table tr td{
        font-size: 20px;
        letter-spacing: .1em;
        text-align: center;
        padding: 10px;
    }

    #tabla-empleados::-webkit-scrollbar-thumb,
    #tabla-usuarios::-webkit-scrollbar-thumb,
    #tabla-entradas-salidas::-webkit-scrollbar-thumb {
        width: 5px;
        background-color: #555;
    }

    #tabla-empleados::-webkit-scrollbar,
    #tabla-usuarios::-webkit-scrollbar,
    #tabla-entradas-salidas::-webkit-scrollbar {
        width: 5px;
        background-color: #000000;
    }

    #tabla-empleados #trash-mobile-icon,
    #tabla-usuarios #trash-mobile-icon {
        display: none;
    }

    #settings-empleado, #trash-empleado {
        display: inline-table;
    }
   
    #trash-empleado, #trash-empleado-usuarios {
        width: 20px;
        cursor: pointer;
        margin-right: 5px;
        margin-left: 5px;
        filter: invert(100%);
    }

    #settings-empleado {
        margin-right: 5px;
        margin-left: 5px;
        width: 25px;
        cursor: pointer;
        filter:invert(100%);
    }

    #settings-delete-mobile-empleados {
        display: none;
    }

    #dia-no-trabaja-mobile {
        display: none;
    }

    #dias-no-trabaja {
        display: inline-block;
        margin-left: 5px;
    }

    /* TABLA USUARIOS / INFORMACION PERSONAL */

    #datos-empleado {
        max-width: 440px;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0px 33px 22px 0px #000000;
        color: white;
        background: var(--bg-box-black);
        background-position: center center;
        background-size: cover;
        margin: 0 20px 0 20px;
    }

    #fecha-vacaciones #fecha input {
        text-align: center;
    }

    #fecha-setting input  {
        height: 38px;
        text-align: left;
        font-family: 'Khand';
        color: white;
        font-size: 18px;
        background-color: transparent;
        border: none;
    }

    #buscar-entrada-salida-empleado {
        max-width: 400px;
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0px 33px 22px 0px #000000;
        color: white;
        background: var(--bg-box-black);
        background-position: center center;
        background-size: cover;
        margin: 0 auto 50px auto;
    }

    #buscar-entrada-salida-empleado h1 {
        text-shadow: var(--text-shadow);
    }

    .fecha-entrada-salida, #empleado-entrada-salida-div {
        display: inline-table;
    }

    #empleado-entrada-salida{
        width: 170px;
    }

    #empleado-entrada-salida-inner {
        border-bottom: 2px solid var(--fieldBorderColor); 
    }

    #boton-buscar-entrada-salida {
        background-color: var(--button-color);
        border: none;
        color: rgb(255, 255, 255);
        padding: 0 8px;
        width: 90%;
    }

    #table-wrapper-entradas-salidas {
        position: relative;
        width: 100%;
        margin: 0 auto 0 auto;
    }

    #tabla-entradas-salidas table {
        width: 100%;
    }

    #tabla-entradas-salidas tr th {
        padding-left: 5px;
        padding-right: 5px;
    }

    @media screen and (min-width: 1300px) and (max-width: 1757px) {
        #crear-empleado {
            max-width: 1000px;
        }

        #no-trabaja-empleado-div,
        #servicios-empleado-div {
            display: inline-table;
            margin: 0 50px 0 50px;
        }
    }
    @media screen and (min-width: 1100px) and (max-width: 1300px) {
        #crear-empleado {
            max-width: 1000px;
        }

        #tabla-empleados thead tr th {
            font-size: 18px;
        }

        #tabla-empleados table tr td {
            font-size: 17px;
        }

        #no-trabaja-empleado-div,
        #servicios-empleado-div {
            display: inline-table;
            margin: 0 50px 0 50px;
        }
    }

    @media screen and (max-width: 1100px) {
        #gestion-empleados-view {
            padding-top: 130px;
        }

        #empleados-tabla {
            width: 90%;
        }

        #empleados-tabla table {
            width: 100%;
        }

        #tabla-empleados {
            padding: 10px;
            height: 440px;
            width: 400px;
            margin-left: auto;
            margin-right: auto;
        }

        #tabla-entradas-salidas {
            padding: 10px;
            height: 300px;
            width: 400px;
            margin-left: auto;
            margin-right: auto;
        }

        #tabla-empleados thead tr th,
        #tabla-entradas-salidas thead tr th {
            text-align: left;
        }

        #tabla-empleados table tr td,
        #tabla-entradas-salidas table tr td {
            text-align: right;
            font-size: 17px;
            max-width: 100%;
        }

        #tabla-empleados #delete-icon-turno {
            display: none;
        }

        #tabla-empleados #trash-mobile-icon {
            display: initial;
        }

        #tabla-empleados thead,
        #tabla-entradas-salidas thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        #tabla-empleados tr,
        #tabla-entradas-salidas tr {
            border-bottom: 3px solid #ddd;
            display: block;
            margin-bottom: .625em;
        }

        #tabla-empleados table td,
        #tabla-entradas-salidas table td {
            border-bottom: none;
            display: block;
            font-size: 17px;
            text-align: right;
        }

        #tabla-empleados table tr td #dia-mobile{
            display: inline-table;
            margin-right: 5px;
            margin-left: 5px;
            font-size: 17px;
        }

        #tabla-empleados td::before,
        #tabla-entradas-salidas td::before {
            /*
            * aria-label has no advantage, it won't be read inside a table
            content: attr(aria-label);
            */
            content: attr(data-label);
            float: left;
            font-weight: bold;
            text-transform: uppercase;
        }

        #tabla-empleados td:last-child,
        #tabla-entradas-salidas td:last-child {
            border-bottom: 0;
        }

        #tabla-empleados::-webkit-scrollbar,
        #tabla-usuarios::-webkit-scrollbar,
        #tabla-entradas-salidas::-webkit-scrollbar {
            display: none;
        }

        #tabla-empleados::-webkit-scrollbar-thumb,
        #tabla-usuarios::-webkit-scrollbar-thumb,
        #tabla-entradas-salidas::-webkit-scrollbar-thumb {
            display: none;
        }

        #trash-empleado-mobile {
            width: 24px;
            margin-right: 5px;
            margin-left: 5px;
            filter:invert(100%);
        }

        #settings-empleado-mobile {
            width: 28px;
            margin-right: 5px;
            margin-left: 5px;
            filter:invert(100%);
        }

        #trash-empleado, #settings-empleado {
            display: none;
        }

        #posicion {
            margin-top: -20px;
        }

        #settings-delete-mobile-empleados {
            display: inherit;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    @media screen and (max-width: 470px) {
        #tabla-empleados {
            padding: 10px;
            height: 440px;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }

        #tabla-entradas-salidas {
            padding: 10px;
            height: 300px;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    @media screen and (max-width: 470px) {
        #nombre-empleado, #apellido-empleado, #telefono-empleado, 
        #direccion-empleado, #dni-empleado, #email-empleado, 
        #password-empleado, #posicion-empleado {
            width: 120px;
        }
    }

    @media screen and (max-width: 320px) {
        #nombre-empleado, #apellido-empleado, #telefono-empleado, 
        #direccion-empleado, #dni-empleado, #email-empleado, 
        #password-empleado, #posicion-empleado {
            width: 105px;
        }
    }

</style>