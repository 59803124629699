<template>
  <footer>
    <div v-if="this.$store.state.isAuthenticated">
      <div id="copyright">
        <p>&copy; Tijeras Gestion {{ new Date().getFullYear() }}</p>
      </div>
    </div>
    <div id="footer-div" v-else>
      <div id="inner-footer-div">
        <div id="col1">
          <p id="titulo-horario">HORARIO</p>
          <div v-for="hora in horario" id="dia-hora-horario-div">
            <p id="dia-horario-footer">
              {{ hora.dia }}
            </p>
            <p id="hora-horario-footer">
              {{ hora.horario }}
            </p>
          </div>
        </div>

        <div id="col2">
          <p id="titulo-encontranos">ENCONTRANOS</p>
          <div id="encontranos-map-div">
            <div id="telefono-direccion-footer-div">
              <p id="phone">Tel. xx-xxxx-xxxx</p>
              <div id="address">
                <a href="https://www.google.com/maps/place/Obelisco/@-34.6037295,-58.3818188,20.17z/data=!4m5!3m4!1s0x4aa9f0a6da5edb:0x11bead4e234e558b!8m2!3d-34.6037389!4d-58.3815704" target="_blank">
                    Av. Ejemplo 4041
                </a>
                <p>C.A.B.A., Buenos Aires, Argentina.</p>
                <a href="https://www.instagram.com/tijerasgestion/" target="_blank">
                  <img id="logo-insta" src="@/assets/images/insta.png" alt="Instagram">
                </a>
                <a href="#" target="_blank">
                  <img id="logo-insta" src="@/assets/images/face.png" alt="Facebook">
                </a>
                <a href="https://wa.me/5492235110667/?text=Hola!%20me%20gustaria%20tener%20informacion%20acerca%20del%20sistema%20de%20gestion.%20Gracias!" target="_blank">
                  <img id="logo-insta" src="@/assets/images/whatsapp.png" alt="Whatsapp">
                </a>
              </div>
            </div>
      
            <div id="map-image">
              <a href="https://www.google.com/maps/place/Obelisco/@-34.6037295,-58.3818188,20.17z/data=!4m5!3m4!1s0x4aa9f0a6da5edb:0x11bead4e234e558b!8m2!3d-34.6037389!4d-58.3815704" target="_blank">
                  <img id="img-map" src="@/assets/images/map-grayscale.png" alt="map">
              </a>
            </div> 
          </div> 
        </div>
        
        <div id="copyright-cliente">
          <img src="@/assets/images/tijeras-logo.png" alt="">
          <p>&copy; {{ new Date().getFullYear() }}</p>
        </div>
      </div>
      
    </div>
  </footer>

</template>

<script>
import axios from 'axios'

export default {
  name: 'FooterComp',
  data() {
    return {
      horario:[]
    }
  },
  mounted() {
    if (!this.$store.state.isAuthenticated) {
      this.getHorario()
    }
  },
  components: {},
  methods: {
    async getHorario() {
      this.$store.commit('setIsLoading', true)
      await axios
          .get('/api/v1/comercio/horario/')
          .then(response => {
              for (let i = 0; response.data.length > i; i++) {
                let horario = ''
                let dia = response.data[i]['dia'].toLowerCase()
                if (response.data[i]['am_apertura'] == '00:00' && response.data[i]['pm_apertura'] != '00:00'){
                  horario = response.data[i]['pm_apertura'] + '-' + response.data[i]['pm_cierre'] + ' Hs'
                }
                else if (response.data[i]['am_apertura'] != '00:00' && response.data[i]['am_cierre'] == '00:00' && response.data[i]['pm_apertura'] == '00:00'){
                  horario = response.data[i]['am_apertura'] + '-' + response.data[i]['pm_cierre'] + ' Hs'
                }
                else if (response.data[i]['am_cierre'] != '00:00' && response.data[i]['pm_apertura'] == '00:00'){
                  horario = response.data[i]['am_apertura'] + '-' + response.data[i]['am_cierre'] + ' Hs'
                }
                else if (response.data[i]['am_apertura'] == '00:00' && response.data[i]['pm_apertura'] == '00:00'){
                  horario = 'Cerrado'
                }
                else{
                  horario = response.data[i]['am_apertura'] + '-' + response.data[i]['am_cierre'] + ' Hs ' + ' / ' + 
                            response.data[i]['pm_apertura'] + '-' + response.data[i]['pm_cierre'] + ' Hs'
                }
                this.horario.push(
                  {
                    dia:dia[0].toUpperCase()+dia.substring(1),
                    horario:horario
                  }
                )
              }
          })
          .catch(error => {
              console.log(error)
          })
      this.$store.commit('setIsLoading', false)
    },
  }
}
</script>


<style>
  footer {
    text-align: center;
    color: white;
  }

  #footer-div {
    background-color: rgb(14, 14, 14);
  }

  footer p {
    margin: 5px 0 5px 0;
  } 

  footer a {
    color: rgb(255, 255, 255);
  }

  #titulo-horario,
  #titulo-encontranos {
    font-size: 25px;
    color: rgb(255, 255, 255);
    letter-spacing: .1em;
    font-weight: bold;
    margin-top: 10px;
  }

  #col1, #col2{
    display: inline-table;
    margin-left: 20px;
    margin-right: 20px;
    max-width: 700px;
    text-align: center;
  }

  #encontranos-map-div {
    display: flex;
    align-items: center;
  }

  #dia-hora-horario-div {
    margin: 5px 0 5px 0;
  }

  #dia-horario-footer,
  #hora-horario-footer {
    display: inline-table;
    margin: 0 20px 0 20px;
  }

  #dia-horario-footer {
    float: left;
  }

  #telefono-direccion-footer-div,
  #map-image-footer-div {
    display: inline-block;
    margin: 0 20px 0 20px;
  }

  #img-map {
    display: block;
    width: 350px;
    height: auto;
  }

  #copyright {
    text-align: center;
    margin: 50px auto 0 auto;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    width: 200px;
  }

  #copyright p {
    font-size: 18px;
    font-weight: 500;
    color: rgb(0, 0, 0);
  }

  #copyright-cliente {
    text-align: center;
    color: white;
  
  }
  #copyright-cliente img {
    width: 150px;
    margin-bottom: -30px;
  }

  #copyright-cliente p {
    margin-bottom: 0;
    font-size: 15px;
    color: rgb(255, 255, 255);
  }

  #logo-insta {
    width: 40px;
    filter: invert(100%);
    margin: 0 5px 0 5px;
  }


  @media screen and (min-width: 690px) and (max-width: 1050px) {
    #dia-horario-footer, #hora-horario-footer {
      display: inline-table;
      margin: 0 20px 0 20px;
    }


  }

  @media only screen and (max-width: 690px) {

    #col2 {
      display: inherit;
      margin-left: 20px;
      margin-right: 20px;
      text-align: center;
    }

    footer #h2{
      font-size: 23px;
      margin-bottom: 0;
    }

    #dia-horario-footer, #hora-horario-footer {
      font-size: 16px;
      margin: 0 10px 0 10px;
    }

    footer p, footer a {
      font-size: 16px;
    }

    #encontranos-map-div {
      display: inline;
    }

    #img-map {
      width: 60%;
      margin: auto;
    }

    #map-image #img {
      width: 100%;
    }

    #logo-insta {
      width: 30px;
    }   
  }

  @media only screen and (max-width: 370px) {
    #dia-horario-footer, #hora-horario-footer {
      font-size: 15px;
      margin: 0 5px 0 5px;
    }
  }

</style>