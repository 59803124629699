<template>
    <div class="gestion-configuracion-view">
        <div id="datos-horario-tienda">
            <div>
                <h1>HORARIO TIENDA</h1>
                <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                    <div class="lds-dual-ring"></div>
                </div>
                <div id="horario-tabla-div">
                    <div id="table-wrapper-horario">
                        <div id="tabla-horario">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>APERTURA AM</th>
                                        <th>CIERRE AM</th>
                                        <th>APERTURA PM</th>
                                        <th>CIERRE PM</th>
                                    </tr> 
                                </thead>

                                <tbody>
                                    <tr
                                        v-for="dia, index in horarioLaboral" :key="dia.id">
                                        <td data-label="DIA">{{ dia.dia[0] }}{{ dia.dia[1] }}{{ dia.dia[2] }}</td>
                                        <td data-label="APERTURA AM">                                           
                                            <Datepicker 
                                                id="apertura-am"
                                                dark
                                                :placeholder="dia.am_apertura"
                                                time-picker 
                                                v-model="am.apertura[index].hora"
                                                minutes-increment="30"
                                                :start-time="startTime" 
                                                auto-apply
                                                hide-input-icon
                                            />
                                        </td>
                                        <td data-label="CIERRE AM">
                                            <Datepicker 
                                                id="cierre-am"
                                                dark
                                                :placeholder="dia.am_cierre"
                                                time-picker 
                                                v-model="am.cierre[index].hora"
                                                minutes-increment="30"
                                                :start-time="startTime" 
                                                auto-apply
                                                hide-input-icon
                                            />
                                        </td>
                                        <td data-label="APERTURA PM">
                                            <Datepicker 
                                                id="apertura-pm"
                                                dark
                                                :placeholder="dia.pm_apertura"
                                                time-picker 
                                                v-model="pm.apertura[index].hora" 
                                                minutes-increment="30"
                                                :start-time="startTime" 
                                                auto-apply
                                                hide-input-icon
                                            />
                                        </td>
                                        <td data-label="CIERRE PM">
                                            <Datepicker 
                                                id="cierre-pm"
                                                dark
                                                :placeholder="dia.pm_cierre"
                                                time-picker 
                                                v-model="pm.cierre[index].hora"
                                                minutes-increment="30"
                                                :start-time="startTime" 
                                                auto-apply
                                                hide-input-icon
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <button id="boton-horario" @click="actualizarHorario">ACTUALIZAR</button>
            </div>
            <button id="boton-horario-mobile" @click="actualizarHorario">ACTUALIZAR</button>
        </div>       

        <div id="dias-cerrado-div">
            <h1>Dias Cerrado</h1>
            <div id="dias-cerrado">
                <div v-for="dia in diasCerrado" :key="dia.id" id="dia-div">
                    <label id="label-dia-nombre" for="dia">{{ dia.dia_corto }}</label>
                    <input type="checkbox" id='dia' :value="dia.numero" v-model="dia.cerrado">   
                </div>
                <button id="boton-dias-cerrado" @click="actualizarDiasCerrado">ACTUALIZAR</button>
            </div>
        </div>

        <div id="duracion-turno-div">
            <h1>DURACION TURNO</h1>
            <div id="duracion-div">
                <div class="field field_v2">
                    <label for="duracion-turno" class="ha-screen-reader">Minutos</label>
                    <input v-model="duracionTurno" id="duracion-turno" class="field__input" placeholder="ej. 30" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Minutos</span>
                    </span>
                </div>    
                <span v-if="error.duracion"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ error.duracion }}</p></span>
            </div>
            <button id="boton-duracion-turno" @click="actualizarDuracionTurno">ACTUALIZAR</button>
        </div>
        
    </div>
</template>

<script>
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';

const startTime = ref({ hours: 0, minutes: 0 });

export default{
    name: 'gestionConfiguracionView',
    data() {
        return {
            startTime,
            diasCerrado:[],
            duracionTurno:'',
            error:{
                duracion:'',
            },
            horarioLaboral:[],
            am:{
                apertura:[
                    {
                        dia:'LUNES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'MARTES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'MIERCOLES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'JUEVES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'VIERNES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'SABADO',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'DOMINGO',
                        hora_guardada:'',
                        hora:''
                    }
                ],
                cierre:[
                    {
                        dia:'LUNES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'MARTES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'MIERCOLES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'JUEVES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'VIERNES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'SABADO',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'DOMINGO',
                        hora_guardada:'',
                        hora:''
                    }
                ],
            },
            pm:{
                apertura:[
                    {
                        dia:'LUNES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'MARTES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'MIERCOLES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'JUEVES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'VIERNES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'SABADO',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'DOMINGO',
                        hora_guardada:'',
                        hora:''
                    }
                ],
                cierre:[
                    {
                        dia:'LUNES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'MARTES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'MIERCOLES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'JUEVES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'VIERNES',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'SABADO',
                        hora_guardada:'',
                        hora:''
                    },
                    {
                        dia:'DOMINGO',
                        hora_guardada:'',
                        hora:''
                    }
                ],
            }
        }
    },
    components: { 
        Datepicker
    },
    mounted() {
        document.title = 'Ajustes tienda | Tijeras Gestion';
        this.setBgBody();
        this.getComercioDatos();
    },
    methods: {
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-gestion)");
            document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
        },
        async actualizarDuracionTurno() {
            this.$store.commit('setIsLoading', true)
            const data = {
                minutos:this.duracionTurno
            }

            await axios
                .post('/api/v1/comercio/actualizar-duracion-turno/', data)
                .then(response => {
                    if (response.data == 'duracion turno actualizada') {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'DURACION TURNO ACTUALIZADA',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }      
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'LA DURACION TURNO NO PUDO SER ACTUALIZADA',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
            this.$store.commit('setIsLoading', false)
        },
        async actualizarDiasCerrado() {
            this.$store.commit('setIsLoading', true)
            const data = {
                dias:this.diasCerrado
            }

            await axios
                .post('/api/v1/comercio/actualizar-dias-cerrado/', data)
                .then(response => {
                    if (response.data == 'dias actualizados') {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'DIAS CERRADO ACTUALIZADO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }
                    
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'DIAS CERRADO NO PUDO SER ACTUALIZADO',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
            this.$store.commit('setIsLoading', false)
        },
        async actualizarHorario() {
            this.$store.commit('setIsLoading', true)

            const data = {
                am_apertura:this.am.apertura,
                am_cierre:this.am.cierre,
                pm_apertura:this.pm.apertura,
                pm_cierre:this.pm.cierre
            }

            await axios
                .post('/api/v1/comercio/actualizar-horario/', data)
                .then(response => {
                    if (response.data == 'horario actualizado') {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'HORARIO ACTUALIZADO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    }      
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'EL HORARIO NO PUDO SER ACTUALIZADO',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
            this.$store.commit('setIsLoading', false)
        },
        getDateFromHours(time) {
            time = time.split(':');
            let now = new Date();
            let date = new Date(now.getFullYear(), now.getMonth(), now.getDate(),time)
            return `${date.getHours()}:${date.getMinutes()}`
        },
        async getComercioDatos() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/comercio/datos')
                .then(response => {
                    this.horarioLaboral = response.data['horario_laboral']
                    this.diasCerrado = response.data['dias_cerrado']
                    this.duracionTurno = response.data['duracion_turno']['minutos']
                })
                .catch(error => {
                    if (error.response.status === 401){
                        localStorage.removeItem("token")
                        localStorage.removeItem("username")
                        localStorage.removeItem("userid")
                        this.$store.commit('removeToken')
                        this.$router.push('/gestion')
                    }
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        handleChange(e) {
            if(e.target.options.selectedIndex > -1) {
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "servicio"){
                    this.cliente.servicioId = e.target.options[e.target.options.selectedIndex].dataset.id
                    this.getEmpleados()
                }
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "empleado-turno"){
                    this.cliente.empleadoId = e.target.options[e.target.options.selectedIndex].dataset.id 
                    this.noTrabaja = e.target.options[e.target.options.selectedIndex].dataset.dias.split(',')
                    this.vacaciones = []
                    let vacaciones = e.target.options[e.target.options.selectedIndex].dataset.vacaciones
                    let jsonVacaciones = JSON.parse(vacaciones)

                    if (jsonVacaciones.length > 1) {
                        for (let i = 0; jsonVacaciones.length > i; i++) {
                            let fechas = jsonVacaciones[i]['fecha']
                            this.getDatesInRange(fechas[0].trim(), fechas[1].trim())
                        }
                    }else {
                        let fechas = jsonVacaciones[0]['fecha']
                        this.getDatesInRange(fechas[0].trim(), fechas[1].trim())
                    } 
                } 
            }
        },
    }
}
</script>

<style>
.gestion-configuracion-view {
    padding-top: 180px;
    color: white;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
}

#crear-categoria-servicio-div h1,
#crear-servicio-configuracion-div h1,
#datos-dias-cerrado h1,
#datos-horario-tienda h1 {
    text-shadow: var(--text-shadow);
}

#datos-servicio-configuracion {
    margin: 0 20px 50px 20px;
}

#datos-crear-categoria-tabla {
    margin: 0 20px 0 20px;
}

#crear-categoria-servicio-div {
    max-width: 350px;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
    margin: 0 auto 50px auto;
}

#crear-servicio-configuracion-div {
    max-width: 620px;
    min-height: 395px;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
}

#configuracion-categoria-servicio-div,
#configuracion-nombre-servicio-div, 
#configuracion-precio-servicio-div, 
#configuracion-turnos-servicio-div {
    display: inline-table;
    width: 130px;
    margin: 10px 10px 0 10px;
}

#categoria-servicio-div {
    margin: 10px auto 0 auto;
}

#categoria-servicio-inner {
    border-bottom: 2px solid var(--fieldBorderColor);
}

#categoria-servicio-nombre,
#nombre-servicio,
#precio-servicio,
#turnos-servicio,
#duracion-turno {
    margin-bottom: 13px;
}

#boton-crear-categoria,
#boton-configuracion-servicio, 
#boton-duracion-turno,
#boton-dias-cerrado,
#boton-horario-mobile, 
#boton-horario {
    background-color: var(--button-color);
    border: none;
    color: white;
    width: 90%;
    margin-top: 10px;
}

#servicios-configuracion-tabla-div,
#crear-categoria-tabla-div {
    margin-top: 10px;
}

#servicios-configuracion-tabla-div h1,
#crear-categoria-tabla-div h1 {
    width: 160px;
    margin: 0 auto 20px auto;
    text-shadow: var(--text-shadow);
}

#table-wrapper-configuracion,
#table-wrapper-crear-categoria {
    position:relative;
}

#tabla-crear-categoria {
    max-height:373px;
    width: 240px;
    color: white;
    overflow:auto;  
    margin-left: auto;
    margin-right: auto;
}

#trash-crear-categoria {
    width: 20px;
    filter: invert(100%);
}

#trash-servicio-configuracion {
    width: 20px;
    filter: invert(100%);
}

#settings-servicio-configuracion {
    width: 23px;
    filter: invert(100%);
    margin-right: 10px;
}

#trash-servicio-configuracion-mobile {
    display: none;
}

#duracion-turno-div, #dias-cerrado-div {
    max-width: 320px;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
    margin: 0 20px 0 20px;
}

#duracion-turno-div {
    margin-bottom: 50px;
}

#duracion-div {
    width: 100px;
    margin: 10px auto 0 auto;
}

#dias-cerrado {
    margin-top: 10px;
}

#datos-horario-tienda {
    max-width: 550px;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
}

#datos-horario-tienda { 
    margin: 0 20px 50px 20px;
}

#tabla-horario {
    margin-top: 20px;
}

#tabla-horario table tbody tr:hover {
  background-color: transparent;
}

#tabla-horario table {
    width: 100%;
}

#tabla-horario table thead {
    line-height: 0;
}

#tabla-horario table tr th {
    position: sticky;
    top: 0;
    background-color: transparent;
    border: none;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    padding: 10px;
    text-shadow: none;
}

#tabla-horario table tr td {
    font-size: 20px;
    letter-spacing: .1em;
    text-transform: uppercase;
    padding: 10px;
}

#apertura-am, #cierre-am,
#apertura-pm, #cierre-pm {
    background-color: transparent;
    width: 68px;
    border-bottom: 2px solid var(--fieldBorderColor);
    margin: 0 auto 0 auto;
}

#apertura-am .dp__clear_icon,#cierre-am .dp__clear_icon,
#apertura-pm .dp__clear_icon, #cierre-pm .dp__clear_icon {
    display: none;
}

#apertura-am input, #cierre-am input,
#apertura-pm input, #cierre-pm input {
    height: 38px;
    text-align: center;
    font-family: 'Khand';
    color: white;
    font-size: 18px;
    background-color: transparent;
    border: none;
}

#datos-dias-cerrado {
    display: inline-table;
    margin: 0 20px 50px 20px;
}

#boton-horario-mobile {
    display: none;
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {

}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .gestion-configuracion-view {
        padding-top: 130px;
    }

    #tabla-crear-categoria {
        height: 200px;
    }
}

@media screen and (max-width: 600px) {

    .gestion-configuracion-view {
        padding-top: 130px;
    }

    #datos-servicio-configuracion{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    #servicios-configuracion-tabla-div {
        margin-top: 20px;
    }

    #icons-configuracion-servicio, #trash-servicio-configuracion {
        display: none;
    }

    #trash-servicio-configuracion-mobile {
        width: 24px;
        filter: invert(100%);
    }

    #trash-servicio-configuracion-mobile {
        display: inherit;
        margin: 0 auto 0 auto;
    }

    #tabla-crear-categoria {
        height: 200px;
    }


    #duracion-turno-div, #dias-cerrado-div {
        margin: 0 auto 50px auto;
    }

    #tabla-horario {
        width: 100%;
    }

    #tabla-horario thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    #tabla-horario {
        margin-top: 0; 
    }

    #tabla-horario tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    #tabla-horario table tr td {
        border-bottom: none;
        display: block;
        font-size: 18px;
        text-align: right;
    }

    #tabla-horario td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }


    #tabla-horario td:last-child {
        border-bottom: 0;
    }

    #horario-tabla-div::-webkit-scrollbar {
        display: none;
    }

    #horario-tabla-div::-webkit-scrollbar-thumb {
        display: none;
    }

    #datos-horario-tienda {
        width: 85%;
    }

    #horario-tabla-div {
        max-height:300px;
        overflow:auto;  
    }

    #apertura-am, #cierre-am, #apertura-pm, #cierre-pm {
        margin: 0 0 0 auto;
        text-align: right;
    }

    #boton-horario {
        display: none;
    }

    #boton-horario-mobile {
        display: inherit;
        margin: 10px auto 0 auto;
    }
}

</style>