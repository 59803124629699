<template>
    <div id="total-col">
      <transition name="bounce">
        <div id="tabla-inicio-servicios" v-if="trabajo.servicios.length">
          <div id="table-wrapper">
            <div id="tabla-home">
              <table class="table" id="servicios-agregados">
                <thead>
                  <tr>
                    <th></th>
                    <th>SERVICIO(S)</th>
                    <th>PRODUCTO(S)</th>
                  </tr>
                </thead>
                
                <tbody>
                  <tr
                    v-for="servicio  in trabajo.servicios"
                    :key="servicio.id">
                    <td><img id="trash-home" src="@/assets/images/trash.png" alt="trash-icon" @click="remove" data-info="servicio" :data-id="servicio.id"></td>
                    <td>{{ servicio.nombre }}</td>
                    <tr v-for="producto in servicio.productos" :key="producto.id">
                      <td><img id="trash-home" src="@/assets/images/trash.png" alt="trash-icon" @click="remove" data-info="producto" :data-id="producto.id"> {{ producto.producto }} {{ producto.cantidad }} {{ producto.unidad }}(S)</td>
                    </tr> 
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
          <div id="showPrice" v-if="showPrice">     
            <p>SUBTOTAL SERVICIO(S): <span>${{ this.trabajo.data.subtotal }}</span></p>       
          </div>

          <div id="showTotal" v-if="showTotal">
            <p>SUBTOTAL SERVICIO(S): <span>${{ this.trabajo.data.total }}</span></p>       
          </div>

        </div>
      </transition>
      
      <transition name="bounce">
        <div id="tabla-inicio-tienda" v-if="tienda.productos.length">
          <div id="table-wrapper">
            <div id="tabla-home-tienda">
              <table class="table" id="items-agregados">
                <thead>
                  <tr>
                    <th></th>
                    <th>ITEM(S)</th>
                    <th>CANTIDAD</th>
                    <th>PRECIO</th>
                  </tr>
                </thead>
                
                <tbody>
                  <tr v-for="producto in tienda.productos" :key="producto.id">
                    <td><img id="trash-home" src="@/assets/images/trash.png" alt="trash-icon" @click="remove" data-info="tienda" :data-id="producto.id"></td>
                    <td>{{ producto.producto }}</td>
                    <td>{{ producto.cantidad }}</td>
                    <td>${{ producto.precio }}</td>
                  </tr> 
                
                </tbody>
              </table>
            </div>
          </div>
      
          <div id="showPriceTienda" v-if="showPriceTienda">    
            <p>SUBTOTAL TIENDA: <span>${{ this.tienda.subtotal }}</span></p>   
          </div>

          <div id="showTotalTienda" v-if="showTotalTienda">
            <p>SUBTOTAL TIENDA: <span>${{ this.tienda.total }}</span></p>
          </div>
          
        </div>
      </transition>

      <div v-if="sumaTotal > 0" id="total-pagar-medio">
        <div>
          <h1>TOTAL: ${{ sumaTotal }}</h1>
        </div>
        <div id="boton-guardar-medio">
          <button id="button-add" @click="check">GUARDAR</button>
        </div>
      </div>
    </div>
</template>
  
<script>

export default {
  name: 'TotalColumn',
  props: [
    'trabajo', 'tienda', 'showPrice', 'showTotal', 
    'showPriceTienda', 'showTotalTienda', 'sumaTotal', 
    'check', 'remove'
  ],
  data() {
    return {

    }
  },
  methods: {
    
  }
}

</script>
  
<style>
.bounce-enter-active {
  animation: bounce-in 0.8s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.10);
  }
  100% {
    transform: scale(1);
  }
}
#total-col {
  margin-left: auto;
  margin-right: auto;
}

#table-wrapper {
  width: 100%;
  position:relative;

}
#tabla-home table tbody tr:hover,
#tabla-home-tienda table tbody tr:hover {
  background-color: #55555500;
}

#tabla-home, #tabla-home-tienda{
  max-width: 500px;
  border-radius: 4px;
  box-shadow: 0px 33px 22px 0px #000000;
  color: white;
  overflow:auto;  
  width: 100%;
  max-height:200px;
  margin-bottom: 10px;
  background: var(--bg-box-black);
  background-position: center center;
  background-size: cover;
}

#tabla-home-tienda {
  margin-top: 30px;
}

#table-wrapper table {
  width:100%;
}

tbody td{
  border-bottom: none;
}

#productos-tabla {
  margin-top: 5px;
}

#productos-tabla::-webkit-scrollbar {
  height: 5px;
	background-color: #000000;
}

#productos-tabla::-webkit-scrollbar-thumb, 
#tabla-home::-webkit-scrollbar-thumb,
#tabla-home-tienda::-webkit-scrollbar-thumb  {
	background-color: #555;
  border-radius: 4px;
}

#tabla-home::-webkit-scrollbar,
#tabla-home-tienda::-webkit-scrollbar {
  width: 5px;
  height: 5px;
	background-color: #35353500;
}

#tabla-home thead tr th,
#tabla-home-tienda thead tr th {
  position: sticky;
  top: 0;
  background-color: rgb(14, 14, 14);
  border: none;
  z-index: 1;
  text-align: center;
  padding: 10px 10px 10px 10px;
}

#tabla-home table tr td,
#tabla-home-tienda table tr td {
  font-size: 17px;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
}

#trash-home{
  width: 17px;
  cursor: pointer;
  filter: invert(100%);
  margin-left: 10px;
}

#showPrice p, #showTotal p, #showPriceTienda p, #showTotalTienda p{
  color: rgb(255, 255, 255);
  font-size: 25px;
  font-weight:500;
  margin-left: 10px;
  margin-right: 10px;
}

#showPrice, #showPriceTienda, #showPriceTienda , #showTotalTienda {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-10&color=000000);
  margin-top: 50px;
}

#showPrice span, #showPriceTienda span, #showTotal span, #showTotalTienda span {
  color:#ff7fff;
  font-size: 30px;
}

#productos-agregados , #servicios-agregados {
  display: inline-table;
}

#row-precio-descuento {
  margin-top: -20px;
}

#showPrecio, #showTotal, #showTotalTienda, #showTotalTienda {
  color: rgb(155, 155, 155);
  margin: -20px, 0px, -20px, 0px;
}

#total-pagar-medio h1 {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-10&color=#000000);
  border-radius: 4px;
  color: #ff3bff;
  margin-top: -10px;
  margin-bottom: -10px;
}

@media screen and (min-width: 1130px) and (max-width: 1550px) {
  #tabla-inicio-servicios,
  #tabla-inicio-tienda {
    display: inline-table;
    margin: 50px 30px 0 30px;
    width: 500px;
  }

  #tabla-home-tienda {
    margin-top: 0;
  }
}


@media screen and (min-width: 700px) and (max-width: 1130px) {
  #total-col #table-wrapper {
    width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 700px) {
  #total-col #table-wrapper {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
}
  
</style>