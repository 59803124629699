<template>
    <div class="gestion-turnos-view">
        <div id="col-1-turnos">
            <h1>CREAR TURNO</h1>
            <div id="servicio-turno-div">
                <div id="servicio-turno">
                    <select id="servicio-t" class="select-style" @change="handleChange" v-model="servicioTurnoSelect">
                    <option value="" disabled hidden>Servicio</option>
                    <option v-for="servicio in servicios" :key="servicio.id" data-info="servicio" :data-id="servicio.id" :data-precio="servicio.precio">{{ servicio.nombre }}</option>
                    </select>
                </div>
                <span v-if="errors.service"><p class="error-select" style="color: red; height: 0;">{{ errors.service }}</p></span>
            </div>

            <div id="empleado-turno-div">
                <div id="empleado-turno">
                    <select id="empleado-t" class="select-style" @change="handleChange" v-model="empleadoTurnoSelect">
                        <option value="" disabled hidden>Emplead@</option>
                        <option v-for="empleado in empleados" :key="empleado" data-info="empleado-turno" 
                            :data-posicion="empleado.posicion" :data-id="empleado.id" :data-dias="empleado.get_no_trabaja"
                            :data-vacaciones="empleado.vacaciones_list">
                                {{ empleado.nombre }} {{ empleado.apellido }}
                        </option>
                    </select>
                </div>
                <span v-if="errors.employee"><p class="error-select" style="color: red; height: 0;">{{ errors.employee }}</p></span>
            </div>

            <div id="fecha-turno-div">
                <div id="fecha-turno">
                    <Datepicker 
                        dark
                        auto-apply
                        hide-input-icon
                        hide-offset-dates
                        placeholder="Fecha"
                        v-model="cliente.fecha"
                        :enable-time-picker="false"
                        :format="format" 
                        locale="es" 
                        cancelText="Cancelar" 
                        selectText="Seleccionar" 
                        id="fecha"
                        @cleared="limpiar"
                        position="center"
                        :disabled-week-days="noTrabaja"
                        :disabled-dates="vacaciones"
                        :year-range="[new Date().getFullYear()]"
                    />      
                </div>
                <span v-if="errors.date"><p class="error-select" style="color: red; height: 0;">{{ errors.date }}</p></span>
            </div>
            
            <div id="nombre-turno">
                <div class="field field_v2">
                    <label for="nombre-t" class="ha-screen-reader">Nombre</label>
                    <input v-model="cliente.nombre"  id="nombre-t" class="field__input" placeholder="ej. Lorena" autocomplete="off">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Nombre</span>
                    </span>
                </div>    
                <span v-if="errors.name"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errors.name }}</p></span>
            </div>

            <div id="apellido-turno">
                <div class="field field_v2">
                    <label for="apellido-t" class="ha-screen-reader">Apelllido</label>
                    <input v-model="cliente.apellido"  id="apellido-t" class="field__input" placeholder="ej. Lorena" autocomplete="off">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Apellido</span>
                    </span>
                </div>     
                <span v-if="errors.lastname"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errors.lastname }}</p></span>
            </div>  

            <div id="telefono-turno">
                <div class="field field_v2">
                    <label for="telefono-t" class="ha-screen-reader">Telefono</label>
                    <input v-model="cliente.telefono" onkeypress="return event.charCode >= 48 && event.charCode <= 57" id="telefono-t" class="field__input" placeholder="ej. 114123456" autocomplete="off">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Telefono</span>
                    </span>
                </div> 
                <span v-if="errors.phone"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errors.phone }}</p></span>
            </div>
            

            <div id="boton-buscar-turno-gestion" v-if="mostrarHoras">
                <button @click="buscarTurno">MOSTRAR HORAS DISPONIBLES</button>
            </div>

            <div id="horas-disponible-turnos" v-if="horasDisponibles">
                <button id="boton-hora" class="ripple" @click="seleccionarTurno" v-for="hora in horasDisponibles" :key="hora" :value="hora">{{ hora }}</button> 
            </div> 

            <div id="reservar-turno-text" v-if="reservarTexto">
                <h1> RESERVAR TURNO PARA {{ cliente.fecha.toLocaleDateString('es-ES', options) }} {{ cliente.hora }}HS?</h1>
                <button id="boton-cambiar-hora" @click="cambiarReserva">CAMBIAR HORA</button>
                <button id="boton-reservar" @click="crear">CREAR</button>
            </div>
        </div>

        <transition namr="fade">
            <div id="col-2-turnos" v-if="turnos.length > 0">
                <div id="table-wrapper">
                    <div id="tabla-turnos">
                        <table>
                            <thead>
                                <tr>
                                    <th id="th-eliminar-turnos"></th>
                                    <th>FECHA</th>                                 
                                    <th>HORA</th>
                                    <th>SERVICIO</th>
                                    <th>APELLIDO</th>
                                    <th>NOMBRE</th>
                                    <th>TELEFONO</th>
                                    <th>EMPLEADO</th>  
                                </tr> 
                            </thead>

                            <tbody>
                                <tr
                                    v-for="turno in turnos"
                                    :key="turno.id">
                                    <td id="delete-icon-turno"><img id="trash-reserva" src="@/assets/images/trash.png" alt="trash-icon" @click="removeTurno" title="Eliminar" data-info="turno-tabla" :data-id="turno.id"></td>
                                    <td data-label="FECHA">{{ turno.get_fecha }}</td>                                
                                    <td data-label="HORA">{{ turno.get_hora }} HS</td>
                                    <td data-label="SERVICIO">{{ turno.servicio }}</td>
                                    <td data-label="APELLIDO">{{ turno.apellido }}</td>
                                    <td data-label="NOMBRE">{{ turno.nombre }}</td>
                                    <td data-label="TELEFONO">{{ turno.telefono }}</td>
                                    <td data-label="EMPLEADO">{{ turno.empleado }}</td>
                                    <div id="trash-mobile-icon">
                                        <img id="trash-reserva-mobile" src="@/assets/images/trash.png" alt="trash-icon" @click="removeTurno" data-info="turno-tabla" :data-id="turno.id">
                                    </div>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="row">
                        <div id="anterior-siguiente">
                            <p id="anterior" @click="decrease" data-info="tienda" v-if="contador > 1">
                                <img src="@/assets/images/siguiente.png" alt="">
                            </p>
                            <p id="siguiente" @click="increase" data-info="tienda" v-if="turnos.length > 20">
                                <img src="@/assets/images/siguiente.png" alt="">
                            </p>                             
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    
        <transition namr="fade">
            <div class="row" id="reservas" v-if="reservas.length > 0">
                <h1>RESERVAS ONLINE</h1>
                <div id="table-wrapper">
                    <div id="tabla-reservas">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>FECHA</th>
                                    <th>HORA</th>
                                    <th>SERVICIO</th>
                                    <th>APELLIDO</th>
                                    <th>NOMBRE</th>
                                    <th>TELEFONO</th>
                                    <th>EMPLEADO</th>  
                                </tr> 
                            </thead>

                            <tbody>
                                <tr
                                    v-for="reserva in reservas"
                                    :key="reserva.id">
                                    <td id="delete-confirm-send">
                                        <img id="whatsapp-logo-reserva" src="@/assets/images/whatsapp-logo-white.png" alt="whatsapp-logo" title="Mandar WhatsApp" @click="mandarWhatsApp" :data-telefono="reserva.telefono" :data-fecha="reserva.get_fecha" :data-hora="reserva.get_hora">
                                        <img id="confirm" src="@/assets/images/confirm.png" alt="confirm-icon" title="Confirmar" @click="confirmarReserva" data-info="reserva-tabla" :data-id="reserva.id">
                                        <img id="trash-reserva" src="@/assets/images/trash.png" alt="trash-icon" title="Eliminar" @click="eliminarReserva" data-info="reserva-tabla" :data-id="reserva.id">
                                    </td>
                                    <td data-label="FECHA">{{ reserva.get_fecha }}</td>
                                    <td data-label="HORA">{{ reserva.get_hora }} HS</td>
                                    <td data-label="SERVICIO">{{ reserva.servicio }}</td>
                                    <td data-label="APELLIDO">{{ reserva.apellido }}</td>
                                    <td data-label="NOMBRE">{{ reserva.nombre }}</td>
                                    <td data-label="TELEFONO">{{ reserva.telefono }}</td>
                                    <td data-label="EMPLEADO">{{ reserva.empleado }}</td>
                                    <div id="botones-reserva">
                                        <img id="whatsapp-logo-reserva" src="@/assets/images/whatsapp-logo-white.png" alt="whatsapp-logo" title="Mandar WhatsApp" @click="mandarWhatsApp" :data-fecha="reserva.get_fecha" :data-hora="reserva.get_hora">
                                        <img id="confirm" src="@/assets/images/confirm.png" alt="confirm-icon" title="Confirmar" @click="confirmarReserva" data-info="reserva-tabla" :data-id="reserva.id">
                                        <img id="trash-reserva-mobile" src="@/assets/images/trash.png" alt="trash-icon" title="Eliminar" @click="eliminarReserva" data-info="reserva-tabla" :data-id="reserva.id">
                                    </div>
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> 
        </transition>
    </div>
</template>

<script>
import axios from 'axios'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { ref } from 'vue';

const date = ref(new Date());
// In case of a range picker, you'll receive [Date, Date]
const format = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}


export default {
    name: 'gestionTurnosView',
    data() {
        return {
            format,
            date,
            empleados:[],
            servicios:[],
            horasDisponibles:[],
            turnos:[],
            reservas:[],
            empleadoTurnoSelect:'',
            servicioTurnoSelect:'',
            contador:1,
            cliente: {
                fecha:'',
                nombre:'',
                apellido:'',
                telefono:'',
                empleadoId:'',
                servicioId:'',
                hora:''
            },
            errors:{
                name:'',
                lastname:'',
                phone:'',
                employee:'',
                date:'',
                service:''
            },
            noTrabaja:[],
            vacaciones:[],
            mostrarHoras:true,
            reservarTexto:false,
            options:{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        }
    },
    components: { 
        Datepicker
    },
    mounted() {
        document.title = 'Turnos | Tijeras Gestion' 
        this.getTurnos();
        this.getReservas();
        this.getServicios();
        this.getDiasCerrado();
        this.setBgBody();
    },
    methods: {
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-gestion)");
            document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
        },
        increase(e) { 
            this.contador++
            this.getTurnos()
        },
        decrease(e) { 
            this.contador-- 
            if (this.contador == 0) {
                this.contador = 1
            }
            this.getTurnos()
        },
        limpiar() {
            //console.log('limpio')
        },
        async getDiasCerrado() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/comercio/dias-cerrado/')
                .then(response => {
                    for (let i = 0;response.data.length > i; i++) {
                        if (response.data[i]['cerrado'] == true) {
                            let numero = response.data[i]['numero']
                            let existe;
                            if (numero >= 6){
                                numero = 0
                                existe = this.noTrabaja.includes(numero.toString())
                            }else{
                                numero = response.data[i]['numero'] + 1
                                existe = this.noTrabaja.includes(numero.toString())
                            }
                            if (existe == false) {
                                this.noTrabaja.push(numero.toString())
                            }
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getServicios() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/servicios/')
                .then(response => {
                    this.servicios = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getEmpleados() {
            this.$store.commit('setIsLoading', true)
            this.empleados = []
            const data = {
                id:this.cliente.servicioId
            }
            await axios
                .post('/api/v1/empleados/por-servicio/', data)
                .then(response => {
                    for (let i = 0; i < response.data.length; i++) {
                        if(response.data[i]['empleado']['posicion'] != 'ADMIN' && response.data[i]['empleado']['posicion'] != 'CAJER@') {
                            this.empleados.push(response.data[i]['empleado'])
                        }
                    }     
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getTurnos() {
            this.$store.commit('setIsLoading', true)
            let data = {
                contador:this.contador
            }
            await axios
                .post('/api/v1/turnos/', data)
                .then(response => {
                    if (response.data.length > 0) {
                        this.turnos = response.data
                    }else {
                        this.contador--
                    }
                })
                .catch(error => {
                    if (error.response.status === 401){
                        localStorage.removeItem("token")
                        localStorage.removeItem("username")
                        localStorage.removeItem("userid")
                        this.$store.commit('removeToken')
                        this.$router.push('/gestion')
                    }
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getReservas() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/reservas/')
                .then(response => {
                    if (response.data.length == 0) {
                        this.reservas = []
                    }
                    if (response.data.length > 0) {
                        this.reservas = response.data
                    }
                })
                .catch(error => {
                    if (error.response.status === 401){
                        localStorage.removeItem("token")
                        localStorage.removeItem("username")
                        localStorage.removeItem("userid")
                        this.$store.commit('removeToken')
                        this.$router.push('/gestion')
                    }
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        getDatesInRange(startDate, endDate) {
            const end = new Date(endDate.replaceAll('/','-'))
            const date = new Date(startDate.replaceAll('/','-'))
            
            date.setDate(date.getDate() + 1);
            end.setDate(end.getDate() + 1)

            while (date <= end) {
                this.vacaciones.push(new Date(date.getTime()) );
                date.setDate(date.getDate() + 1);
            }
        },
        handleChange(e) {
            if(e.target.options.selectedIndex > -1) {
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "servicio"){
                    this.cliente.servicioId = e.target.options[e.target.options.selectedIndex].dataset.id
                    this.getEmpleados()
                }
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "empleado-turno"){
                    this.cliente.empleadoId = e.target.options[e.target.options.selectedIndex].dataset.id 
                    let noTrabaja = e.target.options[e.target.options.selectedIndex].dataset.dias
                    
                    if (noTrabaja != ''){
                        this.noTrabaja = noTrabaja.split(',')
                    }else {
                        this.noTrabaja = []
                    }

                    this.vacaciones = []
                    let vacaciones = e.target.options[e.target.options.selectedIndex].dataset.vacaciones

                    if (vacaciones != '-') {
                        let jsonVacaciones = JSON.parse(vacaciones)

                        if (jsonVacaciones.length > 1) {
                            for (let i = 0; jsonVacaciones.length > i; i++) {
                                let fechas = jsonVacaciones[i]['fecha']
                                this.getDatesInRange(fechas[0].trim(), fechas[1].trim())
                            }
                        }
                        if (jsonVacaciones.length == 1){
                            let fechas = jsonVacaciones[0]['fecha']
                            this.getDatesInRange(fechas[0].trim(), fechas[1].trim())
                        } 
                    }   
                    this.getDiasCerrado()
                } 
            }
        },
        async buscarTurno(e) {
            this.$store.commit('setIsLoading', true)
          
            if (!this.cliente.servicioId) {
                this.errors.service = 'Requerido'
                setTimeout(() => this.errors.service = '', 2000)
            }
            if (!this.cliente.empleadoId) {
                this.errors.employee = 'Requerid@'
                setTimeout(() => this.errors.employee = '', 2000)
            }
            if (!this.cliente.fecha) {
                this.errors.date = 'Requerida'
                setTimeout(() => this.errors.date = '', 2000)
            }

            e.preventDefault();

            if (!this.errors.service && !this.errors.employee && !this.errors.date) {     
                const data = {
                    fecha:this.cliente.fecha,
                    servicio:this.cliente.servicioId,
                    empleado:this.cliente.empleadoId
                }
                await axios
                    .post('/api/v1/turnos/buscar/', data)
                    .then(response => {
                        this.horasDisponibles = response.data[0]['horas']
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            this.$store.commit('setIsLoading', false)
        },
        async seleccionarTurno(e) {
            this.cliente.hora = e.target.value
            this.horasDisponibles = []
            this.mostrarHoras = false
            this.reservarTexto = true
        },
        cambiarReserva(e) {
            this.reservarTexto = false
            this.mostrarHoras = true
            this.buscarTurno(e)
        },
        async crear(e) {
            this.$store.commit('setIsLoading', true)

            if (!this.cliente.fecha) {
                this.errors.date = 'Requerida'
                setTimeout(() => this.errors.date = '', 2000)
            }
            if (!this.cliente.empleadoId) {
                this.errors.employee = 'Requerid@'
                setTimeout(() => this.errors.employee = '', 2000)
            }
            if (!this.cliente.servicioId) {
                this.errors.service = 'Requerid@'
                setTimeout(() => this.errors.service = '', 2000)
            }
            if (!this.cliente.nombre) {
                this.errors.name = 'Requerido'
                setTimeout(() => this.errors.name = '', 2000)
            }
            if (!this.cliente.apellido) {
                this.errors.lastname = 'Requerido'
                setTimeout(() => this.errors.lastname = '', 2000)
            }

            e.preventDefault();

            if (!this.errors.name && !this.errors.lastname && !this.errors.employee && !this.errors.service && !this.errors.date) {     
                const data = {
                    cliente:this.cliente,
                    ip:null,
                    confirmado:true
                }
                await axios
                    .post('/api/v1/turnos/crear/', data)
                    .then(response => {                        
                        if (response.data == 'turno creado') {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'TURNO CREADO',
                                showConfirmButton: false,
                                timer: 2000
                            })
                            this.cliente = {
                                fecha:'',
                                nombre:'',
                                apellido:'',
                                telefono:'',
                                empleadoId:'',
                                servicioId:'',
                                hora:''
                            }
                            this.reservarTexto = false
                            this.empleadoTurnoSelect = ''
                            this.servicioTurnoSelect = ''
                            this.getTurnos()
                        }
                        else{
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'ERROR AL CREAR TURNO',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }
                    })
                    .catch(error => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL CREAR TURNO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                        console.log(error)
                    })
            }
            this.$store.commit('setIsLoading', false)
        },
        async removeTurno(e) {   
            Swal.fire({
                title: 'ELIMINAR TURNO?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#279f04',
                cancelButtonColor: '#d33',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                cancelButtonText: 'CANCELAR'
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = {
                        id:e.target.dataset.id
                    }
                    axios
                        .post("/api/v1/turnos/borrar/", data)
                        .then(response => {
                            this.getTurnos()
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'TURNO ELIMINADO',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'EL TURNO NO PUDO SER ELIMINADO',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                }
            })
                
        },
        async mandarWhatsApp (e) {
            let a= document.createElement('a');
            const fecha = e.target.dataset.fecha
            const hora = e.target.dataset.hora
            const telefono = e.target.dataset.telefono
            a.target= '_blank';
            a.href= "https://wa.me/549"+ telefono +"/?text=Hola, nos comunicamos de 'TU TIENDA NOMBRE', gracias por elegirnos! Confirmas reserva con fecha " + fecha + ' a las ' + hora + ' hs?'
            a.click();
        },
        async confirmarReserva(e) {
            Swal.fire({
                title: 'CONFIRMAR RESERVA?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#279f04',
                cancelButtonColor: '#d33',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                cancelButtonText: 'CANCELAR'
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = {
                        id:e.target.dataset.id
                    }
                    axios
                        .post("/api/v1/reservas/confirmar/", data)
                        .then(response => {
                            this.getReservas()
                            this.getTurnos()
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'RESERVA CONFIRMADA',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'LA RESERVA NO PUDO SER ELIMINADA',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                }
            })
            
        },
        async eliminarReserva(e) {
            Swal.fire({
                title: 'ELIMINAR RESERVA?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#279f04',
                cancelButtonColor: '#d33',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                cancelButtonText: 'CANCELAR'
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = {
                        id:e.target.dataset.id
                    }
                    axios
                        .post("/api/v1/reservas/borrar/", data)
                        .then(response => {
                            this.getReservas()
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'RESERVA ELIMINIDA',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                        .catch(error => {
                            console.log(JSON.stringify(error))
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'LA RESERVA NO PUDO SER ELIMINADA',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                }
            })
            
        },
        async confirmarReservaModal() {   
            const data = {
                id:this.reservaId
            }
            await axios
                .post("/api/v1/reservas/confirmar/", data)
                .then(response => {
                    this.getTurnos()
                    this.getReservas()

                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })    
        },
        
    }
}
</script>

<style>
.gestion-turnos-view {
    padding-top: 180px;
    color: white;
    text-align: center;
}

#col-1-turnos h1 {
    text-shadow: var(--text-shadow);
}

#col-1-turnos, #col-2-turnos {
    display: inline-table;
    margin-left: 20px;
    margin-right: 20px;
}

#col-1-turnos {
  max-width: 520px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 33px 22px 0px #000000;
  max-height: 500px;
  color: white;
  background: var(--bg-box-black);
  background-position: center center;
  background-size: cover;
  margin-bottom: 50px;
}

#empleado-turno, #servicio-turno, #fecha-turno {
  border-bottom: 2px solid var(--fieldBorderColor);
}

#nombre-t, #apellido-t, #telefono-t {
    margin-bottom: 13px;
}

#col-2-turnos {
    width: 900px;
}

#reservas {
    width: 900px;
    margin: 50px auto 50px auto;
}

#servicio-turno-div, #empleado-turno-div, #fecha-turno-div {
    display: inline-table;
    margin: 20px 10px 20px 10px;
}

#nombre-turno, #apellido-turno, #telefono-turno {
    display: inline-table;
    margin: 20px 10px 0 10px;
}

#empleado-t, #servicio-t, #fecha, #nombre-t, #apellido-t, #telefono-t  {
    width: 150px;
}

#empleado-t, #servicio-t {
    padding: 0px 14px ;
}

#fecha {
    background-color: transparent;
}

#fecha-turno {
    height: 35px;
}

#fecha input {
    height: 38px;
    text-align: left;
    font-family: 'Khand';
    color: white;
    font-size: 18px;
    background-color: transparent;
    border: none;
}

#fecha .dp__input {
    padding-left: 18px;
    font-size: 19px;
}

#fecha .dp__input_icon {
    top: 40%;
}

#fecha .dp__input::placeholder{
    color: #ffffff;
    font-weight: 500;
    opacity: 1;
}

.dp__selection_preview {
    font-size: 18px;
}

.dp__clear_icon {
    top: 50%;
    right: -5px;
    color: white;
}

#boton-buscar-turno-gestion button {
    background-color: var(--button-color);
    border: none;
    color: white;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
}

#horas-disponible-turnos {
    padding: 0 20px 0 20px;
}

#boton-hora {
    color: white;
    background-color: var(--button-hour-color);
    width: 61px;
    margin-bottom: 5px;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    padding: 0;
    font-size: 15px;
}

#table-wrapper {
    position:relative;
}

#tabla-turnos{
    max-height:580px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    overflow:auto;  
    background: var(--bg-box-black-big);
    background-position: center center;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
}

#reservas h1 {
    background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-10&color=000000);
    width: 250px;
    margin: 0 auto 20px auto;
}

#tabla-reservas{
    max-height:300px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    overflow:auto;  
    background: var(--bg-box-black-big);
    background-position: center center;
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
}

#th-eliminar-turnos {
    width: 50px;
}

#tabla-turnos thead tr th, 
#tabla-reservas thead tr th {
    position: sticky;
    top: 0;
    background-color: #000000;
    border: none;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    z-index: 1;
}

#tabla-turnos table tr td, 
#tabla-reservas table tr td {
    font-size: 20px;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;
}

#tabla-turnos::-webkit-scrollbar-thumb, 
#tabla-reservas::-webkit-scrollbar-thumb {
	background-color: #555;
    border-radius: 4px;
}

#tabla-turnos::-webkit-scrollbar,
#tabla-reservas::-webkit-scrollbar {
    width: 5px;
	background-color: #00000000;
}

#boton-guardar-turno button {
    background-color: var(--button-color);
    border: none;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 40px;
}

#boton-guardar-turno button:hover {
    background-color: var(--button-close);
}

#tabla-turnos #trash-mobile-icon {
    display: none;
}

#botones-reserva {
    display: none;
}

#trash-reserva{
    width: 20px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    filter: invert(100%);
}

#confirm {
    width: 23px;
    margin-right: 10px;
    cursor: pointer;
}

#whatsapp-logo-reserva {
    width: 20px;
    margin-right: 10px;
}

#confirm-toast {
    width: 100%;
    color: white;
    text-align: center;
    position: fixed;
    margin-top: -30px;
    z-index: 9999;
    background-color: green;
    height: 25px;
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
    #col-2-turnos{
        width: 700px;
    }
    #tabla-turnos thead tr th, 
    #tabla-reservas thead tr th {
        font-size: 18px;
    }

    #tabla-turnos table tr td, 
    #tabla-reservas table tr td {
        font-size: 18px;

    }
}

@media screen and (min-width: 900px) and (max-width: 1300px) {
    #col-1-turnos, #col-2-turnos {
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 900px) {

    .gestion-turnos-view {
        padding-top: 130px;
    }

    #col-2-turnos {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
    }

    #reservas {
        width: 90%;
        margin-top: 30px;
    }

    #tabla-turnos #delete-icon-turno {
        display: none;
    }

    #tabla-turnos #trash-mobile-icon {
        display: initial;
    }

    #tabla-reservas #delete-confirm-send {
        display: none;
    }

    #tabla-turnos,
    #tabla-reservas {
        height: 410px;
        width: 100%;
    }

    #tabla-turnos thead,
    #tabla-reservas thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    #tabla-turnos tr,
    #tabla-reservas tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
        padding: 10px;
    }

    #tabla-turnos table tr td,
    #tabla-reservas table tr td {
        border-bottom: none;
        display: block;
        font-size: 18px;
        text-align: right;
    }

    #tabla-turnos td::before,
    #tabla-reservas td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
        text-shadow: var(--text-shadow);
    }

    #tabla-turnos td:last-child,
    #tabla-reservas td:last-child {
        border-bottom: 0;
    }

    #tabla-turnos table::-webkit-scrollbar
    #tabla-reservas::-webkit-scrollbar {
        display: none;
    }

    #tabla-turnos table::-webkit-scrollbar-thumb
    #tabla-reservas::-webkit-scrollbar-thumb {
        display: none;
    }

    #botones-reserva {
        display:initial;
    }

    #boton-eliminar-reserva {
        background-color: red;
        border: none;
        color: #000000;
    }

    #boton-confirmar-reserva {
        background-color: green;
        border: none;
        color: #000000;
    }

    #boton-whatsapp-reserva {
        background-color: rgb(255, 255, 255);
        border: none;
        color: #000000;
    }

    #trash-reserva-mobile{
        width: 24px;
    }

    #confirm {
        width: 33px;
    }

    #whatsapp-logo-reserva {
        width: 28px;
    }

    #confirm-toast {
    margin-top: 0px;
    height: 25px;
}

    @media screen and (max-width: 575px) {
        #fecha-turno {
            height: 39px;
        }
    }

    @media screen and (max-width: 405px) {
        #empleado-t, #servicio-t, #fecha, #nombre-t, #apellido-t, #telefono-t {
            width: 120px;
        }
        #servicio-turno-div, #empleado-turno-div, #fecha-turno-div {
            margin: 20px 5px 20px 5px;
        }
    
        #nombre-turno, #apellido-turno, #telefono-turno {
            margin: 5px 10px 20px 5px;
        }

        #fecha-turno {
            height: 38px;
        }
    }
}

</style>