<template>
    <div class="gestion-servicios-view">
        <div id="datos-crear-categoria-tabla">
            <div id="crear-categoria-servicio-div">
                <h1>CREAR CATEGORIA SERVICIO</h1>
                <div id="categoria-servicio-div">
                    <div class="field field_v2">
                        <label for="categoria-servicio-nombre" class="ha-screen-reader">Nombre</label>
                        <input v-model="categoriaServicio" id="categoria-servicio-nombre" class="field__input" placeholder="ej. Cortes" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Nombre</span>
                        </span>
                    </div>    
                    <span v-if="error.crearCategoria"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ error.crearCategoria }}</p></span>
                </div>
                <button id="boton-crear-categoria" @click="crearCategoria">GUARDAR</button>
            
                <div id="crear-categoria-tabla-div">
                    <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                        <div class="lds-dual-ring"></div>
                    </div>

                    <div id="table-wrapper-crear-categoria">
                        <div id="tabla-crear-categoria">
                            <table>
                                <thead>
                                    <tr>
                                        <th id="th-eliminar-configuracion"></th>
                                        <th>NOMBRE</th>
                                    </tr> 
                                </thead>

                                <tbody>
                                    <tr
                                        v-for="categoria in categorias" :key="categoria.id">
                                        <td>
                                            <img id="trash-crear-categoria" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarCategoria" title="Eliminar" data-info="crear-categoria-tabla" :data-id="categoria.id">
                                        </td>
                                        <td data-label="NOMBRE">{{ categoria.nombre }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="datos-servicio-configuracion">
            <div id="crear-servicio-configuracion-div">
                <h1>CREAR SERVICIO</h1>
                <div id="configuracion-categoria-servicio-div">
                    <div id="categoria-servicio-inner">
                        <select id="categoria-servicio" class="select-style" @change="handleChange" v-model="servicio.categoria">
                        <option value="" disabled hidden>Categoria</option>
                        <option v-for="categoria in categorias" :key="categoria.id" data-info="categoria" :data-id="categoria.id">{{ categoria.nombre }}</option>
                        </select>
                    </div>
                    <span v-if="error.categoria"><p class="error-select" style="color: red; height: 0;">{{ error.categoria }}</p></span>
                </div>
                <div id="configuracion-nombre-servicio-div">
                    <div class="field field_v2">
                        <label for="nombre-servicio" class="ha-screen-reader">Nombre</label>
                        <input v-model="servicio.nombre" id="nombre-servicio" class="field__input" placeholder="ej. Corte" autocomplete="off">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Nombre</span>
                        </span>
                    </div>    
                    <span v-if="error.nombre"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ error.nombre }}</p></span>
                </div>

                <div id="configuracion-precio-servicio-div">
                    <div class="field field_v2">
                        <label for="precio-servicio" class="ha-screen-reader">Precio</label>
                        <input v-model="servicio.precio" id="precio-servicio" class="field__input" placeholder="ej. 1500" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Precio</span>
                        </span>
                    </div>    
                    <span v-if="error.precio"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ error.precio }}</p></span>
                </div>

                <div id="configuracion-turnos-servicio-div">
                    <div class="field field_v2">
                        <label for="turnos-servicio" class="ha-screen-reader">Turnos</label>
                        <input v-model="servicio.turnos" id="turnos-servicio" class="field__input" placeholder="ej. 4" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                        <span class="field__label-wrap" aria-hidden="true">
                            <span class="field__label">Turnos</span>
                        </span>
                    </div>    
                    <span v-if="error.turnos"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ error.turnos }}</p></span>
                </div>

                <button id="boton-configuracion-servicio" @click="crearServicio">GUARDAR</button>
            
                <div id="servicios-configuracion-tabla-div">
                    <div class="is-loading-bar" v-bind:class="{'is-loading': $store.state.isLoading }">
                        <div class="lds-dual-ring"></div>
                    </div>
                    
                    <div id="table-wrapper-configuracion">
                        <div id="tabla-servicios-configuracion">
                            <table>
                                <thead>
                                    <tr>
                                        <th id="th-eliminar-configuracion"></th>
                                        <th>CATEGORIA</th>
                                        <th>NOMBRE</th>
                                        <th>PRECIO</th>
                                        <th>TURNOS</th>
                                        <th>DURACION</th>
                                    </tr> 
                                </thead>

                                <tbody>
                                    <tr
                                        v-for="servicio in servicios" :key="servicio.id">
                                        <td id="icons-configuracion-servicio">
                                            <img id="settings-servicios" src="@/assets/images/settings.png" alt="settings-icon" @click="editarServicio" title="Editar" data-info="servicios-tabla" :data-id="servicio.id"> 
                                            <img id="trash-servicios" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarServicio" title="Eliminar" data-info="servicio-configuracion-tabla" :data-id="servicio.id">
                                        </td>
                                        <td data-label="CATEGORIA">{{ servicio.categoria }}</td>
                                        <td data-label="NOMBRE">{{ servicio.nombre }}</td>
                                        <td data-label="PRECIO">${{ servicio.precio }}</td>
                                        <td data-label="TURNOS">{{ servicio.turnos }}</td>
                                        <td data-label="DURACION">{{ servicio.get_duracion }} MIN</td>
                                        <td id="icons-servicios-mobile">
                                            <img id="settings-servicios-mobile" src="@/assets/images/settings.png" alt="settings-icon" @click="editarServicio" title="Editar" data-info="servicios-tabla" :data-id="servicio.id">
                                            <img id="trash-servicio-mobile" src="@/assets/images/trash.png" alt="trash-icon" @click="eliminarServicio" title="Eliminar" data-info="servicios-tabla" :data-id="servicio.id">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="cambiar-precio-servicio">
            <h1>ACTUALIZAR PRECIOS</h1>
            <div id="porcentaje-servicio-div">
                <div class="field field_v2">
                    <label for="porcentaje-servicios" class="ha-screen-reader">Porcentaje</label>
                    <input v-model="porcentaje" id="porcentaje-servicios" class="field__input" placeholder="ej. 15" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Porcentaje</span>
                    </span>
                </div>    
            </div>

            <div id="pesos-servicios-div">
                <div class="field field_v2">
                    <label for="pesos-servicios" class="ha-screen-reader">Pesos</label>
                    <input v-model="pesos" id="pesos-servicios" class="field__input" placeholder="ej. 200" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Pesos</span>
                    </span>
                </div>    
            </div>

            <span v-if="errorActualizarPrecio"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorActualizarPrecio }}</p></span>
            
            <button id="boton-precio-aumentar" @click="actualizarPrecios" data-info="aumentar">AUMENTAR</button>
            <button id="boton-precio-reducir" @click="actualizarPrecios" data-info="reducir">REDUCIR</button>
        </div>

        <transition name="bounce">
            <SettingsServicioModal
                v-show="showModalServicio" 
                @servicios="getServicios"
                @close-modal-servicio="showModalServicio = false" 
                :categorias="categorias"
                :trigger="servicio.id"
            />
        </transition>
    </div>
</template>

<script>
import axios from 'axios'
import SettingsServicioModal from '@/components/SettingsServicioComp.vue'

export default{
    name: 'GestionServiciosView',
    data() {
        return {
            servicios:[],
            categorias:[],
            categoriaServicio:'',
            servicio:{
                id:'',
                categoria:'',
                nombre:'',
                precio:'',
                turnos:''
            },
            error:{
                categoria:'',
                nombre:'',
                precio:'',
                turnos:'',
                duracion:'',
                crearCategoria:''
            },
            porcentaje:'',
            pesos:'',
            errorActualizarPrecio:'',
            showModalServicio:false
        }
    },
    components: { 
        SettingsServicioModal
    },
    mounted() {
        document.title = 'Servicios | Tijeras Gestion';
        this.setBgBody();
        this.getServicios();
        this.getCategorias()
        
    },
    methods: {
        setBgBody() {
            var store = document.querySelector(':root');
            store.style.setProperty('--bg-body', "var(--bg-body-gestion)");
            document.getElementById( 'solicitar-demo-div' ).style.display = 'none'
        },
        async getCategorias() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/servicios/categorias')
                .then(response => {
                    this.categorias = response.data
                })
                .catch(error => {
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async getServicios() {
            this.$store.commit('setIsLoading', true)
            await axios
                .get('/api/v1/servicios/')
                .then(response => {
                    this.servicios = response.data
                })
                .catch(error => {
                    if (error.response.status === 401){
                        localStorage.removeItem("token")
                        localStorage.removeItem("username")
                        localStorage.removeItem("userid")
                        this.$store.commit('removeToken')
                        this.$router.push('/gestion')
                    }
                    console.log(error)
                })
            this.$store.commit('setIsLoading', false)
        },
        async crearServicio(e) {
            if (!this.servicio.categoria) {
                this.error.categoria = 'Requerida'
                setTimeout(() => this.error.categoria = '', 2000)
            }
            if (!this.servicio.nombre) {
                this.error.nombre= 'Requerido'
                setTimeout(() => this.error.nombre = '', 2000)
            }
            if (!this.servicio.precio) {
                this.error.precio = 'Requerido'
                setTimeout(() => this.error.precio = '', 2000)
            }
            if (!this.servicio.turnos) {
                this.error.turnos = 'Requerido'
                setTimeout(() => this.error.turnos = '', 2000)
            }

            e.preventDefault();

            if (!this.error.categoria && !this.error.nombre && 
                !this.error.precio && !this.error.turnos) {   
                this.$store.commit('setIsLoading', true)  
               
                const data = {
                    servicio:this.servicio
                }
                await axios
                    .post('/api/v1/servicios/crear', data)
                    .then(response => {
                        if (response.data == 'creado') {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'SERVICIO CREADO',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }else if (response.data == 'actualizado') {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'SERVICIO ACTUALIZADO',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }
                        this.servicio = {
                            categoria:'',
                            nombre:'',
                            precio:'',
                            turnos:'',
                        }
                        this.getServicios()
                    })
                    .catch(error => {
                        if (error.response.status === 401){
                            localStorage.removeItem("token")
                            localStorage.removeItem("username")
                            localStorage.removeItem("userid")
                            this.$store.commit('removeToken')
                            this.$router.push('/gestion')
                        }
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL CREAR SERVICIO',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                this.$store.commit('setIsLoading', false)
            }
        },
        async crearCategoria(e) {
            if (!this.categoriaServicio) {
                this.error.crearCategoria = 'Requerido'
                setTimeout(() => this.error.crearCategoria = '', 2000)
            }
           
            e.preventDefault();

            if (!this.error.crearCategoria) {   
                this.$store.commit('setIsLoading', true)  
               
                const data = {
                    nombre:this.categoriaServicio
                }
                await axios
                    .post('/api/v1/servicios/categoria/crear', data)
                    .then(response => {
                        this.categoriaServicio = ''
                        if (response.data == 'creada') {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'CATEGORIA CREADA',
                                showConfirmButton: false,
                                timer: 2000
                            })
                            this.getCategorias()
                        }else if (response.data == 'existe') {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'LA CATEGORIA EXISTE',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'ERROR AL CREAR CATEGORIA',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                this.$store.commit('setIsLoading', false)
            }
        },
        async actualizarPrecios(e) {
            if (!this.porcentaje && !this.pesos) {
                this.errorActualizarPrecio = 'Seleccione uno'
                setTimeout(() => this.errorActualizarPrecio = '', 2000)
            }
            if (this.porcentaje && this.pesos) {
                this.errorActualizarPrecio = 'Uno solamente'
                setTimeout(() => this.errorActualizarPrecio = '', 2000)
            }
            if (this.porcentaje == '0' || this.pesos == '0') {
                this.errorActualizarPrecio= 'Minimo 1'
                setTimeout(() => this.errorActualizarPrecio = '', 2000)
            }

            e.preventDefault();

            if (!this.errorActualizarPrecio) {   
                const data = {
                    operacion:e.target.dataset.info,
                    porcentaje: this.porcentaje,
                    pesos:this.pesos
                }

                await axios
                    .post('/api/v1/servicios/actualizar-precios', data)
                    .then(response => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'PRECIOS ACTUALIZADOS',
                            showConfirmButton: false,
                            timer: 2000
                        })
                        this.porcentaje = ''
                        this.pesos = ''
                        this.getServicios()
                    })
                    .catch(error => {
                        console.log(error)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'PRECIOS NO ACTUALIZADOS',
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
            }
        },
        async eliminarServicio(e) {
            Swal.fire({
                title: 'ELIMINAR SERVICIO?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#279f04',
                cancelButtonColor: '#d33',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                cancelButtonText: 'CANCELAR'
            }).then((result) => {
                if (result.isConfirmed) {  
                    this.$store.commit('setIsLoading', true)
                    const data = {
                        id:e.target.dataset.id
                    }
                    axios
                        .post('/api/v1/servicios/eliminar', data)
                        .then(response => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'SERVICIO ELIMINADO',
                                showConfirmButton: false,
                                timer: 2000
                            })
                            this.getServicios()
                        })
                        .catch(error => {
                            console.log(error)
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'ERROR AL ELIMINAR SERVICIO',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                }
            })
            this.$store.commit('setIsLoading', false)
        },
        async eliminarCategoria(e) {
            Swal.fire({
                title: 'ELIMINAR CATEGORIA?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#279f04',
                cancelButtonColor: '#d33',
                confirmButtonText: '<i class="fa fa-thumbs-up"></i> SI!',
                cancelButtonText: 'CANCELAR'
            }).then((result) => {
                if (result.isConfirmed) {  
                    this.$store.commit('setIsLoading', true)
                    const data = {
                        id:e.target.dataset.id
                    }
                    axios
                        .post('/api/v1/servicios/categoria/eliminar', data)
                        .then(response => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'CATEGORIA ELIMINADA',
                                showConfirmButton: false,
                                timer: 2000
                            })
                            this.getCategorias()
                        })
                        .catch(error => {
                            console.log(error)
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: 'ERROR AL ELIMINAR CATEGORIA',
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                }
            })
            this.$store.commit('setIsLoading', false)
        },
        async editarServicio(e) {
            this.servicio.id = e.target.dataset.id
            this.showModalServicio = true
        },
        handleChange(e) {
            if(e.target.options.selectedIndex > -1) {
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "servicio"){
                    this.cliente.servicioId = e.target.options[e.target.options.selectedIndex].dataset.id
                    this.getEmpleados()
                }
                if(e.target.options[e.target.options.selectedIndex].dataset.info === "empleado-turno"){
                    this.cliente.empleadoId = e.target.options[e.target.options.selectedIndex].dataset.id 
                    this.noTrabaja = e.target.options[e.target.options.selectedIndex].dataset.dias.split(',')
                    this.vacaciones = []
                    let vacaciones = e.target.options[e.target.options.selectedIndex].dataset.vacaciones
                    let jsonVacaciones = JSON.parse(vacaciones)

                    if (jsonVacaciones.length > 1) {
                        for (let i = 0; jsonVacaciones.length > i; i++) {
                            let fechas = jsonVacaciones[i]['fecha']
                            this.getDatesInRange(fechas[0].trim(), fechas[1].trim())
                        }
                    }else {
                        let fechas = jsonVacaciones[0]['fecha']
                        this.getDatesInRange(fechas[0].trim(), fechas[1].trim())
                    } 
                } 
            }
        },
    }
}
</script>

<style>
.gestion-servicios-view {
    padding-top: 180px;
    color: white;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
}

#crear-categoria-servicio-div h1,
#crear-servicio-configuracion-div h1,
#datos-dias-cerrado h1,
#datos-horario-tienda h1 {
    text-shadow: var(--text-shadow);
}

#datos-servicio-configuracion {
    margin: 0 20px 50px 20px;
}

#datos-crear-categoria-tabla {
    margin: 0 20px 0 20px;
}

#crear-categoria-servicio-div {
    max-width: 350px;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
    margin: 0 auto 50px auto;
}

#crear-servicio-configuracion-div {
    max-width: 620px;
    min-height: 395px;
    padding: 12px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
}

#configuracion-categoria-servicio-div,
#configuracion-nombre-servicio-div, 
#configuracion-precio-servicio-div, 
#configuracion-turnos-servicio-div {
    display: inline-table;
    width: 130px;
    margin: 10px 10px 0 10px;
}

#categoria-servicio-div {
    margin: 10px auto 0 auto;
}

#categoria-servicio-inner {
    border-bottom: 2px solid var(--fieldBorderColor);
}

#categoria-servicio-nombre,
#nombre-servicio,
#precio-servicio,
#turnos-servicio,
#duracion-turno {
    margin-bottom: 13px;
}

#boton-crear-categoria,
#boton-configuracion-servicio {
    background-color: var(--button-color);
    border: none;
    color: white;
    width: 90%;
    margin-top: 10px;
}

#servicios-configuracion-tabla-div,
#crear-categoria-tabla-div {
    margin-top: 10px;
}

#servicios-configuracion-tabla-div h1,
#crear-categoria-tabla-div h1 {
    width: 160px;
    margin: 0 auto 20px auto;
    text-shadow: var(--text-shadow);
}

#table-wrapper-configuracion,
#table-wrapper-crear-categoria {
    position:relative;
}

#tabla-crear-categoria {
    height:320px;
    width: 240px;
    color: white;
    overflow:auto;  
    margin-left: auto;
    margin-right: auto;
}

#tabla-servicios-configuracion {
    max-height:373px;
    width: 580px;
    color: white;
    overflow:auto;  
    margin-left: auto;
    margin-right: auto;
}

#tabla-servicios-configuracion table,
#tabla-crear-categoria table {
    width: 100%;
}

#tabla-servicios-configuracion tr th,
#tabla-crear-categoria tr th {
    position: sticky;
    top: 0;
    background-color: rgb(14, 14, 14);
    background-position: center;
    border: none;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    z-index: 1;
}

#th-eliminar-configuracion {
    width: 50px;
}

#tabla-servicios-configuracion tr td,
#tabla-crear-categoria tr td {
    font-size: 20px;
    letter-spacing: .1em;
    text-transform: uppercase;
    text-align: center;
}

#tabla-servicios-configuracion::-webkit-scrollbar-thumb,
#tabla-crear-categoria::-webkit-scrollbar-thumb {
	background-color: #555;
    border-radius: 4px;
}

#tabla-servicios-configuracion::-webkit-scrollbar,
#tabla-crear-categoria::-webkit-scrollbar {
    width: 5px;
	background-color: #00000000;
}

#icons-configuracion-servicio {
    width: 80px;
}

#trash-crear-categoria,
#trash-servicios {
    width: 20px;
    filter: invert(100%);
    cursor: pointer;
}

#settings-servicios {
    width: 23px;
    filter: invert(100%);
    margin-right: 10px;
    cursor: pointer;
}

#icons-servicios-mobile {
    display: none;
}

#cambiar-precio-servicio {
    min-width: 260px;
    max-width: 300px;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 33px 22px 0px #000000;
    max-height: 500px;
    color: white;
    background: var(--bg-box-black);
    background-position: center center;
    background-size: cover;
}

#cambiar-precio-servicio h1{
    text-shadow: var(--text-shadow);
}

#porcentaje-servicio-div,
#pesos-servicios-div {
    display: inline-table;
    width: 130px;
    margin: 10px 10px 0 10px;
}

#porcentaje-servicios,
#pesos-servicios {
    margin-bottom: 13px;
}




@media screen and (min-width: 1300px) and (max-width: 1500px) {

}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .gestion-servicios-view {
        padding-top: 130px;
    }

    #tabla-crear-categoria {
        height: 200px;
    }
}

@media screen and (max-width: 600px) {

    .gestion-servicios-view {
        padding-top: 130px;
    }

    #datos-servicio-configuracion{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }

    #servicios-configuracion-tabla-div {
        margin-top: 20px;
    }

    #icons-configuracion-servicio{
        display: none;
    }

    #tabla-servicios-configuracion #icons-servicios-mobile {
        text-align: center;
    }

    #settings-servicios-mobile {
        width: 27px;
        filter: invert(100%);
        margin: 0 5px 0 5px;
        cursor: pointer;
    }

    #trash-servicio-mobile {
        width: 24px;
        filter: invert(100%);
        margin: 0 5px 0 5px;
        cursor: pointer;
    }

    #tabla-crear-categoria {
        height: 200px;
    }

    #tabla-servicios-configuracion {
        width: 90%;
        height: 260px;
    }

    #tabla-servicios-configuracion #icons-configuracion-servicio {
        display: none;
    }


    #tabla-servicios-configuracion thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    #tabla-servicios-configuracion tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
        padding: 10px;
    }


    #tabla-servicios-configuracion table tr td {
        border-bottom: none;
        display: block;
        font-size: 18px;
        text-align: right;
    }

    #tabla-servicios-configuracion td::before {
        /*
        * aria-label has no advantage, it won't be read inside a table
        content: attr(aria-label);
        */
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    #tabla-servicios-configuracion td:last-child {
        border-bottom: 0;
    }

    #tabla-servicios-configuracion::-webkit-scrollbar  {
        display: none;
    }

    #tabla-servicios-configuracion::-webkit-scrollbar-thumb {
        display: none;
    }

}

</style>