<template>
    <div class="modal-overlay-producto" @click="this.$emit('close-modal')">
        <div class="modal" @click.stop>
            <h1 id="titulo-editar-stock">EDITAR STOCK</h1>

            <div id="editar-producto-div">
                <div class="field field_v2">
                    <label for="editar-producto" class="ha-screen-reader">Producto</label>
                    <input v-model="editar.producto" id="editar-producto" class="field__input" placeholder="ej. Lorena" autocomplete="off">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Producto</span>
                    </span>
                </div>    
                <span v-if="errorProducto"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorProducto }}</p></span>
            </div>

            <div id="editar-cantidad-div">
                <div class="field field_v2">
                    <label for="editar-cantidad" class="ha-screen-reader">Cantidad</label>
                    <input v-model="editar.cantidad" id="editar-cantidad" class="field__input" placeholder="ej. 100" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Cantidad</span>
                    </span>
                </div>    
                <span v-if="errorCantidad"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorCantidad }}</p></span>    
            </div>

            <div id="editar-unidad-div">
                <div id="editar-unidad">
                    <select id="unidad-editar" class="select-style" @change="handleChange" v-model="editar.unidad">
                        <option value="" disabled hidden>Unidad</option>
                        <option v-for="item in unidades" :key="item.unidad" data-info="unidad" :data-short="item.short">
                                {{ item.short }} 
                        </option>
                    </select>
                </div>
                <span v-if="errorUnidad"><p class="error-select" style="color: red; height: 0;">{{ errorUnidad }}</p></span>
            </div>

            <div id="editar-categoria-div">
                <div id="editar-categoria">
                    <select id="categoria-editar" class="select-style" @change="handleChange" v-model="editar.categoria">
                        <option value="" disabled hidden>Categoria</option>
                        <option v-for="categoria in categorias" :key="categoria.id" data-info="categoria">
                                {{ categoria.nombre }} 
                        </option>
                    </select>
                </div>
                <span v-if="errorCategoria"><p class="error-select" style="color: red; height: 0;">{{ errorCategoria }}</p></span>
            </div>

            <div id="editar-precio-div" v-if="editar.categoria == 'TIENDA'">
                <div class="field field_v2">
                    <label for="editar-precio" class="ha-screen-reader">Precio</label>
                    <input v-model="editar.precio" id="editar-precio" class="field__input" placeholder="ej. 1000" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Precio</span>
                    </span>
                </div>    
                <span v-if="errorPrecio"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorPrecio }}</p></span>    
            </div>

            <div id="boton-actualizar-cancelar-stock">
                <button id="boton-editar-actualizar" @click="actualizarProducto" data-info="actualizar-producto">ACTUALIZAR</button>
                <button id="boton-editar-cancelar-actualizar" @click="this.$emit('close-modal')" data-info="cancelar-actualizar">CERRAR</button>
            </div>

        </div>

    </div>
</template>


<script>
import axios from 'axios'

export default {
    name: 'SettingsProductoModal',
    props: {
      categorias: Array,
      unidades: Array,
      trigger: String
    },
    data() {
        return {
            editar: {
                id:'',
                producto:'',
                cantidad:'',
                unidad:'',
                categoria:'',
                precio:''
            },
			errorProducto: '',
            errorCantidad: '',
            errorUnidad:'',
            errorCategoria:'',
            errorPrecio:'',
        }
    },
    components: { 

    },
    mounted() {
        
    },
    watch: { 
        trigger: function(newVal, oldVal) {
            if (newVal) {
            this.getDatosProducto(newVal)
            }
        }
    },
    methods: {   
        async getDatosProducto(id) {
            const data = {
                id:id
            }

            await axios
                .post('/api/v1/stock/editar/', data)
                .then(response => {
                    this.editar = {
                        id:id,
                        producto:response.data[0]['producto']['producto'],
                        cantidad:response.data[0]['producto']['cantidad'] - response.data[0]['producto_egreso'],
                        unidad:response.data[0]['producto']['unidad'],
                        categoria:response.data[0]['producto']['categoria'],
                        precio:response.data[0]['producto']['precio']
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async actualizarProducto(e) {
            const data = {
                producto:this.editar,
            }

            await axios
                .post('/api/v1/stock/editar/actualizar/', data)
                .then(response => {
                    this.getDatosProducto(this.editar.id)
                    this.$emit('stock')
                })
                .catch(error => {
                    console.log(error)
                })
        },
    }
}
</script>

<style scoped>

.modal-overlay-producto {
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
}
.modal {
    text-align: center;
    color: white;
    background: var(--bg-grey);
    background-position: center center;
    background-size: cover;
    max-height: 100%;
    width: 400px;
    border-radius: 4px;
    padding: 10px;
}

.modal h1 {
    color: white;
    text-shadow:  0 0 2px #fff, 
                0 0 3px var(--title-h1), 
                0 0 4px var(--title-h1), 
                0 0 5px var(--title-h1), 
                0 0 6px var(--title-h1), 
                0 0 7px var(--title-h1), 
                0 0 8px var(--title-h1);
}

#editar-producto-div,
#editar-cantidad-div, 
#editar-precio-div {
    display: inline-table;
    width: 130px;
    margin: 10px;
}

#editar-producto, #editar-cantidad, #editar-precio {
    margin-bottom: 13px;
}

#editar-unidad-div, #editar-categoria-div {
    display: inline-table;
    margin:20px 10px 10px 10px
}

#editar-unidad, #editar-categoria {
    border-bottom: 2px solid var(--fieldBorderColor);
    width: 130px;
}

#boton-editar-actualizar {
    background-color: rgb(39, 159, 4);
    border: none;
    color: white;
    font-size: 1em;
    font-weight: 500;
    margin: 10px;
}

#boton-editar-actualizar:hover {
    background-color: rgb(32, 131, 2);
}

#boton-editar-cancelar-actualizar {
    background-color: rgb(221, 51, 51);
    border: none;
    color: white;
    margin: 10px;
    font-size: 1em;
    font-weight: 500;
}

#button-editar-cancelar-actualizar:hover {
    background-color: rgb(175, 42, 42);
}

#boton-actualizar-cancelar-empleado {
    margin-bottom: 10px;
}



</style>
