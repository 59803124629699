<template>
    <div class="modal-overlay-servicio" @click="this.$emit('close-modal-servicio')">
        <div class="modal-servicio" @click.stop>
            <h1 id="titulo-editar-servicio">EDITAR SERVICIO</h1>

            <div id="editar-categoria-servicio-div">
                <div id="editar-categoria-servicio-inner">
                    <select id="editar-categoria-servicio" class="select-style" @change="handleChange" v-model="editar.categoria">
                        <option value="" disabled hidden>Categoria</option>
                        <option v-for="categoria in categorias" :key="categoria.id" data-info="categoria">
                                {{ categoria.nombre }} 
                        </option>
                    </select>
                </div>
                <span v-if="errorCategoria"><p class="error-select" style="color: red; height: 0;">{{ errorCategoria }}</p></span>
            </div>

            <div id="editar-nombre-servicio-div">
                <div class="field field_v2">
                    <label for="editar-nombre-servicio" class="ha-screen-reader">Nombre</label>
                    <input v-model="editar.nombre" id="editar-nombre-servicio" class="field__input" placeholder="ej. Tintura" autocomplete="off">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Nombre</span>
                    </span>
                </div>    
                <span v-if="errorNombre"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorNombre }}</p></span>
            </div>
     
            <div id="editar-precio-servicio-div">
                <div class="field field_v2">
                    <label for="editar-precio-servicio" class="ha-screen-reader">Precio</label>
                    <input v-model="editar.precio" id="editar-precio-servicio" class="field__input" placeholder="ej. 1000" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Precio</span>
                    </span>
                </div>    
                <span v-if="errorPrecio"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorPrecio }}</p></span>    
            </div>

            <div id="editar-turnos-servicio-div">
                <div class="field field_v2">
                    <label for="editar-turnos-servicio" class="ha-screen-reader">Turnos</label>
                    <input v-model="editar.turnos" id="editar-turnos-servicio" class="field__input" placeholder="ej. 4" autocomplete="off" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    <span class="field__label-wrap" aria-hidden="true">
                        <span class="field__label">Turnos</span>
                    </span>
                </div>    
                <span v-if="errorTurnos"><p class="error-input" style="margin-top:-13px; height: 0; color: red;">{{ errorTurnos }}</p></span>    
            </div>

            <div id="boton-actualizar-cancelar-stock">
                <button id="boton-servicio-actualizar" @click="actualizarServicio" data-info="actualizar-servicio">ACTUALIZAR</button>
                <button id="boton-servicio-cancelar-actualizar" @click="this.$emit('close-modal-servicios')" data-info="cancelar-actualizar">CERRAR</button>
            </div>

        </div>

    </div>
</template>


<script>
import axios from 'axios'

export default {
    name: 'SettingsProductoModal',
    props: {
      categorias: Array,
      trigger: String
    },
    data() {
        return {
            editar: {
                id:'',
                categoria:'',
                nombre:'',
                precio:'',
                turnos:'',
            },
			errorNombre: '',
            errorPrecio: '',
            errorTurnos:'',
            errorCategoria:'',
        }
    },
    components: { 

    },
    mounted() {
        
    },
    watch: { 
        trigger: function(newVal, oldVal) {
            if (newVal) {
                this.getDatosServicio(newVal)
            }
        }
    },
    methods: {   
        async getDatosServicio(id) {
            const data = {
                id:id
            }

            await axios
                .post('/api/v1/servicios/editar', data)
                .then(response => {
                    this.editar = {
                        id:id,
                        categoria:response.data[0]['servicio']['categoria'],
                        nombre:response.data[0]['servicio']['nombre'],
                        precio:response.data[0]['servicio']['precio'],
                        turnos:response.data[0]['servicio']['turnos'],
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async actualizarServicio(e) {
            const data = {
                servicio:this.editar,
            }

            await axios
                .post('/api/v1/servicios/editar/actualizar', data)
                .then(response => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'SERVICIO ACTUALIZADO',
                        showConfirmButton: false,
                        timer: 2000
                    })
                    this.getDatosServicio(this.editar.id)
                    this.$emit('servicios')
                })
                .catch(error => {
                    console.log(error)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'SERVICIO NO ACTUALIZADO',
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
        },
    }
}
</script>

<style scoped>

.modal-overlay-servicio {
    z-index: 999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
}
.modal-servicio {
    text-align: center;
    color: white;
    background: var(--bg-grey);
    background-position: center center;
    background-size: cover;
    max-height: 100%;
    width: 400px;
    border-radius: 4px;
    padding: 10px;
}

.modal-servicio h1 {
    color: white;
    text-shadow: var(--text-shadow);
}


#editar-categoria-servicio-div,
#editar-nombre-servicio-div,
#editar-precio-servicio-div, 
#editar-turnos-servicio-div {
    display: inline-table;
    width: 130px;
    margin: 10px;
}

#editar-categoria-servicio-div {
    border-bottom: 2px solid var(--fieldBorderColor);
    width: 130px;
}

#editar-nombre-servicio, 
#editar-precio-servicio, 
#editar-turnos-servicio {
    margin-bottom: 13px;
}

#boton-servicio-actualizar {
    background-color: rgb(39, 159, 4);
    border: none;
    color: white;
    font-size: 1em;
    font-weight: 500;
    margin: 10px;
}

#boton-servicio-actualizar:hover {
    background-color: rgb(32, 131, 2);
}

#boton-servicio-cancelar-actualizar {
    background-color: rgb(221, 51, 51);
    border: none;
    color: white;
    margin:10px;
    font-size: 1em;
    font-weight: 500;
}

#button-servicio-cancelar-actualizar:hover {
    background-color: rgb(175, 42, 42);
}

#boton-actualizar-cancelar-empleado {
    margin-bottom: 10px;
}


@media screen and (max-width: 450px) {
    .modal-servicio {
        width: 90%;
    }
}


</style>
